import { Button, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { debounceSearchRender } from 'mui-datatables';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getCategories } from '../../../integrations/api/categories';
import DataTable from '../../table/DataTable';

const useStyles = makeStyles((theme) => ({
  tableRow: { borderBottom: '1px solid rgba(224, 224, 224, 1)' },
  tableColumn: {
    padding: theme.spacing(2),
  },
}));

export default function CustomPaginationActionsTable() {
  let history = useHistory();
  const { typeName } = useParams();
  const [categories, setCategories] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState('');
  const classes = useStyles();
  const { docs = [], totalDocs: count = 0 } = categories;

  const fetchCategories = useCallback(() => {
    getCategories({
      limit,
      page,
      filter,
    })
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
        alert('Falha ao carregar as categories');
      });
  }, [page, limit, filter]);

  useEffect(fetchCategories, [fetchCategories]);

  const columns = [
    {
      name: 'category',
      label: 'Nome',
    },
    {
      name: 'type',
      label: 'Tipo',
    },
    {
      options: {
        empty: true,
      },
      name: '_id',
      label: ' ',
    },
  ];

  function onClickEditButton(type, rowDataId) {
    history.push(`/admin/categories/${type}/update/${rowDataId}`);
  }

  const options = {
    page: page - 1,
    selectableRows: 'none',
    print: false,
    download: false,
    serverSide: true,
    count,
    responsive: 'vertical',
    tableBodyHeight: '100%',
    tableBodyMaxHeight: '',
    sort: false,
    filter: false,
    viewColumns: false,
    customSearchRender: debounceSearchRender(500),
    onSearchChange: (newFilter) => setFilter(newFilter),
    onChangePage: (newPage) => setPage(newPage),
    onChangeRowsPerPage: (limit) => setLimit(limit),
    customRowRender: (data) => {
      const [category, type, _id] = data;
      return (
        <tr className={classes.tableRow} key={_id}>
          <td className={classes.tableColumn}>{category}</td>
          <td className={classes.tableColumn}>{type}</td>
          <td>
            <IconButton
              aria-label="upload picture"
              component="span"
              onClick={() => {
                onClickEditButton(type, _id);
              }}
            >
              <EditIcon />
            </IconButton>
          </td>
        </tr>
      );
    },
  };

  return (
    <React.Fragment>
      <div style={{ textAlign: 'right', marginBottom: 20 }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<SaveIcon />}
          onClick={() => history.push(`/admin/categories/${typeName}/create`)}
        >
          Inserir novo
        </Button>
      </div>
      <DataTable data={docs} columns={columns} options={options} />
    </React.Fragment>
  );
}
