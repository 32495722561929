import React, { useEffect, useState } from 'react';
import { Fab, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import { debounceSearchRender } from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openSnackBar } from '../../../redux/snackbar/snackbar.actions';
import PageTitle from '../../app/main/Title';
import DataTable from '../../table/DataTable';

import {
  asyncDeleteBrokerProduct,
  asyncFetchBrokerProducts,
  editBrokerProduct,
  asyncSynchronizeBrokerProductOffers,
} from '../../../redux/brokerproduct/brokerproduct.actions';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function CustomPaginationActionsTable() {
  const dispatch = useDispatch();
  let history = useHistory();
  const classes = useStyles();
  const brokerProducts = useSelector((state) => state.brokerProduct.brokerProducts);
  const page = useSelector((state) => state.brokerProduct.page);
  const rowsPerPage = useSelector((state) => state.brokerProduct.limit);
  const totalCount = useSelector((state) => state.brokerProduct.totalCount);
  const [filter, setFilter] = useState('');

  useEffect(
    () => dispatch(asyncFetchBrokerProducts({ page: 1, limit: 10, lean: true })),
    [dispatch]
  );

  const columns = [
    { name: '_id', label: ' ' },
    { name: '_sync', label: ' ' },
    { name: 'gateway_name', label: 'Nome do produto no broker' },
    { name: 'description', label: 'Descrição' },
    { name: 'plan', label: 'Produto' },
    { name: 'broker_product_id', label: ' '}
  ];

  function onClickEditButton(rowDataId) {
    dispatch(editBrokerProduct(null));
    history.push(`/admin/broker-products/details/${rowDataId}`);
  }

  function onClickSyncButton(brokerProductId) {
    if (brokerProductId) {
      dispatch(asyncSynchronizeBrokerProductOffers(brokerProductId));
      return
    }
    
    dispatch(
      openSnackBar({
        type: 'error',
        message: 'Broker Product ID not present.',
      })
    );
  }

  const options = {
    page: page,
    selectableRows: 'none',
    print: false,
    download: false,
    serverSide: true,
    count: totalCount,
    responsive: 'vertical',
    tableBodyHeight: '100%',
    tableBodyMaxHeight: '',
    sort: false,
    filter: false,
    viewColumns: false,
    customSearchRender: debounceSearchRender(500),
    onSearchChange: (newFilter) => {
      setFilter(newFilter);
      new Promise((resolve) => {
        resolve(
          dispatch(
            asyncFetchBrokerProducts({
              filter: newFilter,
              page: 1,
              limit: rowsPerPage,
              lean: true,
            })
          )
        );
      });
    },
    onRowsDelete: (data) => {
      new Promise((resolve) => {
        resolve(dispatch(asyncDeleteBrokerProduct(data)));
      });
    },
    onChangePage: (newPage) => {
      new Promise((resolve) => {
        resolve(
          dispatch(
            asyncFetchBrokerProducts({
              filter: filter,
              page: newPage + 1,
              limit: rowsPerPage,
              lean: true,
            })
          )
        );
      });
    },
    onChangeRowsPerPage: (limit) => {
      new Promise((resolve) => {
        resolve(
          dispatch(
            asyncFetchBrokerProducts({
              filter: filter,
              page: 1,
              limit: limit,
            })
          )
        );
      });
    },
    customRowRender: (data) => {
      // eslint-disable-next-line
      const [_id, _sync, gateway_name, description, plan, broker_product_id] = data;
      // _sync is a simple placeholder to prevent breaking the UI

      return (
        <tr
          key={_id}
          style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
        >
          <td style={{ width: 40 }}>
            <IconButton
              component="span"
              onClick={() => {
                onClickEditButton(_id);
              }}
            >
              <EditIcon />
            </IconButton>
          </td>
          <td style={{ width: 40 }}>
            <IconButton
              component="span"
              onClick={() => {
                onClickSyncButton(broker_product_id);
              }}
            >
              <SyncIcon />
            </IconButton>
          </td>
          <td align="left">{gateway_name}</td>
          <td style={{ width: 300 }} align="left">{description}</td>
          <td style={{ width: 300 }} align="left">{plan?.name}</td>
        </tr>
      );
    },
  };

  return (
    <>
      <PageTitle>Produtos</PageTitle>
      <DataTable data={brokerProducts} columns={columns} options={options} />
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        href="/admin/broker-products/create"
      >
        <AddIcon />
      </Fab>
    </>
  );
}
