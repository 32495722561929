import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getLiveById } from '../../../integrations/api/playlists';

import { createLive, updateLive } from '../../../integrations/api/playlists';

import LiveForm from './form';

const LiveUpdateHandler = () => {
  const { id } = useParams();
  const [playlist, setPlaylist] = useState();

  useEffect(() => {
    getLiveById(id).then((p) => setPlaylist(p));
  }, [id]);

  return playlist ? (
    <LiveForm
      data={playlist}
      submitHandler={async (data) => await updateLive(playlist._id, data)}
    />
  ) : null;
};

const LiveCreateHandler = () => {
  return (
    <LiveForm
      data={{ media: {}, thumbnailsFiles: {} }}
      submitHandler={async (data) => await createLive(data)}
    />
  );
};

export { LiveUpdateHandler, LiveCreateHandler };
