import { snackbarActionTypes } from './snackbar.types';

export const closeSnackBar = () => {
  return {
    type: snackbarActionTypes.CLOSE,
    payload: { open: false, message: '' },
  };
};

export const openSnackBar = (payload) => {
  return {
    type: snackbarActionTypes.OPEN,
    payload: {
      message: payload.message,
      type: payload.type || 'info',
    },
  };
};
