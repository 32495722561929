import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect } from 'react';

export default ({
  field,
  form: { setFieldValue },
  label,
  disablePast = false,
}) => {
  const handleChange = (date) => {
    setFieldValue(field.name, date);
  };

  useEffect(() => {
    setFieldValue(field.name, field.value || new Date());
  }, [field.name, field.value, setFieldValue]);

  return (
    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        variant="inline"
        ampm={false}
        label={label}
        value={field.value}
        onChange={handleChange}
        onError={console.log}
        disablePast={disablePast}
        format="dd/MM/yyyy HH:mm"
      />
    </MuiPickersUtilsProvider>
  );
};
