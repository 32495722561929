import {
  Button,
  Checkbox,
  Divider,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { asyncFetchRoles } from '../../../redux/roles/role.actions';
import DateTimePicker from '../../date/DateTimePicker';

import moment from 'moment';
import Duration from '../../date/Duration';
import FormikDropZoneArea from '../../formik/DropZoneArea';

import store from '../../../redux/store';
import { openSnackBar } from '../../../redux/snackbar/snackbar.actions';
import { setLoading } from '../../../redux/loading/loading.actions';

import { uploadImage } from '../../../integrations/api/files';
import { KeyboardBackspace } from '@material-ui/icons';

import castleThumbnailFiles from '../playlists/CastleThumbnails';
import { getSourceUrl } from '../../../integrations/cloudinary';

import { products } from '../playlists/playlistsMeta';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  form: {
    marginTop: '20px',
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  player: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  footer: {
    width: '100%',
    padding: '10px',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  durationLabel: {
    textAlign: 'center',
    marginBottom: 10,
    paddingBottom: 10,
    borderBottom: '1px solid #ddd',
  },
}));

const getLiveEnd = (live_start, duration) => {
  return moment(live_start)
    .add(duration.hours, 'hours')
    .add(duration.minutes, 'minutes');
};

const castleThumbnail = castleThumbnailFiles('live');

export default ({ data, submitHandler }) => {
  const initialState = data;

  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => dispatch(asyncFetchRoles()), [dispatch]);
  const history = useHistory();

  const productsOptions = () => {
    let options = [];
    products.forEach((product) => {
      options.push(
        <FormLabel key={`label-${product.id}`}>
          <Field
            key={product.id}
            value={product.id}
            render={({ field }) => (
              <Checkbox
                key={`checkbok-${product.id}`}
                label={product.name}
                {...field}
              />
            )}
            type="checkbox"
            name="relatedProducts"
          />
          {product.name}
        </FormLabel>
      );
    });
    return options;
  };

  const handleSubmit = (values) => {
    const media = {
      ...values.media,
      live_end: getLiveEnd(values.media.live_start, values.duration),
    };

    let data = {
      ...values,
      media,
    };

    if (!values.coverFile || !values.thumbnailFile) {
      store.dispatch(
        openSnackBar({
          type: 'error',
          message: 'Imagens são obrigatórias!',
        })
      );
    }

    let imagesToUpload = [];
    if (initialState.coverFile !== values.coverFile) {
      imagesToUpload.push({
        image: values.coverFile,
        name: 'cover',
        path: `/playlists/live/cover`,
      });
    }

    if (initialState.thumbnailFile !== values.thumbnailFile) {
      imagesToUpload.push({
        image: values.thumbnailFile,
        name: 'thumbnail',
        path: `/playlists/live/thumbnail`,
      });
    }

    if (initialState.heroFile !== values.heroFile) {
      imagesToUpload.push({
        image: values.heroFile,
        name: 'hero',
        path: `/playlists/live/hero`,
      });
    }

    if (
      initialState.thumbnailsFiles[castleThumbnail.stateField] !==
      values.thumbnailsFiles[castleThumbnail.stateField]
    ) {
      imagesToUpload.push({
        metadata: 'thumbnails',
        image: values.thumbnailsFiles[castleThumbnail.stateField],
        name: castleThumbnail.stateField,
        path: `/playlists/live/${castleThumbnail.stateField}`,
      });
    }

    if (values.heroWeb && !values.heroWeb.image) {
      imagesToUpload.push({
        image: values.heroWeb,
        name: 'heroWeb',
        path: `/playlists/live/heroWeb`,
      });
    }

    if (values.playlistLogo && !values.playlistLogo.image) {
      imagesToUpload.push({
        image: values.playlistLogo,
        name: 'playlistLogo',
        path: `/playlists/live/logo`,
      });
    }

    Promise.all(imagesToUpload.map((arg) => uploadImage(arg)))
      .then((responses) => {
        Object.assign(
          data,
          ...responses.map(({ data }) => {
            if (data.meta === 'thumbnails') {
              return { thumbnails: data.reference };
            }
            return data.reference;
          })
        );
        submitForm(data);
      })
      .catch(() => {
        openSnackBar({
          type: 'error',
          message: 'Erro ao salvar os dados!',
        });
      });
  };

  const submitForm = (data) => {
    submitHandler(data)
      .then(() => {
        history.push(`/admin/playlists/live`);
      })
      .catch(() => {
        openSnackBar({
          type: 'error',
          message: 'Erro ao salvar os dados!',
        });
      })
      .finally(() => store.dispatch(setLoading(false)));
  };

  const calcInitialDuration = (live_start, live_end) => {
    if (!live_start || !live_end) {
      return {
        hours: 1,
        minutes: 0,
      };
    }

    const diff = moment(live_end).diff(live_start);
    const duration = moment.duration(diff);
    return {
      hours: duration.days() * 24 + duration.hours(),
      minutes: duration.minutes(),
    };
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant="h4" gutterBottom>
        <Button
          onClick={() => {
            history.push('/admin/playlists/live');
          }}
        >
          <KeyboardBackspace></KeyboardBackspace>
        </Button>
        {data._id ? 'Edição de Live' : 'Criação de Live'}
      </Typography>

      <Divider />

      <Formik
        initialValues={{
          ...data,
          duration: calcInitialDuration(
            data.media.live_start,
            data.media.live_end
          ),
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values }) => (
          <Form className={classes.form}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h6">
                  Informações Gerais
                </Typography>

                <Divider />
              </Grid>
              <Grid item xs={6}>
                <Field
                  required
                  name="name"
                  label="Nome"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  required
                  name="description"
                  label="Descrição"
                  multiline
                  maxRows={7}
                  component={TextField}
                />
              </Grid>

              <Grid item xs={6}>
                <FormLabel component="legend">
                  Produtos relacionados (opcional)
                </FormLabel>
                <FormHelperText>
                  Você pode selecionar produtos relacionados à live (é possível
                  marcar quantos desejar). Dessa forma ela aparecerá como
                  indicação na página dos produtos selecionados.
                </FormHelperText>
                <FormGroup aria-label="titleType">
                  {productsOptions()}
                </FormGroup>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Field
                  width={310.5}
                  height={266.25}
                  maxWidth={310.5}
                  name="heroFile"
                  label="Imagem de Capa (Mobile App)"
                  helperText="Imagem mostrada no topo da página de detalhes do app. Dimensões: 1242x1065px"
                  component={FormikDropZoneArea}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <Field
                  name={'thumbnailsFiles.' + castleThumbnail?.stateField}
                  width={castleThumbnail?.preview.width}
                  height={castleThumbnail?.preview.height}
                  initialFile={
                    values.thumbnailsFiles[castleThumbnail?.stateField]
                  }
                  maxWidth={castleThumbnail?.preview.width}
                  label={
                    castleThumbnail?.label
                      ? castleThumbnail.label
                      : 'Miniatura Nova Plataforma'
                  }
                  helperText={`Imagem mostrada no app e na nova plataforma web. Dimensões: ${castleThumbnail?.proportion.width}x${castleThumbnail?.proportion.height}px`}
                  component={FormikDropZoneArea}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  name="heroWeb"
                  width={480}
                  height={270}
                  maxWidth={480}
                  initialFile={undefined}
                  initialFileUrl={
                    values.heroWeb?.image ? getSourceUrl(values.heroWeb) : ''
                  }
                  label="Imagem de Capa Nova Plataforma"
                  helperText="Imagem mostrada na página de detalhes da nova plataforma web. Dimensões: 1920x1080px"
                  component={FormikDropZoneArea}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  name="playlistLogo"
                  width={400}
                  height={230}
                  initialFile={undefined}
                  initialFileUrl={
                    values.playlistLogo?.image
                      ? getSourceUrl(values.playlistLogo)
                      : ''
                  }
                  maxWidth={400}
                  label="Logo da Playlist (opcional)"
                  helperText="Utilizar uma imagem de logo é uma forma mais atrativa de apresentarmos o conteúdo na plataforma. Se não cadastrado, é mostrado o texto do título. Obs.: utilizar fundo transparente. Dimensões máximas: 1200x690px"
                  component={FormikDropZoneArea}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <Field
                  name="coverFile"
                  width={560}
                  height={200}
                  maxWidth={800}
                  label="Imagem de capa"
                  helperText="Imagem utilizada como destaque de última produção (plataforma antiga)."
                  component={FormikDropZoneArea}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <Field
                  maxWidth={480}
                  width={480}
                  height={270}
                  name="thumbnailFile"
                  label="Miniatura secundária (multi-tipo)"
                  helperText="Miniatura usada em todos os clientes para carrosséis com playlists de diferentes tipos."
                  component={FormikDropZoneArea}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography gutterBottom variant="h6">
                  Configurações da Transmissão
                </Typography>

                <Divider />
              </Grid>

              <Grid item xs={6}>
                <Field
                  name="media.sourceInput"
                  label="Link da mídia"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="media.live_start"
                  label="Data/hora de início"
                  component={DateTimePicker}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <InputLabel className={classes.durationLabel}>
                  Duração
                </InputLabel>
                <Field
                  name="duration"
                  label="duração"
                  placeholder=""
                  component={Duration}
                />
                {`Fim da live em: ${getLiveEnd(
                  values.media.live_start,
                  values.duration
                ).format('DD/MM/YYYY HH:mm')}`}
              </Grid>

              <Grid item xs={12} className={classes.player}>
                <InputLabel style={{ marginBottom: '10px' }}>
                  Preview
                </InputLabel>
                <ReactPlayer
                  style={{ backgroundColor: 'gray' }}
                  url={values.media.sourceInput}
                  controls={true}
                />
              </Grid>
              <div className={classes.footer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};
