import api from '../../api';

export default {
  all: function(params) {
    return api.get('/teachers', {
      params,
    });
  },
  findById: function(id) {
    return api.get(`/teachers/${id}`);
  },
  create: function(data) {
    return api.post('/teachers', data);
  },
  edit: function(data) {
    return api.put('/teachers', data);
  },
  search: function(search) {
    return this.all({ search });
  },
};
