import React from 'react';
import Form from './form';

const PlayListCreate = () => {
  return (
    <React.Fragment>
      <Form />
    </React.Fragment>
  );
};
export default PlayListCreate;
