import { playListActionTypes } from './playlist.types';

const INITIAL_STATE = {
  playlists: [],
  toUpdate: null,
  isFetching: false,
};

const playlistReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case playListActionTypes.FETCH_PLAYLISTS:
      return {
        ...state,
        isFetching: true,
      };
    case playListActionTypes.RECEIVE_PLAYLISTS:
      return {
        ...state,
        playlists: action.payload,
        isFetching: Object.assign({}, { isFetching: false }),
      };
    case playListActionTypes.CREATE_PLAYLIST:
      return {
        ...state,
        playlists: [].concat(state.playlists, [action.payload]),
      };
    case playListActionTypes.EDIT_PLAYLIST:
      return {
        ...state,
        toUpdate: action.payload,
      };
    case playListActionTypes.UPDATE_PLAYLIST:
      return {
        ...state,
        playlists: state.playlists.map((p) =>
          p._id === action.payload._id ? Object.assign(p, action.payload) : p
        ),
      };
    case playListActionTypes.ADD_MODULE:
      return {
        ...state,
        toUpdate: Object.assign({}, state.toUpdate, {
          modules: [].concat(state.toUpdate.modules, action.payload),
        }),
      };
    case playListActionTypes.UPDATE_MODULE:
      return {
        ...state,
        toUpdate: Object.assign({}, state.toUpdate, {
          modules: state.toUpdate.modules.map((mod) =>
            mod._id === action.payload._id
              ? Object.assign({}, mod, action.payload)
              : mod
          ),
        }),
      };

    case playListActionTypes.DELETE_MODULE:
      return {
        ...state,
        toUpdate: Object.assign({}, state.toUpdate, {
          modules: state.toUpdate.modules.filter(
            (mod) => mod._id !== action.payload._id
          ),
        }),
      };
    case playListActionTypes.DELETE_PLAYLIST:
      return {
        ...state,
        playlists: state.playlists.filter((p) => p._id !== action.payload._id),
      };
    default:
      return state;
  }
};
export default playlistReducer;
