import React from 'react';
import DashboardComponent from '../../../components/admin/dashboard';

function Dashboard(props) {
  return (
    <React.Fragment>
      <DashboardComponent />
    </React.Fragment>
  );
}

export default Dashboard;
