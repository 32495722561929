import { Grid } from '@material-ui/core';
import React from 'react';
import { useContext } from 'react';
import PlaylistSelect from '../playlists/Autocomplete';
import { PlaylistContext } from '../playlists/PlaylistContext';

const InterviewInputs = ({ defaultValue }) => {
  const [, dispatch] = useContext(PlaylistContext);
  const onChange = (value) =>
    dispatch({
      type: 'CHANGE_INPUT',
      payload: {
        playlist: value,
      },
    });

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <PlaylistSelect
            type={'serie'}
            label="Produção da entrevista"
            required
            callback={onChange}
            defaultValue={defaultValue}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default InterviewInputs;
