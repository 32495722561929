import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import store from '../../../redux/store';
import { openSnackBar } from '../../../redux/snackbar/snackbar.actions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';

const statusMap = {
  activated: { text: '', color: '#4EC57D' },
  available: { text: 'Não ativado', color: '#FFBB29' },
  error: { text: 'Cartão com erro', color: '#FA7A7F' },
  canceled: { text: 'Cancelado', color: '#FA7A7F' },
};

const useStyles = makeStyles({
  tableRow: {
    height: 30,
  },
  tableCell: {
    padding: '0px 16px',
  },
});

export default function UserGiftcards() {
  const [giftcards, setGiftcards] = useState([]);
  const { id: userId } = useParams();
  const classes = useStyles();
  const fetchUserGiftcards = useCallback(async () => {
    if (userId) {
      const response = await api.get(`/giftcards/admin/${userId}`);
      setGiftcards(response.data.giftCards);
    }
  }, [userId]);

  useEffect(() => {
    fetchUserGiftcards();
  }, [userId, fetchUserGiftcards]);

  const getStatusColor = (giftcard) => {
    return statusMap[giftcard.status]?.color || '#FFBB29';
  };

  const isGiftcardAvailable = (giftcard) => {
    return ['available', 'processing', 'error'].includes(giftcard.status);
  };

  const getActivationStatusFromCard = (giftcard) => {
    if (giftcard?.activation && giftcard?.status === 'activated') {
      return `Ativado em ${moment(giftcard.activation.activatedAt).format(
        'DD/MM/YYYY'
      )}`;
    } else {
      return statusMap[giftcard.status]?.text || 'Processando';
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Produto</TableCell>
            <TableCell align="left">Presenteado</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Validade</TableCell>
            <TableCell align="center">Compartilhar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {giftcards.map((giftcard) => (
            <TableRow
              className={classes.tableRow}
              key={giftcard.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                className={classes.tableCell}
              >
                {giftcard.subscription.plan.role}
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                {giftcard.activation?.user?.name
                  ? giftcard.activation.user.name
                  : '-'}
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                <span
                  style={{
                    width: '10px',
                    height: '10px',
                    background: getStatusColor(giftcard),
                    display: 'inline-block',
                    borderRadius: '10px',
                    marginRight: '5px',
                    verticalAlign: 'middle',
                  }}
                ></span>
                {getActivationStatusFromCard(giftcard)}
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                {isGiftcardAvailable(giftcard)
                  ? `Válido até ${moment(giftcard.expiration).format(
                      'DD/MM/YYYY'
                    )}`
                  : '-'}
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                {isGiftcardAvailable(giftcard) ? (
                  <CopyToClipboard
                    text={`https://presente.brasilparalelo.com.br/gifted/${giftcard.token}`}
                    onCopy={() =>
                      store.dispatch(
                        openSnackBar({
                          variant: 'success',
                          message:
                            'Link copiado para sua área de transferência',
                        })
                      )
                    }
                  >
                    <IconButton
                      component="span"
                      size="small"
                      onClick={() => {
                        console.log('Tcham!');
                      }}
                    >
                      <ShareIcon />
                    </IconButton>
                  </CopyToClipboard>
                ) : (
                  '-'
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
