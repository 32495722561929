import api from '../../api';

export const uploadImage = ({ image, path, name, metadata }) => {
  const formData = new FormData();

  formData.append('image', image);
  formData.append('path', path);
  formData.append('name', name);

  if (metadata) {
    formData.append('meta', metadata);
  }

  return api.post(`/admin/image`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export default { uploadImage };
