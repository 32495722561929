import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid #dedede',
  },
}));
export default function PageTitle({ children }) {
  const classes = useStyles();
  return (
    <Typography className={classes.title} variant="h4" gutterBottom>
      {children}
    </Typography>
  );
}
