import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import API from '../../../api';
import Dashboard from '../../../pages/admin/dashboard';
import SignInPage from '../../../pages/signin/index';
import AppRoute from '../../../router.component';
import Loading from '../../loading';
import routeLinks from '../navigation/routeLinks';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  pageTitle: {
    padding: `0 0 ${theme.spacing(2)}px 0`,
    marginBottom: theme.spacing(2),
    borderBottom: '1px solid #ddd',
  },
  toolbar: theme.mixins.toolbar,
}));

export default () => {
  const classes = useStyles();
  const loading = useSelector((state) => state.loading.isLoading);
  const currentUser = useSelector((state) => state.user.currentUser);

  if (
    process.env.NODE_ENV === 'development' &&
      localStorage.getItem('token') == null
  ) {
    API.post('/admin/auth/token-signin', {}).then((response) => {
      localStorage.setItem('token', JSON.stringify(response.data));
      window.location.reload(false);
    });
  }
  
  return (
    <div className={classes.root}>
      <div className={currentUser && classes.toolbar} />
      
      <Paper className={classes.content}>
        {loading && <Loading />}
	
        <Switch>
          {routeLinks.map((item) => (
            <AppRoute
              path={item.link}
              key={item.link}
              auth={item.auth}
              component={item.component}
              exact
            />
          ))}
          
          {!currentUser && (
            <AppRoute
              exact
              link={'/signin'}
              component={SignInPage}
              auth={false}
            />
          )}
          
          {currentUser && (
            <AppRoute
              exact
              link={'/admin/dashboard'}
              component={Dashboard}
              auth={true}
            />
          )}
        </Switch>
      </Paper>
    </div>
  );
};
