import TeachersPage from '../../../pages/admin/teachers';
import EditTeacherPage from '../../../pages/admin/teachers/edit';

export default [
  {
    link: '/admin/teachers',
    component: TeachersPage,
    auth: true,
    roles: ['admin'],
  },
  {
    link: '/admin/teachers/edit/:id',
    component: EditTeacherPage,
    auth: true,
    roles: ['admin'],
  },
  {
    link: '/admin/teachers/create',
    component: EditTeacherPage,
    auth: true,
    roles: ['admin'],
  },
];
