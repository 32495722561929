import React from 'react';
import CategoryList from '../../../components/admin/categories/List';

function Categories(props) {
  return (
    <React.Fragment>
      <CategoryList />
    </React.Fragment>
  );
}

export default Categories;
