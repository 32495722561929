import React, { useEffect, useState } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import ProductSelect from '../products/ProductSelect';
import ConfirmationDialog from './confirmationDialog';
import PageTitle from '../../app/main/Title';

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core/';

import {
  asyncCreateBrokerProduct,
  asyncUpdateBrokerProduct,
} from '../../../redux/brokerproduct/brokerproduct.actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '2rem 0',
  },
  textField: {
    marginBottom: theme.spacing(2),
    width: '100%',
    margin: 0,
  },
  editableField: {
    color: 'red',
  },
  copyBtn: {
    position: 'absolute',
    top: 18,
    right: 18,
  },
}));

const brokers = [
  { value: 'guru', name: 'Guru' },
];

const brokerProductTypes = [
  { value: 'recurrency', label: 'Assinatura' },
  { value: 'giftcard', label: 'Presente' },
];

const brokerProductFrequency = [
  { value: 'monthly', label: 'Mensal' },
  { value: 'yearly', label: 'Anual' },
];

export default ({ toUpdate }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [oldBrokerProductData] = useState(
    toUpdate || {
      broker_product_id: '',
      gateway_name: '',
      description: '',
      broker_name: '',
      plan: '',
      type: '',
      frequency: '',
      value: '',
      checkout_trial_link: '',
      checkout_link: '',
      notCumulative: false,
      isActive: false,
      group: '',
    }
  );

  const [newBrokerProductData, setNewBrokerProductData] = useState({});
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const handleChange = (key, value) => {
    if (oldBrokerProductData[key] === value) {
      setNewBrokerProductData(_.omit(newBrokerProductData, [key]));
    } else {
      setNewBrokerProductData((brokerProduct) => ({
        ...brokerProduct,
        [key]: value,
      }));
    }
  };

  const [brokerProductSaleLink, setBrokerProductSaleLink] = useState(toUpdate ? `https://checkout.brasilparalelo.com.br/?offer=${oldBrokerProductData._id}` : 'Cadastre o produto para gerar o link');

  useEffect(() => {
    if (Object.keys(newBrokerProductData).length > 0) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }

    if (toUpdate) {
      setBrokerProductSaleLink(`https://checkout.brasilparalelo.com.br/?offer=${toUpdate._id}`)
    }
  }, [newBrokerProductData, toUpdate]);

  const [brokerProductValueLabel, setBrokerProductValueLabel] = useState('Valor');
  const handleValueLabel = (value) => {
    setBrokerProductValueLabel(`Valor${value === '' ? '' : value === 'monthly' ? ' mensal' : ' anual'}`);
  };

  const [copySuccess, setCopySuccess] = useState('Copiar');
  const copyToClipboard = () => {
    navigator.clipboard.writeText(brokerProductSaleLink);
    setCopySuccess('Copiado!');
    setTimeout(() => setCopySuccess('Copiar'), 1000);
  };

  const onProductChange = (value) => handleChange('plan', value);

  const keyToName = (key) => {
    const keyNames = {
      broker_product_id: 'Identificador',
      gateway_name: 'Nome',
      description: 'Descrição',
      broker_name: 'Broker',
      plan: 'Produto',
      type: 'Tipo',
      frequency: 'Frequência',
      value: 'Valor',
      checkout_trial_link: 'Link Guru Trial',
      checkout_link: 'Link Guru Normal',
      group: 'Grupo',
    };
    
    return keyNames[key] || key;
  };

  const openConfirmDialog = (e) => {
    e.preventDefault();
    
    if (((!(newBrokerProductData['checkout_trial_link'] === '') && oldBrokerProductData['checkout_trial_link']) || newBrokerProductData['checkout_trial_link']) ||
       ((!(newBrokerProductData['checkout_link'] === '') && oldBrokerProductData['checkout_link']) || newBrokerProductData['checkout_link'])) {
      setConfirmDialog(true);
    } else {
      alert("Por favor, preencha ao menos um campo de links.");
    }
  };

  const closeConfirmDialog = (e) => {
    e.preventDefault();
    setConfirmDialog(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog(false);
    
    if (toUpdate) {
      dispatch(asyncUpdateBrokerProduct({ id: oldBrokerProductData._id, ...newBrokerProductData }));
      setTimeout(() => window.location.reload(), 500);
    } else {
      dispatch(asyncCreateBrokerProduct(newBrokerProductData));
    }
  };

  return (
    <>
      <PageTitle>
        {toUpdate ? 'Atualizar' : 'Cadastrar'} produto
      </PageTitle>
      
      <form method="PUT" onSubmit={openConfirmDialog}>
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <TextField
                className={classes.textField}
                label="Id do produto no broker"
                defaultValue={oldBrokerProductData.broker_product_id}
                name="broker_product_id"
                required={true}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} md={7}>
              <TextField
                className={classes.textField}
                label="Nome do produto no broker"
                defaultValue={oldBrokerProductData.gateway_name}
                name="gateway_name"
                variant="outlined"
                required={true}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} md={7}>
              <TextField
                className={classes.textField}
                label="Descrição"
                defaultValue={oldBrokerProductData.description}
                variant="outlined"
                name="description"
                required={true}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>

	    <Grid item xs={12} md={3}>
              <TextField
                className={classes.textField}
                label="Grupo"
                defaultValue={oldBrokerProductData.group}
                variant="outlined"
                name="group"
		disabled={true}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <FormControl variant="outlined" className={classes.textField}>
                <InputLabel>Broker</InputLabel>
                <Select
                  defaultValue={oldBrokerProductData.broker_name}
                  name="broker_name"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  label="Broker*"
                  required={true}
                >
                  {brokers.map((b, idx) => (
                    <MenuItem key={`broker-name-${idx}`} value={b.value}>
                      {b.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={6} md={3}>
              <ProductSelect
                onChange={onProductChange}
                value={oldBrokerProductData.plan}
                className={classes.textField}
                required={true}
              />
            </Grid>
            
            <Grid item xs={6} md={3}>
              <FormControl variant="outlined" className={classes.textField}>
                <InputLabel>Tipo</InputLabel>
                <Select
                  defaultValue={oldBrokerProductData.type}
                  name="type"
                  required={true}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  label="Tipo*"
                >
                  {brokerProductTypes.map((t) => (
                    <MenuItem key={t.value} value={t.value}>
                      {t.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={6} md={3}>
              <FormControl variant="outlined" className={classes.textField}>
                <InputLabel>Frequência</InputLabel>
                <Select
                  defaultValue={oldBrokerProductData.frequency}
                  label="Frequência*"
                  name="frequency"
                  required={true}
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.value)
                    handleValueLabel(e.target.value)
                  }}
                >
                  {brokerProductFrequency.map((t) => (
                    <MenuItem key={t.value} value={t.value}>
                      {t.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={6} md={3}>
              <TextField
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  min: 0,
                  step: '0.01',
                  type: 'number',
                }}
                className={classes.textField}
                label={brokerProductValueLabel}
                defaultValue={oldBrokerProductData.value}
                variant="outlined"
                name="value"
                required={true}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                label={`Link Guru Trial`}
                defaultValue={oldBrokerProductData.checkout_trial_link}
                variant="outlined"
                name="checkout_trial_link"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                label={`Link Guru Normal`}
                defaultValue={oldBrokerProductData.checkout_link}
                variant="outlined"
                name="checkout_link"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>

            <Grid item xs={12} style={{position: 'relative'}}>
              <TextField
                className={classes.textField}
                label={`Link para compra`}
                value={brokerProductSaleLink}
                variant="outlined"
                name="broker_product_link"
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                type="button"
                className={classes.copyBtn}
                onClick={copyToClipboard}
              >
                {copySuccess}
              </Button>
            </Grid>
            
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="notCumulative"
                    defaultChecked={oldBrokerProductData.notCumulative}
                    onChange={(e) => { handleChange(e.target.name, e.target.checked) }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Não cumulativa com outras promoções"
              />
            </Grid>
            
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isActive"
                    defaultChecked={oldBrokerProductData.isActive}
                    onChange={(e) => { handleChange(e.target.name, e.target.checked) }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Ativa"
              />
            </Grid>
            
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!canSubmit}
              >
                {toUpdate ? 'Atualizar' : 'Cadastrar'}
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
      
      {confirmDialog && (
        <ConfirmationDialog
          onCancel={closeConfirmDialog}
          onConfirm={handleSubmit}
          title="Os seguintes campos serão alterados:"
          body={Object.keys(newBrokerProductData)
            .map(keyToName)
            .join(', ')}
          cancelText="Cancelar"
          confirmText={toUpdate ? 'Atualizar' : 'Cadastrar'}
        />
      )}
    </>
  );
};
