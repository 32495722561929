import { Button, Grid, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import debounce from 'lodash/debounce';
import React, { useEffect } from 'react';
import { attachPlaylistToCategory } from '../../../integrations/api/categories';
import { getPlaylists } from '../../../integrations/api/playlists';

const useStyle = makeStyles({
  root: {
    width: '100%',
  },
});

export default function AttachPlaylist({ categoryId, types, callback, freeOnly = false }) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const classes = useStyle();
  const fetch = React.useMemo(
    () =>
      debounce(
        (request, callback) => {
          getPlaylists({
            filter: request,
            type: types, // we have not changed API signature to keep backward compatibility
            freeOnly
          }).then((response) => callback(response.data.docs));
        },
        500,
        { leading: true, trailing: true }
      ),
    [types, freeOnly]
  );

  useEffect(() => {
    fetch(inputValue, (options) => setOptions(options));
  }, [inputValue, fetch]);

  const onValueChange = (event, value) => {
    setValue(value);
  };
  const onInputValueChange = (event, value) => {
    setInputValue(value);
  };

  const isOptionSelected = (option, value) => option._id === value._id;
  const confirm = () => {
    attachPlaylistToCategory({ categoryId, playlistId: value._id }).then(() => {
      callback(value);
      setValue(null);
      setInputValue('');
    });
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Autocomplete
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : option.name
          }
          className={classes.root}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          onChange={onValueChange}
          getOptionSelected={isOptionSelected}
          onInputChange={onInputValueChange}
          renderInput={(params) => {
            return (
              <>
                <TextField {...params} label="Adicionar playlist" fullWidth />
              </>
            );
          }}
          renderOption={(option) => {
            return (
              <div>
                <Typography variant="body2" color="textSecondary">
                  {option.name}
                </Typography>
              </div>
            );
          }}
        />
      </Grid>
      <Grid item>
        <Button variant="contained" disabled={!value} onClick={confirm}>
          Adicionar playlist
        </Button>
      </Grid>
    </Grid>
  );
}
