import { offerActionTypes } from './offer.types';
import API from '../../api';
import { setLoading } from '../loading/loading.actions';

export const setCurrentOffer = (payload) => ({
  type: offerActionTypes.SET_CURRENT_OFFER,
  payload,
});

export const fetchOffers = () => {
  return {
    type: offerActionTypes.FETCH_OFFERS,
  };
};

export const receiveOffers = (payload) => {
  return {
    type: offerActionTypes.RECEIVE_OFFERS,
    payload,
  };
};

export const editOffer = (payload) => {
  return {
    type: offerActionTypes.EDIT_OFFER,
    payload,
  };
};

export const asyncFetchOffersByBrokerProductId = (id, params) => (dispatch) => {
  dispatch(fetchOffers);
  API.get(`/broker_products/${id}/offers`, {
    params,
  })
    .then((response) => {
      dispatch(
        receiveOffers({
          data: response.data.docs,
          totalCount: response.data.totalDocs,
          page: response.data.page - 1,
          limit: response.data.limit,
        })
      );
    })
    .catch((err) => console.log(err));
};

export const asyncFetchOfferById = (params) => (dispatch) => {
  dispatch(fetchOffers);
  API.get(`/offers/${params.id}`)
    .then((response) => dispatch(editOffer(response.data)))
    .catch((err) => console.log(err));
};

export const asyncUpdateOffer = (data) => (dispatch) => {
  API.patch(`/offers/${data.id}`, data)
    .then((response) => dispatch(editOffer(response.data)))
    .catch((error) => {
      console.log(error.response);
      alert('Não foi possível atualizar a oferta.');
    })
    .finally(() => dispatch(setLoading(false)));
};
