import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import debounce from 'lodash/debounce';
import React, { useEffect } from 'react';
import api from '../../../api';

export default function UserSelect({
  label,
  defaultValue,
  onChange,
  disabled = false,
  readonly = false,
}) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const fetch = React.useMemo(
    () =>
      debounce(
        (request, callback) => {
          api.get(`/users?filter=${request}&lean=true`).then((response) => {
            callback(response.data.docs);
          });
        },
        500,
        { leading: true, trailing: true }
      ),
    []
  );
  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);
  useEffect(() => {
    let active = true;
    fetch(inputValue, (results) => {
      if (active) {
        setOptions(results);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="user-search-select"
      style={{ width: 300 }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.name
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      disabled={disabled}
      readonly={readonly}
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        if (newValue) {
          setValue(newValue);
          onChange(newValue._id);
        }
      }}
      getOptionSelected={(option, value) => option._id === value._id}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          style={{ marginTop: 16 }}
          {...params}
          label={label}
          fullWidth
        />
      )}
      renderOption={(option) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {option.name}
            </Typography>
          </div>
        );
      }}
    />
  );
}
