import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FilterHdrIcon from '@material-ui/icons/FilterHdr';
import React from 'react';
import GoogleLogin from './googleLogin.component';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: theme.spacing(1),
    color: theme.palette.primary.dark,
    fontSize: 120,
  },
  title: {
    margin: theme.spacing(0, 0, 15, 0),
    fontSize: 50,
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <FilterHdrIcon className={classes.logo} />
        <Typography component="h1" variant="h5" className={classes.title}>
          Painel Admin
        </Typography>
        <GoogleLogin />
      </div>
    </Container>
  );
};
