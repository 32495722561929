import React, { useContext } from 'react';

import { Chip, Grid, TextField } from '@material-ui/core';

import { PlaylistContext } from '../playlists/PlaylistContext';
import { Autocomplete } from '@material-ui/lab';

import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

const MovieInputs = () => {
  const [state, dispatch] = useContext(PlaylistContext);

  const inputChange = (key, value) => {
    dispatch({
      type: 'CHANGE_INPUT',
      payload: {
        [key]: value,
      },
    });
  };

  if (!state.type) return null;

  return (
    <>
      <Grid item xs={12} lg={6}>
        <Autocomplete
          onChange={(event, value) => {
            event.preventDefault();
            inputChange('directors', value);
          }}
          options={[]}
          multiple
          id="tags-filled"
          defaultValue={state.directors}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Diretores"
              helperText="Você pode cadastrar diversos diretores. Digite o nome do diretor e tecle 'enter'."
            />
          )}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Autocomplete
          onChange={(event, value) => {
            event.preventDefault();
            inputChange('cast', value);
          }}
          options={[]}
          multiple
          id="tags-filled2"
          defaultValue={state.cast}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Elenco"
              helperText="Você pode cadastrar diversos atores. Digite o nome do ator e tecle 'enter'."
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            label="Data de Saída"
            value={state.exitDate}
            autoOk={true}
            onChange={(value) => {
              inputChange('exitDate', value);
            }}
            helperText="Data de saída da produção na plataforma (remove toda a playlist e suas mídias)."
            format="dd/MM/yyyy HH:mm"
            variant="inline"
            ampm={false}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} sm={6}></Grid>
    </>
  );
};

export default MovieInputs;
