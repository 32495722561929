const getNavDataFromPathname = (menuData, pathname) => {
  for (const item of menuData) {
    if (item.link.includes(pathname)) {
      return item;
    }

    if (item.subItems) {
      for (const subItem of item.subItems) {
        if (subItem.link.includes(pathname)) {
          return subItem;
        }
      }
    }
  }
};
export default getNavDataFromPathname;
