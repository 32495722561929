import { roleActionTypes } from './role.types';
import API from '../../api';

export const createRole = (payload) => {
  return {
    type: roleActionTypes.CREATE_ROLE,
    payload,
  };
};
export const updateRole = (payload) => {
  return {
    type: roleActionTypes.UPDATE_ROLE,
    payload,
  };
};
export const deleteRole = (payload) => {
  return {
    type: roleActionTypes.DELETE_ROLE,
    payload,
  };
};
export const fetchRoles = () => {
  return {
    type: roleActionTypes.FETCH_ROLES,
  };
};

export const receiveRoles = (payload) => {
  return {
    type: roleActionTypes.RECEIVE_ROLES,
    payload,
  };
};

export const asyncFetchRoles = (data) => (dispatch) => {
  dispatch(fetchRoles);
  API.get('/roles', {
    data,
  })
    .then(
      (response) => response,
      (error) => console.log('erro ao trazer roles', error)
    )
    .then((json) => dispatch(receiveRoles(json.data)));
};
export const asyncCreateRole = (data) => (dispatch) => {
  API.post('/roles', data)
    .then(
      (response) => response.data,
      (error) => console.log('erro ao cadastrar role', error)
    )
    .then((res) => dispatch(createRole(res)));
};

export const asyncDeleteRole = (data) => (dispatch) => {
  API.delete('/roles', {
    params: data,
  })
    .then(
      (response) => response.data,
      (error) => console.log('erro ao deletar role', error)
    )
    .then((res) => dispatch(deleteRole(res)));
};
export const asyncUpdateRole = (data) => (dispatch) => {
  API.put('/roles', data)
    .then(
      (response) => response.data,
      (error) => console.log('erro ao atualizar role', error)
    )
    .then((res) => dispatch(updateRole(res)));
};
