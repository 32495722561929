import PlayListsPage from '../../../pages/admin/playlists';
import TypePlaylists from '../../../components/admin/playlists/list';

import getTypeHandler from '../../admin/types/pageTypeHandler';
import { useParams } from 'react-router-dom';

const TypeCreateHandler = function() {
  const { typeName } = useParams();
  return getTypeHandler(typeName).create();
};

const TypeUpdateHandler = function() {
  const { typeName } = useParams();
  return getTypeHandler(typeName).update();
};

export default [
  {
    link: '/admin/playlists',
    component: PlayListsPage,
    auth: true,
    roles: ['admin'],
  },
  {
    link: '/admin/playlists/:typeName',
    component: TypePlaylists,
    auth: true,
    roles: ['admin'],
  },
  {
    link: '/admin/playlists/:typeName/create',
    component: TypeCreateHandler,
    auth: true,
    roles: ['admin'],
  },
  {
    link: '/admin/playlists/:typeName/update/:id',
    component: TypeUpdateHandler,
    auth: true,
    roles: ['admin'],
  },
];
