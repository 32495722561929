import React, { useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { createClient, Provider } from 'urql';

import {
  createTheme as createMuiTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';

import Bar from './components/app/bar/bar.component';
import AppDrawer from './components/app/drawer/drawer.component';
import Main from './components/app/main/main.component';
import { NavProvider } from './components/app/navigation/NavContext';
import { closeSnackBar } from './redux/snackbar/snackbar.actions';
import { asyncFetchCurrentUser } from './redux/user/user.actions';

const hermesProductionUrl =  'https://hermes.brasilparalelo.com.br/api';

const hermesClient = createClient({
  url: hermesProductionUrl,
  fetchOptions: () => {
    const token = localStorage.getItem('token')
      ? JSON.parse(localStorage.getItem('token'))
      : undefined
    if (token) {
      return {
        headers: { Authorization: token },
      }
    }
    return {}
  },
})

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

const darkTheme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function App() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const snackbar = useSelector((state) => state.snackbar);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(asyncFetchCurrentUser());
    }
  }, [dispatch]);

  return (
    <Provider value={hermesClient}>
      <div className={classes.root}>
        <NavProvider>
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
	    
            {currentUser && (
              <React.Fragment>
                <Bar />
                <AppDrawer currentUser={currentUser} />
              </React.Fragment>
            )}
	    
            <Snackbar
              open={snackbar.open}
              autoHideDuration={6000}
              onClose={() => dispatch(closeSnackBar())}
            >
              <Alert severity={snackbar.type}>{snackbar.message}</Alert>
            </Snackbar>
	    
            <Main />
          </ThemeProvider>
        </NavProvider>
      </div>
    </Provider>
  );
}
