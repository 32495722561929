import { Button, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import moment from 'moment';
import { debounceSearchRender } from 'mui-datatables';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { asyncFetchPlayLists } from '../../../redux/playlists/playlist.actions';
import DataTable from '../../table/DataTable';
const useStyles = makeStyles((theme) => ({
  tableRow: { borderBottom: '1px solid rgba(224, 224, 224, 1)' },
  tableColumn: {
    padding: theme.spacing(2),
  },
}));
export default function CustomPaginationActionsTable() {
  let history = useHistory();
  const { typeName } = useParams();
  const [playlists, setPlaylists] = useState({});
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState('');
  const classes = useStyles();
  const { docs = [], totalDocs: count = 0 } = playlists;

  const fetchPlaylists = useCallback(() => {
    asyncFetchPlayLists({
      limit,
      page: page + 1,
      type: typeName,
      filter,
    })
      .then((response) => {
        setPlaylists(response.data);
      })
      .catch(() => {
        alert('Falha ao carregar as playlists');
      });
  }, [typeName, page, limit, filter]);

  useEffect(fetchPlaylists, [fetchPlaylists]);

  const columns = [
    {
      name: 'name',
      label: 'Nome',
    },
    {
      name: 'createdAt',
      label: 'Cadastrada em',
    },
    {
      name: 'type',
      label: 'Tipo',
    },
    {
      options: {
        empty: true,
      },
      name: '_id',
      label: ' ',
    },
  ];

  function onClickEditButton(type, rowDataId) {
    history.push(`/admin/playlists/${type}/update/${rowDataId}`);
  }

  const options = {
    page,
    selectableRows: 'none',
    print: false,
    download: false,
    serverSide: true,
    count,
    responsive: 'vertical',
    tableBodyHeight: '100%',
    tableBodyMaxHeight: '',
    sort: false,
    filter: false,
    viewColumns: false,
    customSearchRender: debounceSearchRender(500),
    onSearchChange: (newFilter) => setFilter(newFilter),
    onChangePage: (newPage) => setPage(newPage),
    onChangeRowsPerPage: (limit) => setLimit(limit),
    customRowRender: (data) => {
      const [name, createdAt, type, _id] = data;
      return (
        <tr className={classes.tableRow} key={_id}>
          <td className={classes.tableColumn}>{name}</td>
          <td className={classes.tableColumn}>
            {moment(createdAt).format('DD/MM/YYYY')}
          </td>
          <td className={classes.tableColumn}>{type}</td>
          <td>
            <IconButton
              aria-label="upload picture"
              component="span"
              onClick={() => {
                onClickEditButton(type, _id);
              }}
            >
              <EditIcon />
            </IconButton>
          </td>
        </tr>
      );
    },
  };

  return (
    <React.Fragment>
      {typeName && (
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<SaveIcon />}
            onClick={() => history.push(`/admin/playlists/${typeName}/create`)}
          >
            Inserir novo
          </Button>
        </div>
      )}
      <DataTable data={docs} columns={columns} options={options} />
    </React.Fragment>
  );
}
