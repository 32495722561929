import React, { useState, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import {
  CloudDownload as CloudDownloadIcon,
  Replay as ReplayIcon,
} from '@material-ui/icons';
import { debounceSearchRender } from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { asyncFetchUploadedUsersFiles } from '../../../redux/files/files.actions';
import PaymentApi from '../../../payment-api';
import DataTable from '../../table/DataTable';

const definedStatusLabel = (processing_status) => {
  switch (processing_status) {
    case 'received':
      return 'Recebido';
    case 'enqueued':
      return 'Aguardando processamento';
    case 'not-enqueued':
      return 'Erro de envio';
    case 'in-process':
      return 'Processando';
    case 'success':
      return 'Sucesso';
    case 'finished-with-error':
      return 'Processado com erros';
    default:
      return '';
  }
};

const createErrorFileName = (s) => {
  if (s.includes('.')) {
    const lastIdx = s.lastIndexOf('.');
    return s.substr(0, lastIdx) + '-error' + s.substr(lastIdx);
  }

  return s + '-error';
};

const UploadedFiles = () => {
  const dispatch = useDispatch();
  const files = useSelector((state) => state.files.uploadedUsersFiles.docs);
  const page = useSelector((state) => state.files.uploadedUsersFiles.page);
  const rowsPerPage = useSelector(
    (state) => state.files.uploadedUsersFiles.limit
  );
  const totalCount = useSelector(
    (state) => state.files.uploadedUsersFiles.totalCount
  );
  const [filter, setFilter] = useState('');

  useEffect(() => {
    dispatch(asyncFetchUploadedUsersFiles({ page: 1, limit: 10 }));
  }, [dispatch]);

  const columns = [
    { name: 'file_processing_id', label: ' ' },
    { name: 'uploaded_file_name', label: 'Arquivo' },
    { name: 'processing_status', label: 'Status' },
    { name: 'received_at', label: 'Recebido em' },
    { name: 'created_by', label: 'Criado por' },
    { name: 'file_processing_id', label: 'Arquivo de Erros' },
  ];

  const onClickRetryButton = (id) =>
      PaymentApi.post('api/files/create_members/enqueue', null, { params: { id } });

  const onClickDownloadButton = (id, name, type) => {
    PaymentApi.get('/api/files/create_members', { params: { id, type } })
      .then((res) => {
        // Create anchor el in browser's memory with href to the file, click it and clean up the dom
        const link = document.createElement('a');

        var blob = new Blob([res.data.file]);
        var href = URL.createObjectURL(blob, {
          type: 'text/plain',
        });
        link.href = href;

        const fileName = type === 'error' ? createErrorFileName(name) : name;
        link.setAttribute('download', fileName);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => console.log(err));
  };

  const options = {
    page: page,
    selectableRows: 'none',
    print: false,
    download: false,
    serverSide: true,
    count: totalCount,
    responsive: 'vertical',
    tableBodyHeight: '100%',
    tableBodyMaxHeight: '',
    sort: false,
    filter: false,
    viewColumns: false,
    customSearchRender: debounceSearchRender(500),
    onSearchChange: (newFilter) => {
      setFilter(newFilter);
      new Promise((resolve) => {
        resolve(
          dispatch(
            asyncFetchUploadedUsersFiles({
              filter: newFilter,
              page: 1,
              limit: rowsPerPage,
            })
          )
        );
      });
    },
    onChangePage: (newPage) => {
      new Promise((resolve) => {
        resolve(
          dispatch(
            asyncFetchUploadedUsersFiles({
              filter: filter,
              page: newPage + 1,
              limit: rowsPerPage,
            })
          )
        );
      });
    },
    onChangeRowsPerPage: (limit) => {
      new Promise((resolve) => {
        resolve(
          dispatch(
            asyncFetchUploadedUsersFiles({
              filter: filter,
              page: 1,
              limit: limit,
            })
          )
        );
      });
    },
    customRowRender: (data) => {
      const [
        file_processing_id,
        uploaded_file_name,
        processing_status,
        received_at,
        created_by,
      ] = data;
      return (
        <tr
          key={uploaded_file_name}
          style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
        >
          <td style={{ width: 10 }}>
            <IconButton
              aria-label="donwload original file"
              component="span"
              onClick={() =>
                onClickDownloadButton(
                  file_processing_id,
                  uploaded_file_name,
                  'uploaded'
                )
              }
            >
              <CloudDownloadIcon />
            </IconButton>
          </td>

          <td style={{ width: 100 }}>{uploaded_file_name}</td>

          <td style={{ width: 100 }}>
            {definedStatusLabel(processing_status)}{' '}
            {processing_status === 'not-enqueued' && (
              <IconButton
                aria-label="donwload error file"
                component="span"
                color="error"
                onClick={() => onClickRetryButton(file_processing_id)}
              >
                <ReplayIcon />
              </IconButton>
            )}
          </td>

          <td style={{ width: 25 }}>
            {moment(received_at).format('DD/MM/YYYY')}
          </td>
          <td style={{ width: 25 }}>{created_by}</td>

          <td style={{ width: 10 }}>
            {processing_status === 'finished-with-error' && (
              <IconButton
                aria-label="donwload error file"
                component="span"
                color="error"
                onClick={() =>
                  onClickDownloadButton(
                    file_processing_id,
                    uploaded_file_name,
                    'error'
                  )
                }
              >
                <CloudDownloadIcon />
              </IconButton>
            )}
          </td>
        </tr>
      );
    },
  };

  return <DataTable data={files} columns={columns} options={options} />;
};

export default UploadedFiles;
