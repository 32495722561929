import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import debounce from 'lodash/debounce';
import React, { useEffect } from 'react';
import { getPlaylists } from '../../../integrations/api/playlists';

const useStyle = makeStyles({
  root: {
    width: '100%',
  },
});

export default function PlaylistSelect({
  type,
  callback,
  defaultValue,
  label = 'Adicionar playlist',
  ...props
}) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const classes = useStyle();
  const fetch = React.useMemo(
    () =>
      debounce(
        (request, cb) => {
          getPlaylists({
            filter: request,
            type,
          }).then((response) => cb(response.data.docs));
        },
        500,
        { leading: true, trailing: true }
      ),
    [type]
  );

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    fetch(inputValue, (options) => setOptions(options));
  }, [inputValue, fetch]);

  const onValueChange = (event, value) => {
    setValue(value);
    callback(value);
  };

  const onInputValueChange = (event, value) => {
    setInputValue(value);
  };

  const isOptionSelected = (option, value) => option._id === value._id;
  return (
    <Autocomplete
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.name
      }
      className={classes.root}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      {...props}
      value={value}
      onChange={onValueChange}
      getOptionSelected={isOptionSelected}
      onInputChange={onInputValueChange}
      renderInput={(params) => {
        return (
          <>
            <TextField {...params} label={label} fullWidth />
          </>
        );
      }}
      renderOption={(option) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {option.name}
            </Typography>
          </div>
        );
      }}
    />
  );
}
