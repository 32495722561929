import { mediaActionTypes } from './medias.types';

const INITIAL_STATE = {
  media: undefined,
  medias: [],
  isFetching: false,
};

const mediaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case mediaActionTypes.FETCH_MEDIAS:
      return {
        ...state,
        isFetching: true,
      };
    case mediaActionTypes.RECEIVE_MEDIA:
      return {
        ...state,
        media: action.payload,
        isFetching: false,
      };
    case mediaActionTypes.RECEIVE_MEDIAS:
      return {
        ...state,
        medias: action.payload,
        isFetching: false,
      };
    case mediaActionTypes.UPDATE_MEDIAS_ORDER:
      return {
        ...state,
        medias: action.payload,
      };
    case mediaActionTypes.CREATE_MEDIA:
      return {
        ...state,
        medias: [].concat(state.medias, [action.payload]),
      };
    case mediaActionTypes.UPDATE_MEDIA:
      return {
        ...state,
        medias: state.medias.map((p) =>
          p._id === action.payload._id ? Object.assign(p, action.payload) : p
        ),
      };
    case mediaActionTypes.DELETE_MEDIA:
      return {
        ...state,
        medias: state.medias.filter((p) => p._id !== action.payload._id),
      };
    default:
      return state;
  }
};
export default mediaReducer;
