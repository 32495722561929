import MoneyIcon from '@material-ui/icons/Money';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BannersIcon from '@material-ui/icons/DynamicFeed';
import ForumIcon from '@material-ui/icons/Forum';
import AddIcon from '@material-ui/icons/Add';
import GroupIcon from '@material-ui/icons/Group';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import SchoolIcon from '@material-ui/icons/School';
import typeToPageMap from '../../admin/playlists/typeToPageMap';
import { Pages } from '@material-ui/icons';

const contentTypes = [
  'serie',
  'entrevista',
  'live',
  'debate',
  'podcast',
  'programa',
  'canal',
  'short',
  'bp-select',
  'bp-kids',
];

const courseTypes = [
  'curso',
  'sociedade-do-livro',
  'escola-da-familia',
  'clube-da-musica',
  'travessia',
  'produto',
];

const menuItems = () => [
  {
    text: 'Dashboard',
    icon: DashboardIcon,
    link: '/admin/dashboard',
    roles: ['admin'],
  },
  {
    text: 'Banners',
    icon: BannersIcon,
    link: '#',
    roles: ['admin'],
    subItems: [
      {
        text: 'Banner Principal',
        link: '/admin/banners',
        roles: ['admin'],
      },
    ],
  },
  {
    text: 'Cadastros auxiliares',
    icon: AddIcon,
    link: '#',
    roles: ['admin'],
    subItems: [
      {
        text: 'Professores',
        link: '/admin/teachers',
        roles: ['admin'],
      },
    ],
  },
  {
    text: 'Cadastro de conteúdo',
    icon: VideoLibraryIcon,
    link: '#',
    roles: ['admin'],
    subItems: contentTypes.map((type) => {
      return {
        text: typeToPageMap[type],
        link: `/admin/playlists/${type}`,
        roles: ['admin'],
        playlistType: type,
      };
    }),
  },
  {
    text: 'Cadastro de cursos',
    icon: SchoolIcon,
    link: '#',
    roles: ['admin'],
    subItems: courseTypes.map((type) => {
      return {
        text: typeToPageMap[type],
        link: `/admin/playlists/${type}`,
        roles: ['admin'],
        playlistType: type,
      };
    }),
  },
  {
    text: 'Comentários',
    icon: ForumIcon,
    link: '#',
    roles: ['admin'],
    subItems: [
      {
        text: 'Comentários',
        link: '/admin/comment-feed',
        roles: ['admin'],
      },
      {
        text: 'Disponibilidade',
        link: '/admin/enabled-comments-rules',
        roles: ['admin'],
      },
    ],
  },
  {
    text: 'Membros',
    icon: GroupIcon,
    link: '#',
    roles: ['admin'],
    subItems: [
      {
        text: 'Gestão',
        link: '/admin/users',
        roles: ['admin'],
      },
      {
        text: 'Promoções',
        link: '/admin/users/promotion-actions',
        roles: ['admin'],
      },
      {
        text: 'Geo-ip Manager',
        link: '/admin/users/geo-ip-manager',
        roles: ['admin'],
      },
    ],
  },
  {
    text: 'Páginas',
    icon: Pages,
    link: '/admin/pages',
    roles: ['admin'],
  },
  {
    text: 'Produtos',
    icon: MoneyIcon,
    link: '/admin/broker-products',
    roles: ['admin'],
  },
];

export default menuItems;
