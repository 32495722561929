import { playListTypesActionTypes } from './playlisttype.types';

const INITIAL_STATE = {
  playlisttypes: [],
  isFetching: false,
};

const playlistTypesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case playListTypesActionTypes.FETCH_PLAYLISTTYPES:
      return {
        ...state,
        isFetching: true,
      };
    case playListTypesActionTypes.RECEIVE_PLAYLISTTYPES:
      return {
        ...state,
        playlisttypes: action.payload,
        isFetching: Object.assign({}, { isFetching: false }),
      };
    case playListTypesActionTypes.CREATE_PLAYLISTTYPE:
      return {
        ...state,
        playlisttypes: [].concat(state.playlisttypes, [action.payload]),
      };
    case playListTypesActionTypes.UPDATE_PLAYLISTTYPES:
      return {
        ...state,
        playlisttypes: state.playlisttypes.map((p) =>
          p._id === action.payload._id ? Object.assign(p, action.payload) : p
        ),
      };
    case playListTypesActionTypes.DELETE_PLAYLISTTYPES:
      return {
        ...state,
        playlisttypes: state.playlisttypes.filter(
          (p) => p._id !== action.payload._id
        ),
      };
    default:
      return state;
  }
};
export default playlistTypesReducer;
