import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useContext } from 'react';
import { PlaylistContext } from '../playlists/PlaylistContext';
import Form from './Form';
import List from './List';
export default function Materials() {
  const [state, dispatch] = useContext(PlaylistContext);
  const handleClose = () =>
    dispatch({
      type: 'CLOSE_MATERIALS',
    });
  return (
    <div>
      <Dialog
        open={state.materialsForm.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          Materiais complementares
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Form />
          </DialogContentText>
          <List materials={[{ name: 'Material 1' }, { name: 'Material 2' }]} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
