import { makeStyles } from '@material-ui/core';
import React from 'react';
import Body from './body';

const useStyle = makeStyles((theme) => ({
  comments: {
    marginTop: theme.spacing(2),
  },
  comment: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1),
    '&:hover': {
      '& > .actions-wrapper': {
        opacity: 1,
      },
    },
  },
}));

const Comment = ({ comment }) => {
  const classes = useStyle();
  return (
    <div
      style={{ maxWidth: '600px' }}
      className={classes.comment}
      id={comment._id}
    >
      <Body comment={comment} />
    </div>
  );
};

export default Comment;
