import {
  Button,
  makeStyles,
  Divider,
  TextField,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createPage } from '../../../integrations/api/pages';
import store from '../../../redux/store';
import { openSnackBar } from '../../../redux/snackbar/snackbar.actions';

const useStyles = makeStyles((theme) => ({
  form: {
    marginBottom: theme.spacing(2),
  },
  input: {
    marginRight: theme.spacing(1),
    minWidth: 300,
  },
}));

export default () => {
  const [name, setName] = useState('');
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    createPage({ name: name, type: 'all' })
      .then((res) => {
        setName('');
        store.dispatch(
          openSnackBar({
            type: 'success',
            message: 'Nova página criada!',
          })
        );
        history.push(`/admin/pages/${res.data._id}`);
      })
      .finally(() => setLoading(false));
  };

  return (
    <form onSubmit={onSubmit} className={classes.form}>
      <Typography gutterBottom variant="h5">
        Cadastrar página
      </Typography>

      <Divider />
      <Grid container direction="column" spacing={2}>
        <Grid item xs={5}>
          <TextField
            name="name"
            label="Nome da página"
            placeholder="Nova página"
            value={name}
            className={classes.input}
            onChange={(event) => setName(event.target.value)}
          />
        </Grid>

        <Grid container direction="row-reverse">
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              type="submit"
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
