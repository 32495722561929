import { userActionTypes } from './user.types';

const INITIAL_STATE = {
  currentUser: null,
  users: [],
  toUpdate: null,
  forgotSucceed: false,
  resetSucceed: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userActionTypes.FETCH_USERS:
      return {
        ...state,
        isFetching: true,
      };
    case userActionTypes.EDIT_USER:
      return {
        ...state,
        toUpdate: action.payload,
      };
    case userActionTypes.RECEIVE_USERS:
      return {
        ...state,
        users: action.payload.data,
        page: action.payload.page,
        totalCount: action.payload.totalCount,
        limit: action.payload.limit,
        isFetching: true,
      };
    case userActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    default:
      return state;
  }
};
export default userReducer;
