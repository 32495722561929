import React from 'react';
import TeacherList from '../../../components/admin/teachers/list';

function Teachers() {
  return (
    <React.Fragment>
      <TeacherList />
    </React.Fragment>
  );
}

export default Teachers;
