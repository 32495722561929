import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core/';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { asyncFetchBrokerProductById } from '../../../redux/brokerproduct/brokerproduct.actions';
import Form from './form';
import OffersList from '../offers/list';

export default () => {
  const dispatch = useDispatch();
  
  const { id } = useParams();
  let toUpdate = useSelector((state) => state.brokerProduct.toUpdate);

  useEffect(() => {
    if (!toUpdate) {
      dispatch(asyncFetchBrokerProductById({ id }));
    }
  }, [dispatch, id, toUpdate]);

  return (
    <>
      {toUpdate && (
	<>
	  <Form toUpdate={toUpdate} />
	  <hr style={{ margin: '2rem 0', border: 'none' }} />
	  <OffersList brokerProductId={id} />
	</>
      )}
      
      {!toUpdate && (
        <Typography variant="h4" component="h4">
          Produto do broker não encontrado.
        </Typography>
      )}
    </>
  );
};
