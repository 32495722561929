import { Button, makeStyles, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { createCategory } from '../../../integrations/api/pages';

const useStyles = makeStyles((theme) => ({
  form: {
    marginBottom: theme.spacing(2),
  },
  input: {
    marginRight: theme.spacing(1),
    minWidth: 300,
  },
}));
export default ({ pageId, callback }) => {
  const [category, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const onSubmit = (event) => {
    if (!category.trim()) {
      alert('O nome da categoria é inválido');

      return;
    }
    setLoading(true);

    createCategory(pageId, { category })
      .then((response) => {
        console.log(response);
        callback(response);
        setName('');
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={classes.form}>
      <TextField
        name="category"
        placeholder="Nova categoria"
        value={category}
        onKeyPress={(e) => {
          const enter = e.key === 'Enter';
          if (enter) {
            e.preventDefault();
            e.stopPropagation();
            onSubmit();
          }
        }}
        className={classes.input}
        onChange={(event) => setName(event.target.value)}
      />
      <Button
        variant={'contained'}
        onClick={onSubmit}
        disabled={loading}
        type="button"
      >
        Adicionar
      </Button>
    </div>
  );
};
