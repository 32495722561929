import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPlaylistById } from '../../../integrations/api/playlists';
import Form from '../playlists/Form';
import { PlaylistContextProvider } from '../playlists/PlaylistContext';
import SerieInputs from './Form';

const SerieUpdateHandler = () => {
  const { id } = useParams();
  const [playlist, setSerie] = useState();

  useEffect(() => {
    getPlaylistById(id).then((p) => {
      setSerie(p);
    });
  }, [id]);

  return playlist ? (
    <PlaylistContextProvider>
      <Form
        initialValues={{
          name: playlist.name || '',
          playlist: playlist.playlist || '',
          productionReleaseDate:
            playlist?.productionReleaseDate ||
            playlist?.releaseDate ||
            playlist?.createdAt ||
            new Date(),
          releaseDate:
            playlist?.releaseDate || playlist?.createdAt || new Date(),
          heroWeb: playlist.heroWeb || undefined,
          playlistLogo: playlist.playlistLogo || undefined,
          thumbnails: playlist.thumbnails || {},
          thumbnailsFiles: playlist.thumbnailsFiles || {},
          hero: playlist.hero || undefined,
          heroFile: playlist.heroFile || '',
          dvdFile: playlist.dvdFile || '',
          description: playlist.description || '',
          thumbnailFile: playlist.thumbnailFile || '',
          coverFile: playlist.coverFile || '',
          thumbnail: playlist.thumbnail || '',
          cover: playlist.cover || '',
          modules: playlist.modules || [],
          medias: playlist.medias || [],
          roles: playlist.roles || [],
          type: playlist.type || [],
          teaser: playlist.teasers ? playlist.teasers[0] : undefined,
          _id: playlist._id || '',
          directors: playlist.directors || [],
          rating: playlist.rating,
          freeOnly: playlist.freeOnly || false,
          freemium_banner_img: playlist.freemium_banner_img || undefined,
        }}
        action="update"
      >
        <SerieInputs />
      </Form>
    </PlaylistContextProvider>
  ) : null;
};

const SerieCreateHandler = () => {
  return (
    <PlaylistContextProvider>
      <Form initialValues={{ type: 'serie', directors: [] }} action="create">
        <SerieInputs />
      </Form>
    </PlaylistContextProvider>
  );
};

export { SerieUpdateHandler, SerieCreateHandler };
