import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserById } from '../../../integrations/api/users';

import { createUser, updateUser } from '../../../integrations/api/users';

import UserForm from './Form';

const UserUpdateHandler = () => {
  const { id } = useParams();
  const [user, setUser] = useState();

  useEffect(() => {
    getUserById(id).then((p) => setUser(p));
  }, [id]);
  return user ? <UserForm data={user} submitHandler={updateUser} /> : null;
};

const UserCreateHandler = () => {
  return <UserForm data={{}} submitHandler={createUser} />;
};

export { UserUpdateHandler, UserCreateHandler };
