import React, { useState, useEffect } from 'react';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Divider } from '@material-ui/core';
import Comment from '../../../components/comments';
import { getComments } from '../../../integrations/api/comment-feed';
import API from '../../../api';

function CommentFeed() {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    getComments().then((response) => {
      setComments(response.data);
    });
  }, []);

  return (
    <>
      {comments.map((comment) => (
        <div key={comment._id}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              gap: '12px',
            }}
          >
            <a
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, .05)',
                color: 'rgba(0, 0, 0, .3)',
              }}
              target="_blank"
              rel="noopener noreferrer"
              href={`${API.defaults.baseURL}/admin/redirect-to-media?mediaId=${comment.media}`}
            >
              <KeyboardArrowRightIcon />
            </a>
            <Comment comment={comment} />
          </div>
          <Divider />
        </div>
      ))}
    </>
  );
}

export default CommentFeed;
