import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getPlaylistById } from '../../../integrations/api/playlists';
import { createPlaylist } from '../../../integrations/api/playlists';
import { PlaylistContextProvider } from './PlaylistContext';
import PlaylistForm from './Form';

const PlaylistUpdateHandler = () => {
  const { id } = useParams();
  const [playlist, setPlaylist] = useState();

  useEffect(() => {
    getPlaylistById(id).then((p) => setPlaylist(p));
  }, [id]);

  return playlist ? (
    <PlaylistContextProvider>
      <PlaylistForm
        initialValues={{
          playlist: playlist.playlist || '',
          name: playlist.name || '',
          productionReleaseDate:
            playlist?.productionReleaseDate ||
            playlist?.releaseDate ||
            playlist?.createdAt ||
            new Date(),
          releaseDate:
            playlist?.releaseDate || playlist?.createdAt || new Date(),
          description: playlist.description || '',
          thumbnail: playlist.thumbnail || '',
          thumbnailFile: playlist.thumbnailFile || '',
          heroWeb: playlist.heroWeb || undefined,
          playlistLogo: playlist.playlistLogo || undefined,
          cover: playlist.cover || '',
          coverFile: playlist.coverFile || '',
          thumbnailsFiles: playlist.thumbnailsFiles || {},
          thumbnails: playlist.thumbnails || {},
          hero: playlist.hero || undefined,
          heroFile: playlist.heroFile || '',
          dvdFile: playlist.dvdFile || '',
          teacher: playlist.teacher || '',
          level: playlist.level || '',
          modules: playlist.modules || [],
          medias: playlist.medias || [],
          roles: playlist.roles || [],
          type: playlist.type || [],
          _id: playlist._id || '',
          teaser: playlist.teasers ? playlist.teasers[0] : undefined,
          rating: playlist.rating,
          freeOnly: playlist.freeOnly || false,
          freemium_banner_img: playlist.freemium_banner_img || undefined,
        }}
        action="update"
      ></PlaylistForm>
    </PlaylistContextProvider>
  ) : null;
};

const PlaylistCreateHandler = (type) => {
  return () => {
    return (
      <PlaylistContextProvider>
        <PlaylistForm
          initialValues={{ type }}
          submitHandler={async (data) => await createPlaylist(data)}
        />
      </PlaylistContextProvider>
    );
  };
};

export { PlaylistUpdateHandler, PlaylistCreateHandler };
