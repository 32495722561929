import PagesPage from '../../../pages/admin/pages';
import Create from '../../admin/pages/Create';
import PageUpdate from '../../admin/pages/Update';
export default [
  {
    link: '/admin/pages',
    component: PagesPage,
    auth: true,
    roles: ['admin'],
  },
  {
    link: '/admin/pages/create',
    component: Create,
    auth: true,
    roles: ['admin'],
  },
  {
    link: '/admin/pages/:_id',
    component: PageUpdate,
    auth: true,
    roles: ['admin'],
  },
];
