import { Button, makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { useContext } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { PlaylistContext } from '../playlists/PlaylistContext';
import ModuleList from './List';

const useStyle = makeStyles((theme) => ({
  actions: {
    textAlign: 'right',
    '& $Button': {
      marginLeft: theme.spacing(2),
    },
    marginBottom: theme.spacing(2),
  },
}));
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const DndModules = ({
  modules,
  isModular,
  isCourse,
  advisoryTags,
  advisoryRatings,
}) => {
  const classes = useStyle();
  const [state, dispatch] = useContext(PlaylistContext);

  const addModule = () => {
    dispatch({
      type: 'ADD_MODULE',
      payload: {
        name: '',
        medias: [],
      },
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const records = reorder(
      state.modules,
      result.source.index,
      result.destination.index
    );
    dispatch({
      type: 'SET_MODULES',
      payload: {
        modules: records,
      },
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={classes.actions}>
        <Button
          startIcon={<Add />}
          variant={'contained'}
          color="primary"
          onClick={addModule}
        >
          Novo módulo
        </Button>
      </div>
      {modules.length > 0 ? (
        <ModuleList
          modules={modules}
          isModular={isModular}
          isCourse={isCourse}
          advisoryTags={advisoryTags}
          advisoryRatings={advisoryRatings}
        />
      ) : (
        <Alert severity="info">Não há nada aqui ainda...</Alert>
      )}
    </DragDropContext>
  );
};

export default DndModules;
