import {
  Button,
  Grid,
  IconButton,
  Input,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { uploadMaterial } from '../../../integrations/api/materials';
import { PlaylistContext } from '../playlists/PlaylistContext';
const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(2)} 0`,
    paddingBottom: theme.spacing(2),
  },
  input: {
    display: 'none',
  },
  fileName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '200px', // some width
  },
}));
export default () => {
  const classes = useStyles();
  const [, dispatch] = useContext(PlaylistContext);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: '',
    file: undefined,
  });

  const handleChange = (event) => {
    setForm((old) => ({ ...old, file: event.target.files[0] }));
  };
  const handleNameChange = (event) => {
    event.persist();
    setForm((old) => ({
      ...old,
      name: event.target.value,
    }));
  };
  const handleSubmit = () => {
    const { name, file } = form;
    if (!name || !file) alert('Revise os campos!');
    const fd = new FormData();
    fd.append('material', form.file);
    setLoading(true);
    return uploadMaterial(fd)
      .then((data) => {
        const { url } = data;
        dispatch({
          type: 'ADD_MATERIAL',
          payload: {
            name,
            link: url,
          },
        });
        setForm(() => ({ name: '', file: null }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="flex-end" justifyContent="flex-end">
        <Grid item>
          <TextField
            label="Novo material"
            name="name"
            value={form.name}
            onChange={handleNameChange}
          />
        </Grid>
        <Grid item>
          {form.file ? (
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <div className={classes.fileName} title={form.file.name}>
                  {form.file.name}
                </div>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => setForm((old) => ({ ...old, file: null }))}
                >
                  <Clear />
                </IconButton>
              </Grid>
            </Grid>
          ) : (
            <Button variant="contained" component="label">
              Enviar arquivo
              <Input
                type="file"
                hidden
                className={classes.input}
                onChange={handleChange}
              />
            </Button>
          )}
        </Grid>
        {form.file && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={handleSubmit}
            >
              Adicionar
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
