import { offerActionTypes } from './offer.types';

const INITIAL_STATE = {
  currentOffer: null,
  offers: [],
  toUpdate: null,
  forgotSucceed: false,
  resetSucceed: false,
};

const offerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case offerActionTypes.FETCH_OFFERS:
      return {
        ...state,
        isFetching: true,
      };
    case offerActionTypes.EDIT_OFFER:
      return {
        ...state,
        toUpdate: action.payload,
      };
    case offerActionTypes.RECEIVE_OFFERS:
      return {
        ...state,
        offers: action.payload.data,
        page: action.payload.page,
        totalCount: action.payload.totalCount,
        limit: action.payload.limit,
        isFetching: true,
      };
    case offerActionTypes.SET_CURRENT_OFFER:
      return {
        ...state,
        currentOffer: action.payload,
      };
    default:
      return state;
  }
};
export default offerReducer;
