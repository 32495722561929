import OfferDetailsPage from '../../admin/offers/details';

export default [
  {
    link: '/admin/offers/details/:id',
    component: OfferDetailsPage,
    auth: true,
    roles: ['admin'],
  },
];
