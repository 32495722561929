import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Logout from '../../signin/googleLogout.component';
import API from '../../../api';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    flexGrow: 1,
  },
}));

const devThemeStyle = {
  backgroundColor: 'yellow',
  color: 'black',
};

const highlight = (text, isLocal) => (
  <span
    style={{
      fontWeight: 'bold',
      paddingLeft: 4,
      paddingRight: 4,
      color: isLocal ? 'yellow' : 'blue',
      backgroundColor: isLocal ? 'black' : 'white',
    }}
  >
    {text}
  </span>
);

export default () => {
  let classes = null;
  let hostname = window.location.hostname;
  let backendUrl = API.defaults.baseURL;
  let bothLocal = [hostname, backendUrl].every((s) => s.includes('localhost'));
  let someLocal = [hostname, backendUrl].some((s) => s.includes('localhost'));

  classes = useStyles();

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      style={bothLocal ? devThemeStyle : null}
    >
      <Toolbar>
        <Typography variant="h6" noWrap className={classes.title}>
          <div>Brasil Paralelo</div>
          {someLocal && (
            <div style={{ fontSize: 15, fontWeight: 'normal' }}>
              Running on {highlight(hostname, hostname.includes('localhost'))}{' '}
              and pointing to{' '}
              {highlight(backendUrl, backendUrl.includes('localhost'))}
            </div>
          )}
        </Typography>
        <Logout />
      </Toolbar>
    </AppBar>
  );
};
