import React from 'react';
import { Typography } from '@material-ui/core';
import moment from 'moment';

export default ({ comment, live }) => {
  return (
    <div
      style={
        comment.alert && {
          padding: '5px',
          backgroundColor: 'rgba(0, 144, 255, .2)',
        }
      }
      className="flex flex-col items-start"
    >
      <Typography variant="caption" display="block" gutterBottom>
        <strong>{comment.author.name}</strong>{' '}
        {!live && formatedCommentDate(comment.createdAt)}
      </Typography>

      <Typography
        variant="body1"
        display="block"
        gutterBottom
        style={{ marginTop: 10 }}
      >
        {comment.text}
      </Typography>
    </div>
  );
};

const formatedCommentDate = (createdAt) => {
  const date = moment(createdAt);
  const momentNow = moment();

  if (momentNow.diff(date, 'minutes') <= 2) {
    return 'Agora mesmo';
  }
  const diffHours = momentNow.diff(date, 'hours');
  if (diffHours === 0) {
    return `${momentNow.diff(date, 'minutes')} minutos`;
  }
  if (diffHours <= 4) {
    return `${diffHours}h`;
  }

  if (date.year() === momentNow.year()) {
    return `${date.date()} de ${date.format('MMM')} ${date.format('HH:mm')}`;
  }
  return `${date.date()} de ${date.format(
    'MMM'
  )} de ${date.year()} ${date.format('HH:mm')}`;
};
