import API from '../../api';

import { retrieveImageBytes } from '../../integrations/cloudinary';
import CastleThumbnails from '../../components/admin/playlists/CastleThumbnails';

export const getFilesResources = (playlist) => {
  let optionalImages = [
    {
      image: playlist.cover.image,
      version: playlist.cover.version,
      name: 'coverFile',
    },
    {
      image: playlist.thumbnail.image,
      version: playlist.thumbnail.version,
      name: 'thumbnailFile',
    },
  ];

  const castleThumb = CastleThumbnails(playlist.type);

  if (playlist.thumbnails && playlist.thumbnails[castleThumb.stateField]) {
    optionalImages.push({
      metadata: { thumbnails: true },
      image: playlist.thumbnails[castleThumb.stateField].image,
      version: playlist.thumbnails[castleThumb.stateField].version,
      name: castleThumb.stateField,
    });
  }

  if (playlist.hero) {
    optionalImages.push({
      image: playlist.hero.image,
      version: playlist.hero.version,
      name: 'heroFile',
    });
  }

  if (playlist.thumbnails?.dvd) {
    optionalImages.push({
      image: playlist.thumbnails.dvd.image,
      version: playlist.thumbnails.dvd.version,
      name: 'dvdFile',
    });
  }

  return Promise.all(
    optionalImages.map((image) => retrieveImageBytes(image))
  ).then((responses) => {
    let files = {};

    Object.assign(
      files,
      ...responses.map(({ file, name, metadata }) => {
        const appendFile = new File([file], name, {
          type: 'image/jpeg',
        });

        if (metadata && metadata.thumbnails) {
          return {
            thumbnailsFiles: {
              [name]: appendFile,
            },
          };
        }

        return {
          [name]: appendFile,
        };
      })
    );

    return {
      ...playlist,
      ...{ thumbnailsFiles: {} },
      ...files,
    };
  });
};

export const getLiveById = async (id) => {
  return API.get('/admin/playlists/id', {
    params: {
      id,
    },
  })
    .then((response) => {
      const playlist = response.data[0];
      const medias = playlist.medias.map((m) => {
        const { _id, name, sourceInput, live_start, live_end } = m;
        return {
          _id,
          name,
          sourceInput,
          live_start,
          live_end,
        };
      });
      if (playlist.medias) delete playlist.medias;
      return {
        ...playlist,
        media: medias[0],
      };
    })
    .then(getFilesResources);
};

export const getPlaylistById = async (id) => {
  return API.get('/admin/playlists/id', {
    params: {
      id,
    },
  })
    .then((response) => {
      const playlist = response.data[0];
      return playlist;
    })
    .then(getFilesResources);
};

export const createLive = async (data) => {
  return API.post('/playlists/lives', data);
};

export const updateLive = async (id, data) => {
  return API.put(`/playlists/lives/${id}`, data);
};

export const createPlaylist = async (data) => {
  return API.post('/playlists', data);
};

export const updatePlaylist = async (data) => {
  return API.put(`/playlists/${data._id}`, data);
};

export const getPlaylists = async (params) =>
  API.get('/playlists', {
    params,
  });

export const getAllPlaylists = async () => API.get('/playlists/names');

export const getMediaRawSources = async (data) => {
  return API.post('/medias/raw-sources', data);
};

export default {
  getLiveById,
  getPlaylistById,
  getPlaylists,
  createLive,
  updateLive,
  createPlaylist,
  updatePlaylist,
  getMediaRawSources,
  getAllPlaylists,
};
