import DashboardPAge from '../../../pages/admin/dashboard';

export default [
  {
    link: '/admin/dashboard',
    component: DashboardPAge,
    auth: true,
    roles: ['admin'],
  },
];
