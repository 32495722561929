import { snackbarActionTypes } from './snackbar.types';

const INITIAL_STATE = {
  message: '',
  open: false,
  type: 'success',
};

const snackbarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case snackbarActionTypes.CLOSE:
      return {
        ...state,
        open: false,
        ...action.payload,
      };
    case snackbarActionTypes.OPEN:
      return {
        ...state,
        open: true,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default snackbarReducer;
