import { levelActionTypes } from './level.types';

const INITIAL_STATE = {
  levels: [],
  isFetching: false,
};

const playlistReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case levelActionTypes.FETCH_LEVELS:
      return {
        ...state,
        isFetching: true,
      };
    case levelActionTypes.RECEIVE_LEVELS:
      return {
        ...state,
        levels: action.payload,
        isFetching: Object.assign({}, { isFetching: false }),
      };
    case levelActionTypes.CREATE_LEVEL:
      return {
        ...state,
        levels: [].concat(state.levels, [action.payload]),
      };
    case levelActionTypes.UPDATE_LEVEL:
      return {
        ...state,
        levels: state.levels.map((p) =>
          p._id === action.payload._id ? Object.assign(p, action.payload) : p
        ),
      };
    case levelActionTypes.DELETE_LEVEL:
      return {
        ...state,
        levels: state.levels.filter((p) => p._id !== action.payload._id),
      };
    default:
      return state;
  }
};
export default playlistReducer;
