import CommentFeed from '../../../pages/admin/comment-feed';

export default [
  {
    link: '/admin/comment-feed',
    component: CommentFeed,
    auth: true,
    roles: ['admin'],
  },
];
