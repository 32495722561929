import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import ModulePanel from './ModulePanel';
import ModulesPanels from './ModulesPanels';

const ModuleList = ({
  modules,
  isModular,
  isCourse,
  advisoryTags,
  advisoryRatings,
}) => {
  return (
    <Droppable droppableId={'playlist-modules-droppable'}>
      {(provided) => (
        <ModulesPanels
          {...provided.droppableProps}
          innerRef={provided.innerRef}
        >
          {modules.map((module, index) => (
            <ModulePanel
              index={index}
              key={index}
              isCourse={isCourse}
              isModular={isModular}
              advisoryRatings={advisoryRatings}
              advisoryTags={advisoryTags}
            ></ModulePanel>
          ))}
          {provided.placeholder}
        </ModulesPanels>
      )}
    </Droppable>
  );
};
export default ModuleList;
