import teachers from './teachers';
import playlists from './playlists';
import levels from './levels';
import roles from './roles';
export default {
  teachers,
  playlists,
  levels,
  roles,
};
