import BannersPage from '../../../pages/admin/banners';
import BannerForm from '../../../pages/admin/banners/form';

export default [
  {
    link: '/admin/banners',
    component: BannersPage,
    auth: true,
    roles: ['admin'],
  },
  {
    link: '/admin/mainBanner/create',
    component: BannerForm,
    auth: true,
    roles: ['admin'],
  },
  {
    link: '/admin/mainBanner/edit/:id',
    component: BannerForm,
    auth: true,
    roles: ['admin'],
  },
];
