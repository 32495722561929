import api from '../../api';

export default {
  all: function(params) {
    return api.get('/enabledcommentsrules', {
      params,
    });
  },
  findById: function(id) {
    return api.get(`/enabledcommentsrules/${id}`);
  },
  create: function(data) {
    return api.post('/enabledcommentsrules', data);
  },
  edit: function(data) {
    return api.put('/enabledcommentsrules', data);
  },
  search: function(search) {
    return this.all({ search });
  },
};
