import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core/';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { asyncFetchOfferById } from '../../../redux/offer/offer.actions';
import Form from './form';

export default () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  let toUpdate = useSelector((state) => state.offer.toUpdate);
  
  useEffect(() => {
    if (!toUpdate) {
      dispatch(asyncFetchOfferById({ id }));
    }
  }, [dispatch, id, toUpdate]);

  return (
    <>
      {toUpdate && <Form toUpdate={toUpdate} />}
      {!toUpdate && (
        <Typography variant="h4" component="h4">
          Oferta não encontrada.
        </Typography>
      )}
    </>
  );
};
