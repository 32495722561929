import API from '../../api';

export const getCategories = async ({ search, page, limit }) =>
  API.get('/categories', {
    params: {
      search,
      page,
      limit,
    },
  });
export const attachPlaylistToCategory = async ({ categoryId, playlistId }) =>
  API.post(`/categories/${categoryId}/playlists`, { playlistId });

export const dettachPlaylistFromCategory = async ({ categoryId, playlistId }) =>
  API.delete(`/categories/${categoryId}/playlists`, {
    data: { playlistId },
  });

export const asyncUpdateCategory = async ({ categoryId, category }) => {
  return API.put(`/categories/${categoryId}`, category);
};

export const updateCategoryFreeOnly = async ({ categoryId, freeOnly }) => {
  return API.put(`/categories/${categoryId}/free-only`, { freeOnly });
}
