import { Button, Grid, makeStyles, TextField } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import ReactPlayer from 'react-player';
import { PlaylistContext } from '../playlists/PlaylistContext';
const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(2)} 0`,
    paddingBottom: theme.spacing(2),
  },
  input: {
    display: 'none',
  },
  fileName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '200px', // some width
  },
}));
export default () => {
  const classes = useStyles();
  const [state, dispatch] = useContext(PlaylistContext);
  const [loading] = useState(false);
  const modIndex = state.audioForm.moduleIndex;
  const medIndex = state.audioForm.mediaIndex;
  const formType = state.audioForm.formType;
  const module = state.modules[modIndex];
  const media = state.medias[medIndex];

  const sourceInput =
    modIndex >= 0 ? module?.audio?.sourceInput : media?.audio?.sourceInput;
  const [form, setForm] = useState({
    link: sourceInput,
  });

  const handleLinkChange = (event) => {
    event.persist();
    setForm((old) => ({
      ...old,
      link: event.target.value,
    }));
  };

  const handleSubmit = () => {
    dispatch({
      type: formType,
      payload: {
        link: form.link,
        moduleIndex: modIndex,
        mediaIndex: medIndex,
      },
    });
    dispatch({ type: 'CLOSE_AUDIO' });
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}
        alignItems="flex-start"
        justifyContent="flex-end"
        direction="column"
      >
        <Grid item>
          <TextField
            label="Link do áudio"
            name="link"
            value={form.link}
            onChange={handleLinkChange}
          />
        </Grid>
        <Grid item>
          <ReactPlayer
            style={{ backgroundColor: 'gray' }}
            url={form.link}
            controls={true}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={handleSubmit}
          >
            Adicionar
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
