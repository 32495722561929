import React from 'react';
import { GoogleLogin } from 'react-google-login';
import { useDispatch } from 'react-redux';
import { asyncTokenSignIn } from '../../redux/user/user.actions';

function Login() {
  const dispatch = useDispatch();

  const onSuccess = (res) => {
    console.log('[Login Sucess]');
    dispatch(asyncTokenSignIn({ token: res.tokenId }));
  };

  const onFailure = (res) => {
    console.log('[Ĺogin failed] res:', res);
  };

  return (
    <div>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        onSuccess={onSuccess}
        onFailure={onFailure}
        isSignedIn={true}
        scope={'profile email https://www.googleapis.com/auth/groups'}
        buttonText={'Entrar com Google'}
      />
    </div>
  );
}

export default Login;
