import React, { useEffect, useState } from 'react';
import { debounceSearchRender } from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PageTitle from '../../app/main/Title';
import DataTable from '../../table/DataTable';
import { asyncFetchOffersByBrokerProductId, editOffer } from '../../../redux/offer/offer.actions';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

export default function CustomPaginationActionsTable({ brokerProductId }) {
  const dispatch = useDispatch();
  let history = useHistory();
  const offers = useSelector((state) => state.offer.offers);
  const page = useSelector((state) => state.offer.page);
  const rowsPerPage = useSelector((state) => state.offer.limit);
  const totalCount = useSelector((state) => state.offer.totalCount);
  const [filter, setFilter] = useState('');

  useEffect(
    () => dispatch(asyncFetchOffersByBrokerProductId(
      brokerProductId,
      { page: 1, limit: 10, lean: true }
    )),
    [dispatch, brokerProductId]
  );

  function onClickEditButton(rowDataId) {
    dispatch(editOffer(null));
    history.push(`/admin/offers/details/${rowDataId}`);
  }

  const columns = [
    { name: '_id', label: ' ' },
    { name: 'name', label: 'Nome da Oferta' },
    { name: 'broker_offer_id', label: 'Id da oferta no broker' },
    { name: 'default', label: 'Padrão' },
  ];

  const options = {
    page: page,
    selectableRows: 'none',
    print: false,
    download: false,
    serverSide: true,
    count: totalCount,
    responsive: 'vertical',
    tableBodyHeight: '100%',
    tableBodyMaxHeight: '',
    sort: false,
    filter: false,
    viewColumns: false,
    customSearchRender: debounceSearchRender(500),
    onSearchChange: (newFilter) => {
      setFilter(newFilter);
      new Promise((resolve) => {
        resolve(
          dispatch(
            asyncFetchOffersByBrokerProductId(
              brokerProductId,
              {
                filter: newFilter,
                page: 1,
                limit: rowsPerPage,
                lean: true,
              }
            )
          )
        );
      });
    },
    onRowsDelete: (data) => { alert('Não é possível deletar uma oferta.') },
    onChangePage: (newPage) => {
      new Promise((resolve) => {
        resolve(
          dispatch(
            asyncFetchOffersByBrokerProductId(
              brokerProductId,
              {
                filter: filter,
                page: newPage + 1,
                limit: rowsPerPage,
                lean: true,
              }
            )
          )
        );
      });
    },
    onChangeRowsPerPage: (limit) => {
      new Promise((resolve) => {
        resolve(
          dispatch(
            asyncFetchOffersByBrokerProductId(
              brokerProductId,
              {
                filter: filter,
                page: 1,
                limit: limit,
              }
            )
          )
        );
      });
    },
    customRowRender: (data) => {
      const [_id, name, broker_offer_id, defaultCheck] = data;
      return (
        <tr
          key={_id}
          style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
        >
          <td style={{ width: 40 }}>
            <IconButton
              component="span"
              onClick={() => {
                onClickEditButton(_id);
              }}
            >
              <EditIcon />
            </IconButton>
          </td>
          <td align="left">{name}</td>
          <td style={{ width: 300 }} align="left">{broker_offer_id}</td>
          <td style={{ width: 40 }} align="center">{defaultCheck
            ? <input type="checkbox" checked />
            : ''
          }</td>
        </tr>
      );
    },
  };

  return (
    <>
      <PageTitle>Ofertas do produto</PageTitle>
      <DataTable data={offers} columns={columns} options={options} />
    </>
  );
}
