import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ProductSelect from '../products/ProductSelect';
import UserSelect from '../users/UserSelect';

const customReasons = [
  'Afiliado',
  'Aluno de instituição parceira',
  'Membro fundador',
  'Funcionário',
  'Outro',
];

export default ({ subscription, handleSubmit }) => {
  const [formData, setFormData] = useState(subscription);
  const [customReason, setCustomReason] = useState('');
  const isManual = subscription.type === 'manual';
  useEffect(() => {
    if (isManual) {
      setCustomReason(subscription.reason);
    }
  }, [isManual, setCustomReason, subscription.reason]);
  const onSubmit = (event) => {
    event.preventDefault();
    const data = {
      ...formData,
      reason: isManual ? customReason || formData.reason : undefined,
    };
    if (isManual && !data.reason) {
      return alert('Por favor, informe o motivo da criação da assinatura');
    }
    if (!data.subscriptionPlan) {
      return alert('O produto da assinatura é obrigatório!');
    }
    handleSubmit(data)(event);
  };
  const isCustomReason =
    formData.reason &&
    (formData.reason === 'Outro' || !customReasons.includes(formData.reason));

  const productChange = (value) => {
    setFormData((old) => ({
      ...old,
      subscriptionPlan: value,
    }));
  };
  return (
    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              required
              id="date-picker-inline"
              label="Data de expiração"
              value={moment(formData.expires_in)}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(date) =>
                setFormData((prevState) => ({
                  ...prevState,
                  expires_in: date,
                }))
              }
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item>
            <UserSelect
              label="Dono da assinatura"
              disabled={isManual}
              readOnly={isManual}
              defaultValue={formData.owner.name}
              onChange={(owner) =>
                setFormData((prevState) => ({
                  ...prevState,
                  owner,
                }))
              }
            />
          </Grid>
          {!isManual && (
            <>
              <Grid item>
                <TextField
                  id="gateway_code"
                  label="Código externo"
                  value={formData.gateway_code}
                  disabled
                  readOnly
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="startedAt"
                  label="Data de início"
                  value={
                    formData.startedAt &&
                    moment(formData.startedAt).format('DD/MM/YYYY')
                  }
                  disabled={isManual}
                  readOnly={isManual}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item>
            {isManual ? (
              <ProductSelect
                required
                value={formData.subscriptionPlan._id}
                onChange={productChange}
              />
            ) : (
              <TextField
                id="plan"
                label="Produto"
                value={formData.subscriptionPlan.name}
                disabled
                readOnly
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} style={{ margin: '10px 0' }}>
            <Grid container spacing={2} alignItems="flex-end">
              {isManual && (
                <>
                  <Grid item>
                    <InputLabel id="reason-select-label">
                      Motivo do cadastro
                    </InputLabel>
                    <Select
                      labelId="reason-select-label"
                      id="reason-select"
                      name="reason"
                      style={{ width: 300 }}
                      value={isCustomReason ? 'Outro' : formData.reason || ''}
                      onChange={(event) => {
                        if (!isCustomReason) {
                          setCustomReason('');
                        }
                        setFormData((prevState) => ({
                          ...prevState,
                          reason: event.target.value,
                        }));
                      }}
                    >
                      {customReasons.map((reason) => (
                        <MenuItem value={reason}>{reason}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  {isCustomReason && (
                    <Grid item>
                      <FormControl style={{ width: '300px' }}>
                        <TextField
                          labelId="reason-custom-label"
                          id="reason-custom"
                          name="reason-custom"
                          required={isCustomReason}
                          label="Motivo personalizado"
                          value={customReason}
                          fullWidth
                          onChange={(event) => {
                            event.persist();
                            setCustomReason(event.target.value);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Button type="submit" variant="contained" color="primary">
          {subscription._id ? 'atualizar' : 'cadastrar'}
        </Button>
      </form>
    </MuiPickersUtilsProvider>
  );
};
