import {
  Checkbox,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import api from '../../../integrations/api';
const useStyle = makeStyles({
  root: {
    width: '100%',
  },
});
const ProductsRolesSelect = ({ name, label, selectedRoles, inputChange }) => {
  const [allRoles, setAllRoles] = useState([]);
  const classes = useStyle();
  const checkedDefaultRoles = useRef(false);

  useEffect(() => {
    Promise.all([api.roles.plan(), api.roles.products()])
      .then(([planRes, productRes]) => {
        const allRolesFromReq = [...planRes.data, ...productRes.data];
        // MaterialUI's Select component relies on the options lists ('selected' and 'all')
        // to have the same object reference; just looking the same does not suffice.
        const allRolesSameRef = allRolesFromReq.map(
          (role) => selectedRoles.find((sRole) => sRole._id === role._id) || role
        );
        setAllRoles(allRolesSameRef);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      allRoles.length > 0 &&
      !checkedDefaultRoles.current &&
      selectedRoles.length === 0
    ) {
      inputChange({
        target: {
          value: allRoles.filter((role) =>
            ['good', 'better', 'best'].includes(role.role)
          ),
          name: 'roles',
        },
      });
      checkedDefaultRoles.current = true;
    }
  }, [allRoles, inputChange, selectedRoles.length]);

  const handleChange = (event) => {
    inputChange(event);
  };

  return (
    <>
      <InputLabel id="plansroles">{label || 'Produtos com acesso'}</InputLabel>
      <Select
        className={classes.root}
        labelId="plansroles"
        id="plansroles"
        multiple
        name={name || 'roles'}
        renderValue={(selected) => selected.map((s) => s.role).join(', ')}
        value={selectedRoles}
        onChange={handleChange}
      >
        {allRoles.map((role) => (
          <MenuItem key={role._id} value={role}>
            <Checkbox
              checked={
                selectedRoles.findIndex((sRole) => sRole._id === role._id) > -1
              }
            />
            <ListItemText primary={role.role} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
export default ProductsRolesSelect;
