import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import api from '../../../api';
import Form from './Form';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));
export default () => {
  const { id } = useParams();
  const [subscription, setSubscription] = useState(null);
  const classes = useStyles();
  useEffect(() => {
    const fetchSubscription = async () => {
      api
        .get(`/subscriptions/${id}`)
        .then((res) => setSubscription(res.data))
        .catch((__) => alert('Falha ao buscar assinatura'));
    };
    fetchSubscription();
  }, [id]);

  const handleSubmit = (data) => (e) => {
    e.preventDefault();
    api
      .put(`/subscriptions/${id}`, data)
      .then((res) => alert('Assinatura atualizada com sucesso'))
      .catch((err) => alert('Falha ao atualizar assinatura'));
  };
  return (
    <div className={classes.root}>
      {subscription && (
        <>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              to={`/admin/users/details/${subscription.owner._id}`}
            >
              {subscription.owner.name}
            </Link>
            <Typography color="textPrimary">Editar assinatura</Typography>
          </Breadcrumbs>
          <Form subscription={subscription} handleSubmit={handleSubmit} />
        </>
      )}
    </div>
  );
};
