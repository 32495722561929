const remove = (array, position) =>
  array.filter((element, index) => index !== position);

const add = (array, newElement) => [...array, newElement];

const replace = (array, index, data) => {
  return array.map((element, elIndex) =>
    elIndex === index ? { ...element, ...data } : element
  );
};

const removeNested = (array, parentIndex, nestedKey, nestedIndex) =>
  array.map((parentEl, pIndex) =>
    pIndex === parentIndex
      ? {
          ...parentEl,
          [nestedKey]: remove(parentEl[nestedKey], nestedIndex),
        }
      : parentEl
  );

const replaceNested = (array, parentIndex, nestedKey, nestedIndex, data) =>
  array.map((parentEl, pIndex) =>
    pIndex === parentIndex
      ? {
          ...parentEl,
          [nestedKey]: replace(parentEl[nestedKey], nestedIndex, data),
        }
      : parentEl
  );

const addNested = (array, parentIndex, nestedKey, newElement) => {
  return array.map((parentEl, pIndex) =>
    pIndex === parentIndex
      ? {
          ...parentEl,
          [nestedKey]: add(parentEl[nestedKey], newElement),
        }
      : parentEl
  );
};
export const array = {
  remove,
  removeNested,
  addNested,
  add,
  replace,
  replaceNested,
};
