import { FormControlLabel, Switch } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

import { getAllDistributors } from '../../../integrations/api/distributors';
import { getAllRatingsAndAdvisories } from '../../../integrations/api/ratings';
import DndModules from '../modules/DndModules';
import MediasTable from '../videos/MediasTable';

export default ({ medias, modules, isModular, isCourse }) => {
  const [modular, setModular] = useState(isModular.current);
  const [distributorOptions, setDistributorOptions] = useState([]);
  const [advisoryRatingsOptions, setAdvisoryRatingsOptions] = useState([]);

  const handleChange = (event) => {
    const {
      target: { checked },
    } = event;
    if (checked) {
      if (
        medias.length === 0 ||
        window.confirm(
          'Ao salvar a playlist, os vídeos cadastrados fora de módulos serão perdidos. Confirma?'
        )
      ) {
        setModular(checked);
        isModular.current = checked;
      }
    } else if (
      modules.length === 0 ||
      window.confirm(
        'Ao salvar a playlist, os vídeos cadastrados dentro de módulos serão perdidos. Confirma?'
      )
    ) {
      setModular(checked);
      isModular.current = checked;
    }
  };

  useEffect(() => {
    async function fetchDistributors() {
      const fetchedDistributors = await getAllDistributors();
      setDistributorOptions(fetchedDistributors);
    }
    async function fetchAdvisoryRatings() {
      const advisoryRatings = await getAllRatingsAndAdvisories();
      setAdvisoryRatingsOptions(advisoryRatings);
    }
    fetchDistributors();
    fetchAdvisoryRatings();
  }, []);

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={modular}
            onChange={handleChange}
            name="modular"
            color="primary"
          />
        }
        label="Dividir em módulos"
      />

      {modular ? (
        <DndModules
          name="modules"
          isCourse={isCourse}
          modules={modules}
          isModular={modular}
          advisoryTags={advisoryRatingsOptions?.advisories || []}
          advisoryRatings={advisoryRatingsOptions?.ratings || []}
        />
      ) : (
        <MediasTable
          name="medias"
          medias={medias}
          isCourse={isCourse}
          distributorValues={distributorOptions}
          advisoryTags={advisoryRatingsOptions?.advisories || []}
          advisoryRatings={advisoryRatingsOptions?.ratings || []}
        />
      )}
    </>
  );
};
