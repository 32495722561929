import DataTable from '../../../components/table/DataTable';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { asyncFetchTeachers } from '../../../redux/teachers/teacher.actions';
import { Edit, Save } from '@material-ui/icons';
import { Button, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router';

const columns = [
  {
    name: '_id',
    label: ' ',
  },
  {
    name: 'name',
    label: 'Nome',
  },
  {
    name: 'biography',
    label: 'Biografia',
  },
];

export default function MaterialTableDemo() {
  const history = useHistory();

  const dispatch = useDispatch();
  const teachers = useSelector((state) => state.teacher.teachers);

  const onClickEditButton = (id) => {
    history.push(`/admin/teachers/edit/${id}`);
  };

  useEffect(() => dispatch(asyncFetchTeachers()), [dispatch]);

  const options = {
    rowHover: true,
    selectableRows: 'none',
    responsive: 'vertical',
    tableBodyHeight: '100%',
    filter: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [20, 50, 100],
    viewColumns: false,
    customRowRender: (data) => {
      const [_id, name, biography] = data;

      return (
        <tr
          key={_id}
          style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
        >
          <td style={{ width: 80 }}>
            <IconButton
              aria-label="edit"
              component="span"
              onClick={() => {
                onClickEditButton(_id);
              }}
            >
              <Edit />
            </IconButton>
          </td>

          <td align="left" style={{ padding: '20px' }}>
            {name}
          </td>
          <td align="left">{biography}</td>
        </tr>
      );
    },
  };

  return (
    <>
      <div style={{ textAlign: 'right', marginBottom: 20 }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<Save />}
          onClick={() => history.push(`/admin/teachers/create`)}
        >
          Inserir novo
        </Button>
      </div>
      <DataTable
        title="Professores"
        columns={columns}
        data={teachers}
        options={options}
      />
    </>
  );
}
