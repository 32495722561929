import DataTable from '../../table/DataTable';

import React, { useEffect, useState } from 'react';
import enabledCommentsRulesApi from '../../../integrations/api/enabled-comments-rules';
import API from '../../../api';
import { Switch } from '@mui/material';
import store from '../../../redux/store';
import { openSnackBar } from '../../../redux/snackbar/snackbar.actions';

const columns = [
  {
    name: '_id',
    label: '',
    options: {
      display: false,
    },
  },
  {
    name: 'name',
    label: 'Tag de mídia',
  },
  {
    name: 'allowed',
    label: 'Permitir comentário?',
  },
];

export default function MaterialTableDemo() {
  const [availableRules, setAvailableRules] = useState([]);

  const onClickEditButton = (data, index) => {
    if (data._id === undefined) {
      store.dispatch(
        openSnackBar({
          type: 'error',
          message: 'Cant find this rule. Refresh the page.',
        })
      );
    } else {
      enabledCommentsRulesApi.edit(data).then((result) => {
        let newAvailableRules = JSON.parse(JSON.stringify(availableRules));
        newAvailableRules[index] = result.data;
        setAvailableRules(newAvailableRules);
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    API.get('/enabledcommentsrules').then((response) => {
      if (isMounted) setAvailableRules(response.data);
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const options = {
    rowHover: true,
    selectableRows: 'none',
    responsive: 'vertical',
    tableBodyHeight: '100%',
    filter: false,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [20, 50, 100],
    viewColumns: false,
    customRowRender: (data, dataIndex) => {
      const [_id, name, allowed] = data;
      let rule = {
        allowed: allowed,
        _id: _id,
        name: name,
        __v: 0,
      };
      return (
        <tr
          key={name}
          style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
        >
          <td align="left" style={{ padding: '20px' }}>
            {name}
          </td>
          <td style={{ width: 80 }}>
            <Switch
              checked={allowed}
              onChange={(event) => {
                rule.allowed = event.target.checked;
                onClickEditButton(rule, dataIndex);
              }}
            />
          </td>
        </tr>
      );
    },
  };

  return (
    <>
      <DataTable
        title="Conteúdos"
        columns={columns}
        data={availableRules}
        options={options}
      />
    </>
  );
}
