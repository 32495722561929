import { IconButton, ListItemSecondaryAction } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { Delete, FileCopy, OpenInBrowser } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { PlaylistContext } from '../playlists/PlaylistContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    minWidth: theme.breakpoints.width('md'),
  },
}));

export default function MaterialsList() {
  const classes = useStyles();
  const [state, dispatch] = useContext(PlaylistContext);
  const {
    materialsForm: { moduleIndex, mediaIndex },
  } = state;
  let materials = [];
  if (moduleIndex >= 0) {
    if (mediaIndex !== null && mediaIndex >= 0) {
      materials = state.modules[moduleIndex].medias[mediaIndex].materials;
    } else {
      materials = state.modules[moduleIndex].materials;
    }
  } else {
    materials = state.medias[mediaIndex].materials;
  }
  const removeMaterial = (materialIndex) => (event) => {
    if (window.confirm('remover material?')) {
      dispatch({
        type: 'REMOVE_MATERIAL',
        payload: {
          materialIndex,
        },
      });
    }
  };
  materials = materials || [];
  return (
    <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        {materials.length === 0 ? (
          <Alert variant="filled" severity="info">
            Não há nenhum material no momento...
          </Alert>
        ) : (
          materials.map((material, index) => (
            <ListItem key={index} button>
              <ListItemText primary={material.name} />
              <ListItemSecondaryAction>
                <IconButton
                  title="abrir link"
                  target="_blank"
                  href={material.link}
                  component="a"
                >
                  <OpenInBrowser />
                </IconButton>
                <CopyToClipboard
                  text={material.link}
                  onCopy={() =>
                    alert('Link copiado para a área de transferência')
                  }
                >
                  <IconButton title="copiar link">
                    <FileCopy />
                  </IconButton>
                </CopyToClipboard>
                <IconButton
                  title="remover material"
                  onClick={removeMaterial(index)}
                >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))
        )}
      </List>
    </div>
  );
}
