import {
  Button,
  Divider,
  Grid,
  Typography,
  makeStyles,
  Snackbar,
  CircularProgress,
} from '@material-ui/core';

import { Alert } from '@material-ui/lab';
import { KeyboardBackspace, Save } from '@material-ui/icons';

import store from '../../../redux/store';
import { openSnackBar } from '../../../redux/snackbar/snackbar.actions';

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import teachersApi from '../../../integrations/api/teachers';
import { uploadImage } from '../../../integrations/api/files';
import { getSourceUrl } from '../../../integrations/cloudinary';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import FormikDropZoneArea from '../../../components/formik/DropZoneArea';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  textField: {
    marginBottom: theme.spacing(3),
    width: '90%',
  },
  editableField: {
    color: 'red',
  },
  form: {
    marginTop: '20px',
  },
}));

function FormBanner() {
  const history = useHistory();

  const { id } = useParams();

  const [loading, setLoading] = useState(Boolean(id));
  const [teacher, setTeacher] = useState({});

  useEffect(() => {
    if (id) {
      teachersApi.findById(id).then((response) => {
        const t = response.data;
        setTeacher(t);
        setLoading(false);
      });
    }
  }, [id]);

  const [snackbar, setSnackbar] = useState({ open: false });

  const handleSubmit = (values) => {
    let imagesToUpload = [];
    if (values.avatar && !values.avatar.image) {
      imagesToUpload.push({
        image: values.avatar,
        name: 'avatar',
        path: `/teachers/avatar`,
      });
    }

    setLoading(true);

    Promise.all(imagesToUpload.map((arg) => uploadImage(arg))).then(
      ([response]) => {
        if (response) values = { ...values, ...response.data.reference };

        if (teacher._id) {
          teachersApi
            .edit(values)
            .then(() => {
              handleSuccess();
            })
            .catch(() => {
              showError();
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          teachersApi
            .create(values)
            .then(() => {
              handleSuccess();
            })
            .catch(() => {
              showError();
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }
    );
  };

  const handleSuccess = () => {
    setLoading(false);
    store.dispatch(
      openSnackBar({
        type: 'success',
        message: 'Dados atualizados com sucesso!',
      })
    );

    history.push('/admin/teachers');
  };

  const showError = () => {
    openSnackBar({
      type: 'error',
      message: `Falha ao salvar os dados`,
    });
  };

  const classes = useStyles();

  if (loading || !teacher) {
    return <CircularProgress />;
  }

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ open: false })}
      >
        <Alert severity={snackbar.type}>{snackbar.message}</Alert>
      </Snackbar>
      <Typography variant="h4" gutterBottom>
        <Button
          onClick={() => {
            history.push('/admin/teachers');
          }}
        >
          <KeyboardBackspace></KeyboardBackspace>
        </Button>
        Professores
      </Typography>

      <Divider />
      <Formik
        initialValues={teacher}
        className={classes.root}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values }) => (
          <Form className={classes.root}>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <Field
                  required
                  name="name"
                  label="Nome"
                  className={classes.textField}
                  helperText="Nome completo do professor"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12} lg={6}></Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  label="Biografia"
                  helperText="Breve descrição da trajetória profissional do professor. Texto é apresentado tanto em sua página de perfil quanto nos cursos que ministrou."
                  className={classes.textField}
                  multiline
                  name="biography"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  name="avatar"
                  width={250}
                  height={250}
                  maxWidth={250}
                  initialFile={undefined}
                  initialFileUrl={
                    values.avatar?.image ? getSourceUrl(values.avatar) : ''
                  }
                  label="Foto de perfil"
                  helperText="Foto de perfil do professor mostrada na plataforma. Dimensões: 800x800px"
                  component={FormikDropZoneArea}
                />
              </Grid>
            </Grid>

            <div style={{ textAlign: 'right', marginBottom: 20 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                startIcon={<Save />}
              >
                Salvar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default FormBanner;
