import { combineReducers } from 'redux';

import userReducer from './user/user.reducer';
import levelReducer from './levels/level.reducer';
import loadingReducer from './loading/loading.reducer';
import playListReducer from './playlists/playlist.reducer';
import playListTypeReducer from './playlisttypes/playlisttype.reducer';
import categoryReducer from './categories/categories.reducer';
import roleReducer from './roles/role.reducer';
import teacherReducer from './teachers/teacher.reducer';
import snackbarReducer from './snackbar/snackbar.reducer';
import pageReducer from './pages/pages.reducer';
import mediaReducer from './medias/medias.reducer';
import brokerProductReducer from './brokerproduct/brokerproduct.reducer';
import offerReducer from './offer/offer.reducer';
import filesReducer from './files/files.reducer';

export default combineReducers({
  user: userReducer,
  loading: loadingReducer,
  level: levelReducer,
  playlist: playListReducer,
  playlisttype: playListTypeReducer,
  role: roleReducer,
  teacher: teacherReducer,
  snackbar: snackbarReducer,
  category: categoryReducer,
  page: pageReducer,
  media: mediaReducer,
  offer: offerReducer,
  brokerProduct: brokerProductReducer,
  files: filesReducer,
});
