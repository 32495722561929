/**
This file exports a map of keywords to React components. The keywords
to this map are to be kept in sync with the definitions on file
`src/components/admin/videos/MediasTable.jsx`.

You can think of this map as part of specialized framework created by
Caverna's developers for rendering forms and tables.
**/
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Chip,
} from '@material-ui/core';

import Tooltip from '@mui/material/Tooltip';

import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import { Autocomplete } from '@material-ui/lab';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const FormTextFormField = ({ fieldProps, data, handleChange, index }) => (
  <FormControl style={{ width: '100%' }}>
    <TextField
      id={`input-${index}`}
      value={data[fieldProps.name] || ''}
      type={fieldProps.modifier}
      multiline={fieldProps.modifier === 'multiline' ? true : false}
      maxRows={fieldProps.modifier === 'multiline' ? 10 : undefined}
      label={fieldProps.label}
      onChange={handleChange}
      InputLabelProps={{
        shrink: true,
      }}
    />
    <FormHelperText>{fieldProps.helperText}</FormHelperText>
  </FormControl>
);

const ChipInputFormField = ({
  fieldProps,
  data,
  handleChange,
  chipOptions,
  index,
  currentUser,
}) => {
  const disabled =
    currentUser.roles.map((r) => r.role).indexOf('admin-tag-editor') < 0;
  return (
    <FormControl style={{ width: '100%' }}>
      <Tooltip
        title={disabled && 'Somente editores de tag podem atualizar esse campo'}
      >
        <Autocomplete
          disabled={disabled}
          onChange={handleChange}
          options={chipOptions}
          value={data[fieldProps.name] || []}
          multiple
          id={`chipinput-${index}`}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                onDelete={handleChange}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tags tipo de mídia"
              helperText="Etiquetas para definir tipo de mídia"
            />
          )}
        />
      </Tooltip>
    </FormControl>
  );
};

const AdvisoryChipInputFormField = ({
  fieldProps,
  data,
  handleChange,
  index,
  currentUser,
}) => {
  const disabled = currentUser.roles.map((r) => r.role).indexOf('admin-tag-editor') < 0;

  return (
    <FormControl style={{ width: '100%' }}>
      <Tooltip
        title={disabled && 'Somente editores de tag podem atualizar esse campo'}
      >
        <Autocomplete
          disabled={disabled}
          onChange={handleChange}
          options={fieldProps?.values || []}
          value={data[fieldProps.name] || []}
          multiple
          id={`chipinput-${index}`}
          getOptionDisabled={(option) => {
            const selectedOptions = data[fieldProps.name] || [];
            const reachedMax = selectedOptions.length === 3;
            const optionSelected = selectedOptions.indexOf(option) > -1;

            return !optionSelected && reachedMax;
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                onDelete={handleChange}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Motivo Classificação Indicativa"
              helperText="Etiquetas para definir o motivo da classificação indicativa. Max: 3"
            />
          )}
        />
      </Tooltip>
    </FormControl>
  );
};

const ContentChipingFormField = ({
  fieldProps,
  data,
  handleChange,
  contentDescriptionChipOptions,
  index,
  currentUser,
}) => {
  const disabled =
    currentUser.roles.map((r) => r.role).indexOf('admin-tag-editor') < 0;
  return (
    <FormControl style={{ width: '100%' }}>
      <Tooltip
        title={disabled && 'Somente editores de tag podem atualizar esse campo'}
      >
        <Autocomplete
          disabled={disabled}
          onChange={handleChange}
          options={contentDescriptionChipOptions}
          value={data[fieldProps.name] || []}
          multiple
          id={`chipinput-${index}`}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                onDelete={handleChange}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tag descrição de conteúdo"
              helperText="Etiquetas para definir descrição de conteúdo da mídia"
            />
          )}
        />
      </Tooltip>
    </FormControl>
  );
};

const DropDownFormField = ({
  fieldProps,
  data,
  handleChange,
  index,
  helperText,
}) => (
  <FormControl style={{ width: '100%' }}>
    <InputLabel htmlFor={`select-${index}`}>{fieldProps.label}</InputLabel>
    <Select
      labelId={`select-${index}`}
      id={`select-${index}`}
      value={data[fieldProps.name] || ''}
      autoWidth
      onChange={handleChange}
    >
      {fieldProps.values.map((v) => (
        <MenuItem value={v.value}>{v.label}</MenuItem>
      ))}
    </Select>
    <FormHelperText>{fieldProps.helperText}</FormHelperText>
  </FormControl>
);

const AdvisoryDropdownFormField = ({
  fieldProps,
  data,
  handleChange,
  index,
  helperText,
}) => (
  <FormControl style={{ width: '100%' }}>
    <InputLabel htmlFor={`select-${index}`}>{fieldProps.label}</InputLabel>
    <Select
      labelId={`select-${index}`}
      id={`select-${index}`}
      value={data[fieldProps.name] || ''}
      autoWidth
      onChange={handleChange}
    >
      {fieldProps?.values?.map(({ value, url }) => (
        <MenuItem value={value} key={value}>
          {url && <img src={url} alt={value} />}
        </MenuItem>
      ))}
    </Select>
    <FormHelperText>{fieldProps.helperText}</FormHelperText>
  </FormControl>
);

const CheckboxFormField = ({ fieldProps, data, handleChange }) => (
  <FormControl style={{ width: '100%' }}>
    <FormControlLabel
      control={
        <Checkbox
          checked={
            data[fieldProps.name] === undefined
              ? fieldProps.checked
              : data[fieldProps.name]
          }
          onChange={handleChange}
        />
      }
      label={fieldProps.label}
      name={fieldProps.name}
    />
    <FormHelperText>{fieldProps.helperText}</FormHelperText>
  </FormControl>
);

const DataFormField = ({ fieldProps, data, handleChange }) => (
  <FormControl style={{ width: '100%' }}>
    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        required
        label={fieldProps.label}
        value={data[fieldProps.name] || null}
        autoOk={true}
        onChange={(value) => {
          handleChange({ target: { name: data[fieldProps.name], value } });
        }}
        format="dd/MM/yyyy HH:mm"
        variant="inline"
        ampm={false}
      />
    </MuiPickersUtilsProvider>
    <FormHelperText>{fieldProps.helperText}</FormHelperText>
  </FormControl>
);

export const componentsMap = {
  textfield: FormTextFormField,
  chipinput: ChipInputFormField,
  contentChipInput: ContentChipingFormField,
  advisoryChipInput: AdvisoryChipInputFormField,
  advisoryDropdown: AdvisoryDropdownFormField,
  dropdown: DropDownFormField,
  checkbox: CheckboxFormField,
  datefield: DataFormField,
};
