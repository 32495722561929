import { filesActionTypes } from './files.types';
import PaymentApi from '../../payment-api';

export const fetchUploadedUsersFiles = () => {
  return {
    type: filesActionTypes.FETCH_UPLOADED_USERS_FILES,
  };
};

export const receiveUploadedUsersFiles = (payload) => {
  return {
    type: filesActionTypes.RECEIVE_UPLOADED_USERS_FILES,
    payload,
  };
};

export const asyncFetchUploadedUsersFiles = (params) => (dispatch) => {
  dispatch(fetchUploadedUsersFiles);
  PaymentApi.get('/api/files/create_members/search', {
    params,
  })
    .then((response) => {
      dispatch(
        receiveUploadedUsersFiles({
          data: response.data.docs,
          totalCount: response.data.totalDocs,
          page: response.data.page - 1,
          limit: response.data.limit,
        })
      );
    })
    .catch((err) => console.log(err));
};
