import MUIDataTable from 'mui-datatables';
import React from 'react';

const defaultOptions = {
  jumpToPage: false,
  textLabels: {
    body: {
      noMatch: 'Nenhum registro encontrado',
      toolTip: 'Ordenar',
      columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
    },
    pagination: {
      next: 'Próxima',
      previous: 'Anterior',
      rowsPerPage: 'Linhas por página:',
      displayRows: 'de',
    },
    toolbar: {
      search: 'Pesquisar',
      downloadCsv: 'Baixar CSV',
      print: 'Imprimir',
      viewColumns: 'Ver colunas',
      filterTable: 'Filtrar tabela',
    },
    filter: {
      all: 'Todes',
      title: 'FILTROS',
      reset: 'RESETAR',
    },
    viewColumns: {
      title: 'Mostrar colunas',
      titleAria: 'Exibir/Ocultar colunas',
    },
    selectedRows: {
      text: 'linha(s) selecionada(s)',
      delete: 'Apagar',
      deleteAria: 'Apagar linhas selecionadas',
    },
  },
};
export default ({ data, columns, options }) => (
  <MUIDataTable
    data={data}
    columns={columns}
    options={{ ...options, ...defaultOptions }}
  />
);
