import { teacherActionTypes } from './teacher.types';

const INITIAL_STATE = {
  teachers: [],
  isFetching: false,
};

const teacherReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case teacherActionTypes.FETCH_TEACHERS:
      return {
        ...state,
        isFetching: true,
      };
    case teacherActionTypes.RECEIVE_TEACHERS:
      return {
        ...state,
        teachers: action.payload,
        isFetching: Object.assign({}, { isFetching: false }),
      };
    case teacherActionTypes.CREATE_TEACHER:
      return {
        ...state,
        teachers: [].concat(state.teachers, [action.payload]),
      };
    case teacherActionTypes.UPDATE_TEACHER:
      return {
        ...state,
        teachers: state.teachers.map((p) =>
          p._id === action.payload._id ? Object.assign(p, action.payload) : p
        ),
      };
    case teacherActionTypes.DELETE_TEACHER:
      return {
        ...state,
        teachers: state.teachers.filter((p) => p._id !== action.payload._id),
      };
    default:
      return state;
  }
};
export default teacherReducer;
