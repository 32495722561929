import React from 'react';
import PlayListList from '../../../components/admin/playlists/list';

function PlayListPage(props) {
  return (
    <React.Fragment>
      <PlayListList />
    </React.Fragment>
  );
}

export default PlayListPage;
