import React, { useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { Image, Transformation } from 'cloudinary-react';
import { useLocation } from 'react-router-dom';

import getNavDataFromPathname from '../navigation/getNavDataFromPathname';
import { useNavContext } from '../navigation/NavContext';
import MenuItem from './menuItem';
import menuItems from './menuItems';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    height: '100%',
  },
  avatar: {
    margin: '20px auto',
    borderRadius: '50%',
    overflow: 'hidden',
    width: 100,
    height: 100,
  },
  userName: {
    margin: '20px',
    textAlign: 'center',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    paddingBottom: '10px',
  },
  toolbar: theme.mixins.toolbar,
}));

function intersect(a, b) {
  return a.filter(function(e) {
    return b.indexOf(e) > -1;
  });
}

export default ({ currentUser }) => {
  const classes = useStyles();
  const [navData, setNavData] = useNavContext();
  const location = useLocation();

  const menuData = menuItems().filter((menuItem) => {
    if (!menuItem.roles || menuItem.roles.length === 0) {
      return true;
    }
    return (
      intersect(
        currentUser.roles.map((r) => r.role),
        menuItem.roles
      ).length > 0
    );
  });

  useEffect(() => {
    if (!navData) {
      const data = getNavDataFromPathname(menuData, location.pathname);
      setNavData(data);
    }
  }, [location.pathname, menuData, setNavData, navData]);

  return (
    <Drawer
      className={classes.drawer}
      anchor="top"
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbar} />
      <List>
        <ListItemAvatar key="avatar" className={classes.avatar}>
          <Image cloudName="hvzbb2hdx" publicId={currentUser.avatar.image}>
            <Transformation
              width="100"
              height="100"
              gravity="face"
              radius="max"
              crop="thumb"
            />
          </Image>
        </ListItemAvatar>
	
        <ListItemText primary={currentUser.name} className={classes.userName} />

	{menuData.map((item, index) => (
          <MenuItem
            key={index}
            classes={classes}
            text={item.text}
            link={item.link}
            icon={item.icon}
            subItems={item.subItems}
          />
        ))}
      </List>
    </Drawer>
  );
};
