import api from '../../api';

export const getPages = () => api.get('/admin/pages');
export const getPageById = (id) => api.get(`/admin/pages/${id}`);
export const createPage = (data) => api.post('/admin/pages', data);
export const updatePage = (id, data) => api.put(`/admin/pages/${id}`, data);
export const deletePage = (id) => api.delete(`/admin/pages/${id}`);
export const createCategory = async (id, category) =>
  api.post(`/admin/pages/${id}/categories`, category);

export const removeCategory = async (id, categoryId) => {
  return api.delete(`/admin/pages/${id}/categories`, { data: { categoryId } });
};
