import { userActionTypes } from './user.types';
import API from '../../api';
import { setLoading } from '../loading/loading.actions';

export const setCurrentUser = (user) => ({
  type: userActionTypes.SET_CURRENT_USER,
  payload: user,
});

export const fetchUsers = () => {
  return {
    type: userActionTypes.FETCH_USERS,
  };
};

export const signInUser = () => {
  return {
    type: userActionTypes.SIGN_IN,
  };
};

export const receiveUsers = (payload) => {
  return {
    type: userActionTypes.RECEIVE_USERS,
    payload,
  };
};

export const editUser = (payload) => {
  return {
    type: userActionTypes.EDIT_USER,
    payload,
  };
};

export const asyncFetchUsers = (params) => (dispatch) => {
  dispatch(fetchUsers);
  API.get('/users', {
    params,
  })
    .then((response) => {
      dispatch(
        receiveUsers({
          data: response.data.docs,
          totalCount: response.data.totalDocs,
          page: response.data.page - 1,
          limit: response.data.limit,
        })
      );
    })
    .catch((err) => console.log(err));
};

export const asyncFetchUserById = (params) => (dispatch) => {
  dispatch(fetchUsers);
  API.get(`/users/${params.id}`)
    .then((response) => dispatch(editUser(response.data)))
    .catch((err) => console.log(err));
};

export const asyncUpdateUser = (data) => (dispatch) => {
  API.put('/admin/users', data)
    .then((response) => dispatch(editUser(response.data)))
    .catch((error) => {
      console.log(error.response);
      alert('Não foi possível atualizar o usuário.');
    })
    .finally(() => dispatch(setLoading(false)));
};

export const asyncSignIn = (data) => (dispatch) => {
  dispatch(fetchUsers);
  return API.post('/auth/signin', data)
    .then((response) => {
      localStorage.setItem('token', JSON.stringify(response.data));
      dispatch(asyncFetchCurrentUser());
    })
    .catch((err) => console.log(err))
    .finally(() => dispatch(setLoading(false)));
};

export const asyncTokenSignIn = (data) => (dispatch) => {
  dispatch(fetchUsers);
  return API.post('/admin/auth/token-signin', data)
    .then((response) => {
      localStorage.setItem('token', JSON.stringify(response.data));
      dispatch(asyncFetchCurrentUser());
    })
    .catch((err) => console.log(err))
    .finally(() => dispatch(setLoading(false)));
};

export const asyncFetchCurrentUser = (data) => (dispatch) => {
  API.get('/auth')
    .then((response) => {
      dispatch(setCurrentUser(response.data));
    })
    .catch((err) => console.log(err))
    .finally(() => dispatch(setLoading(false)));
};
