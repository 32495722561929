import { categoryActionTypes } from './categories.types';

const INITIAL_STATE = {
  categories: [],
  isFetching: false,
};

const categoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case categoryActionTypes.FETCH_CATEGORIES:
      return {
        ...state,
        isFetching: true,
      };
    case categoryActionTypes.RECEIVE_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        isFetching: Object.assign({}, { isFetching: false }),
      };
    case categoryActionTypes.CREATE_CATEGORY:
      return {
        ...state,
        categories: [].concat(state.categories, [action.payload]),
      };
    case categoryActionTypes.UPDATE_CATEGORY:
      return {
        ...state,
        categories: state.categories.map((p) =>
          p._id === action.payload._id ? Object.assign(p, action.payload) : p
        ),
      };
    case categoryActionTypes.DELETE_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter(
          (p) => p._id !== action.payload._id
        ),
      };
    default:
      return state;
  }
};
export default categoryReducer;
