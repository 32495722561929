import api from '../../api';

export default {
  plan: function(params) {
    return api.get('/roles/plans', {
      params,
    });
  },
  products: function(params) {
    return api.get('/roles/products', {
      params,
    });
  },
  all: function(params) {
    return api.get('/roles', { params });
  },
  create: function(data) {
    return api.post('/roles', data);
  },
  search: function(search) {
    return this.all({ search });
  },
};
