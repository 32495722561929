import {
  SerieCreateHandler,
  SerieUpdateHandler,
} from '../../admin/series/handler';

import {
  PlaylistCreateHandler as GenericPlaylistCreateHandler,
  PlaylistUpdateHandler as GenericPlaylistUpdateHandler,
} from '../../../components/admin/playlists/FormHandler';
import {
  CourseUpdateHandler,
  CourseCreateHandler,
} from '../../../components/admin/courses/handler';
import {
  InterviewCreateHandler,
  InterviewUpdateHandler,
} from '../../../components/admin/interviews/handler';
import {
  LiveCreateHandler,
  LiveUpdateHandler,
} from '../../../components/admin/lives/handler';
import { MovieCreateHandler, MovieUpdateHandler } from '../movies/handler';

const customHandlersMap = {
  serie: {
    create: SerieCreateHandler,
    update: SerieUpdateHandler,
  },
  curso: {
    create: CourseCreateHandler('curso'),
    update: CourseUpdateHandler,
  },
  'sociedade-do-livro': {
    create: CourseCreateHandler('sociedade-do-livro'),
    update: CourseUpdateHandler,
  },
  entrevista: {
    create: InterviewCreateHandler,
    update: InterviewUpdateHandler,
  },
  'escola-da-familia': {
    create: CourseCreateHandler('escola-da-familia'),
    update: CourseUpdateHandler,
  },
  'clube-da-musica': {
    create: CourseCreateHandler('escola-da-musica'),
    update: CourseUpdateHandler,
  },
  travessia: {
    create: CourseCreateHandler('travessia'),
    update: CourseUpdateHandler,
  },
  produto: {
    create: CourseCreateHandler('produto'),
    update: CourseUpdateHandler,
  },
  live: {
    create: LiveCreateHandler,
    update: LiveUpdateHandler,
  },
  'bp-select': {
    create: MovieCreateHandler('bp-select'),
    update: MovieUpdateHandler,
  },
  'bp-kids': {
    create: MovieCreateHandler('bp-kids'),
    update: MovieUpdateHandler,
  },
};

const getTypeHandler = (typeName) => {
  return (
    customHandlersMap[typeName] || {
      create: GenericPlaylistCreateHandler(typeName),
      update: GenericPlaylistUpdateHandler,
    }
  );
};

export default getTypeHandler;
