const defaultThumbnail = {
  preview: { width: 480, height: 270 },
  proportion: { width: 1920, height: 1080 },
  stateField: 'sixteenNine',
};

const thumbs = {
  serie: {
    preview: { width: 200, height: 287.5 },
    label: 'Miniatura DVD',
    proportion: { width: 800, height: 1150 },
    stateField: 'dvd',
  },
  entrevista: {
    preview: { width: 255, height: 255 },
    proportion: { width: 1020, height: 1020 },
    stateField: 'fiftyOneSixty',
  },
  podcast: {
    preview: { width: 255, height: 255 },
    proportion: { width: 1020, height: 1020 },
    stateField: 'square',
  },
  'bp-select': {
    preview: { width: 200, height: 287.5 },
    label: 'Miniatura DVD',
    proportion: { width: 800, height: 1150 },
    stateField: 'dvd',
  },
  'bp-kids': {
    preview: { width: 200, height: 287.5 },
    label: 'Miniatura DVD',
    proportion: { width: 800, height: 1150 },
    stateField: 'dvd',
  },
};

export default function castleThumbnailFiles(type) {
  return thumbs[type] || defaultThumbnail;
}
