import { Grid, TextField } from '@material-ui/core';
import React from 'react';

export default ({ field, form: { setFieldValue } }) => (
  <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
      <TextField
        label={'horas'}
        type="number"
        value={field.value.hours}
        onChange={(event) => {
          const { value } = event.target;
          setFieldValue(field.name, { ...field.value, hours: value });
        }}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField
        label={'minutos'}
        type="number"
        value={field.value.minutes}
        onChange={(event) => {
          const { value } = event.target;
          setFieldValue(field.name, { ...field.value, minutes: value });
        }}
      />
    </Grid>
  </Grid>
);
