import { FormHelperText, InputLabel, makeStyles } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    width: '100%',
    maxWidth: ({ maxWidth }) => maxWidth,
    height: ({ height }) => height,
    padding: 20,
    color: 'white',
    zIndex: 0,
    backgroundImage: ({ backgroundImage }) => `url('${backgroundImage}')`,
    backgroundColor: '#80808040',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      '& > $textContainer': {
        opacity: 1,
        transition: 'opacity .3s',
      },
    },
  },
  dragText: {
    fontSize: '1.3rem',
    fontWeight: '700',
    color: '#444',
  },
  label: {
    width: '100%',
    textAlign: 'center',
  },
  textContainer: {
    padding: theme.spacing(2),
    backgroundColor: 'rgba(208,208,208,.9)',
    opacity: ({ isDragActive, isEmpty }) => (isDragActive || isEmpty ? 1 : 0),
    border: `2px dashed #333`,
    transition: 'opacity .3s',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    textAlign: 'center',
  },
}));

function DropZone({
  initialFile,
  inputChange,
  name,
  width = 300,
  height = 200,
  label,
  maxWidth,
  helperText,
  initialFileUrl,
}) {
  const [file, setFile] = useState();
  const [fileUrlPreview, setFileUrlPreview] = useState();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ['image/jpeg', 'image/jpg', 'image/png'],
    maxSize: 1e7,
    maxFiles: 1,
    onDropAccepted: (acceptedFiles) => {
      const firstFile = acceptedFiles[0];
      const event = {
        target: {
          name,
          value: firstFile,
        },
      };
      setFile(firstFile);
      setFileUrlPreview(URL.createObjectURL(firstFile));
      inputChange(event);
    },
    onDropRejected: () => {
      alert(
        'Formato de imagem não suportado, ou tamanho superior ao máximo permitido (3MB)'
      );
    },
  });
  useEffect(() => {
    setFile(initialFile);
  }, [initialFile]);

  useEffect(() => {
    if (initialFile) {
      setFileUrlPreview(URL.createObjectURL(initialFile));
    }
  }, [initialFile]);

  const classes = useStyles({
    width,
    height,
    backgroundImage: fileUrlPreview ? fileUrlPreview : initialFileUrl,
    isEmpty: !file && !initialFileUrl,
    isDragActive,
    maxWidth,
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(fileUrlPreview);
    },
    [fileUrlPreview]
  );

  return (
    <>
      <div
        style={{
          marginBottom: '10px',
        }}
      >
        <InputLabel className={classes.label}>{label}</InputLabel>
        {helperText && (
          <FormHelperText className={classes.label}>
            {helperText}
          </FormHelperText>
        )}
      </div>

      <div {...getRootProps()} className={classes.thumbsContainer}>
        <input {...getInputProps()} />
        <div className={classes.textContainer}>
          <p className={classes.dragText}>
            Solte um arquivo ou clique para enviar
          </p>
          <CloudUploadIcon fontSize={'large'} />
        </div>
      </div>
    </>
  );
}

export default DropZone;
