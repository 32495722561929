import React from 'react';
import AllowedTags from '../../../components/admin/enabled-comments-rules/AllowedTags';

function EnabledCommentsRules() {
  return (
    <React.Fragment>
      <AllowedTags />
    </React.Fragment>
  );
}

export default EnabledCommentsRules;
