import { playListActionTypes } from './playlist.types';
import API from '../../api';

export const createPlayList = (payload) => {
  return {
    type: playListActionTypes.CREATE_PLAYLIST,
    payload,
  };
};
export const updatePlayList = (payload) => {
  return {
    type: playListActionTypes.UPDATE_PLAYLIST,
    payload,
  };
};
export const editPlayList = (payload) => {
  return {
    type: playListActionTypes.EDIT_PLAYLIST,
    payload,
  };
};
export const deletePlayList = (payload) => {
  return {
    type: playListActionTypes.DELETE_PLAYLIST,
    payload,
  };
};
export const fetchPlayLists = () => {
  return {
    type: playListActionTypes.FETCH_PLAYLISTS,
  };
};

export const receivePlayLists = (payload) => {
  return {
    type: playListActionTypes.RECEIVE_PLAYLISTS,
    payload,
  };
};
export const addPlaylistModule = (payload) => {
  return {
    type: playListActionTypes.ADD_MODULE,
    payload,
  };
};

export const updateModule = (payload) => {
  return {
    type: playListActionTypes.UPDATE_MODULE,
    payload,
  };
};
export const deleteModule = (payload) => {
  return {
    type: playListActionTypes.DELETE_MODULE,
    payload,
  };
};

export const asyncFetchPlayLists = (params) => {
  return API.get('/playlists', {
    params,
  });
};

export const asyncFetchById = (data) => (dispatch) => {
  dispatch(fetchPlayLists);
  API.get('/admin/playlists/id', {
    params: data,
  })
    .then(
      (response) => response,
      (error) => console.log('erro ao trazer playlist', error)
    )
    .then((json) => dispatch(editPlayList(json.data[0])));
};
export const asyncCreatePlayList = (data) => (dispatch) => {
  API.post('/playlists', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(
    (res) => {
      console.log(res);
      return dispatch(createPlayList(res.data));
    },
    (error) => console.log('erro ao cadastrar playlist', error)
  );
};

export const asyncDeletePlayList = (data) => (dispatch) => {
  API.delete('/playlists', {
    params: data,
  })
    .then(
      (response) => response.data,
      (error) => console.log('erro ao deletar playlist', error)
    )
    .then((res) => dispatch(deletePlayList(res)));
};
export const asyncUpdatePlayList = (data) => (dispatch) => {
  API.put('/playlists', data)
    .then(
      (response) => response.data,
      (error) => console.log('erro ao atualizar playlist', error)
    )
    .then((res) => dispatch(updatePlayList(res)));
};
export const asyncPlayListAddModule = (data) => (dispatch) => {
  API.put('/playlists/modules', data)
    .then(
      (response) => response.data,
      (error) => console.log('erro ao adicionar módulo a playlist', error)
    )
    .then((res) => dispatch(addPlaylistModule(res)));
};

export const asyncUpdateModule = (data) => (dispatch) => {
  API.put('/modules', data)
    .then(
      (response) => response.data,
      (error) => console.log('erro ao atualizar módulo', error)
    )
    .then((res) => dispatch(updateModule(res)));
};

export const asyncDeleteModule = (data) => (dispatch) => {
  API.delete('/modules', {
    params: data,
  })
    .then(
      (response) => response.data,
      (error) => console.log('erro ao deletar módulo', error)
    )
    .then((res) => dispatch(deleteModule(res)));
};
