import { IconButton, TextField, FormHelperText } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';
import { Edit, PictureAsPdf, Wallpaper, Audiotrack } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { getAllDistributors } from '../../../integrations/api/distributors';
import { PlaylistContext } from '../playlists/PlaylistContext';
import MediasTable from '../videos/MediasTable';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    display: 'flex',
    justifyContent: `space-between`,
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0',
    },
    fontSize: '1.5rem',
  },

  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
}))(MuiAccordionDetails);

export default function ModulePanel({
  index,
  isCourse,
  editing = false,
  isModular = false,
  advisoryTags,
  advisoryRatings,
}) {
  const [edit, setEdit] = useState(editing);
  const [isDragDisabled, setIsDragDisabled] = useState(false);
  const [state, dispatch] = useContext(PlaylistContext);
  const [expanded, setExpanded] = useState(false);
  const [distributorOptions, setDistributorOptions] = useState([]);
  const editInputRef = useRef();
  const firstInputRender = useRef(true);
  const module = state.modules[index];

  const updateModule = (index, data) => {
    dispatch({
      type: 'REPLACE_MODULE',
      payload: {
        data,
        index,
      },
    });
  };
  const removeModule = (index) => {
    dispatch({
      type: 'DEL_MODULE',
      payload: index,
    });
  };

  const updateDragDisabled = (isDisabled) => {
    setIsDragDisabled(isDisabled);
  };

  const addMaterial = (event) => {
    event.stopPropagation();
    dispatch({
      type: 'OPEN_MATERIALS',
      payload: {
        moduleIndex: index,
        mediaIndex: null,
      },
    });
  };

  const addAudio = (event) => {
    event.stopPropagation();
    dispatch({
      type: 'OPEN_AUDIO',
      payload: {
        moduleIndex: index,
        mediaIndex: null,
        formType: 'ADD_MODULE_AUDIO',
      },
    });
  };

  const addModuleThumbnail = (event) => {
    event.stopPropagation();
    dispatch({
      type: 'OPEN_MODULE_THUMBNAIL',
      payload: {
        moduleIndex: index,
        mediaIndex: null,
      },
    });
  };

  useLayoutEffect(() => {
    if (firstInputRender.current && !module.name.trim()) {
      setEdit(true);
      firstInputRender.current = false;
    }
  }, [module.name]);

  useEffect(() => {
    if (edit && editInputRef.current) {
      editInputRef.current.focus();
    }
  }, [edit]);

  useEffect(() => {
    async function fetchDistributors() {
      const fetchedDistributors = await getAllDistributors();
      setDistributorOptions(fetchedDistributors);
    }
    fetchDistributors();
  }, []);

  return (
    <Draggable
      draggableId={`module-${index}`}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          key={index}
        >
          <Accordion
            index={index}
            expanded={expanded}
            onChange={() => setExpanded((expanded) => !expanded)}
          >
            <AccordionSummary
              aria-controls={`module-${index}-content`}
              id={`module-${index}-header`}
            >
              <>
                {edit ? (
                  <TextField
                    value={module.name}
                    innerRef={editInputRef}
                    style={{ maxWidth: '50%' }}
                    autoFocus={edit}
                    onKeyPress={(e) => {
                      const enter = e.key === 'Enter';
                      if (enter) {
                        e.preventDefault();
                        setEdit(false);
                      }
                    }}
                    onChange={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      updateModule(index, { name: event.target.value });
                    }}
                  />
                ) : (
                  <span style={{ fontSize: '1.1rem' }}>{module.name}</span>
                )}
                <div>
                  {isModular && (
                    <IconButton
                      style={{ color: module.thumbnail ? '#1be11b' : 'red' }}
                      title="Cadastrar miniatura"
                      onClick={(event) => {
                        event.stopPropagation();
                        addModuleThumbnail(event);
                      }}
                      aria-label="thumbnail"
                    >
                      <Wallpaper />
                    </IconButton>
                  )}
                  <IconButton onClick={addMaterial}>
                    <PictureAsPdf />
                  </IconButton>
                  <IconButton onClick={addAudio}>
                    <Audiotrack />
                  </IconButton>
                  <IconButton
                    title="Editar"
                    onClick={(event) => {
                      event.stopPropagation();
                      if (edit && !module.name.trim()) {
                        return alert(
                          'O nome da aula não pode ficar em branco!'
                        );
                      }
                      setEdit((edit) => !edit);
                    }}
                    aria-label="edit"
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      if (window.confirm('Tem certeza?')) removeModule(index);
                    }}
                    title={'Deletar'}
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                label={'Descrição do módulo (opcional)'}
                type="text"
                value={module.description}
                onChange={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  updateModule(index, { description: event.target.value });
                }}
              />
              <FormHelperText>
                Texto de descrição do módulo. Máximo de caracteres: xx
              </FormHelperText>
              <br />
              <MediasTable
                setModuleDragNotAllowed={updateDragDisabled}
                moduleIndex={index}
                medias={module.medias}
                isCourse={isCourse}
                advisoryTags={advisoryTags}
                advisoryRatings={advisoryRatings}
                distributorValues={distributorOptions}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </Draggable>
  );
}
