import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  makeStyles,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  attachPlaylist: {
    marginBottom: theme.spacing(3),
  },
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function({
  playlists,
  dettachPlaylist,
  updatePlaylists,
  isDragDisabled,
}) {
  const classes = useStyles();
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const records = reorder(
      playlists,
      result.source.index,
      result.destination.index
    );
    updatePlaylists(records);
  };
  return (
    <div>
      <ListItem style={{ textAlign: 'right' }}></ListItem>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={'droppable-playlists'}>
          {(provided, snapshot) => (
            <List ref={provided.innerRef}>
              {playlists.map((pl, rowIndex) => (
                <Draggable
                  key={`draggable-${rowIndex}`}
                  draggableId={`draggable-${rowIndex}`}
                  isDragDisabled={isDragDisabled}
                  index={rowIndex}
                  {...provided.droppableProps}
                >
                  {(provided, snapshot) => (
                    <ListItem
                      key={pl._id}
                      divider={true}
                      ref={provided.innerRef}
                      className={`${
                        snapshot.isDragging
                          ? classes.draggableActive
                          : undefined
                      } ${classes.listItem}`}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Link
                        target="_blank"
                        to={`/admin/playlists/${pl.type}/update/${pl._id}`}
                      >
                        {pl.name}
                      </Link>
                      <ListItemSecondaryAction className="itemAction">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            if (window.confirm('Remover playlist?')) {
                              dettachPlaylist({
                                playlistId: pl._id,
                              });
                            }
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
