export const playListActionTypes = {
  CREATE_PLAYLIST: 'CREATE_PLAYLIST',
  FETCH_PLAYLISTS: 'FETCH_PLAYLISTS',
  RECEIVE_PLAYLISTS: 'RECEIVE_PLAYLISTS',
  DELETE_PLAYLIST: 'DELETE_PLAYLIST',
  UPDATE_PLAYLIST: 'UPDATE_PLAYLIST',
  EDIT_PLAYLIST: 'EDIT_PLAYLIST',
  ADD_MODULE: 'ADD_MODULE',
  UPDATE_MODULE: 'UPDATE_MODULE',
  DELETE_MODULE: 'DELETE_MODULE',
};
