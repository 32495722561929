import axios from 'axios';
import store from './redux/store';
import { openSnackBar } from './redux/snackbar/snackbar.actions';

let url = 'https://api.brasilparalelo.com.br';

const api = axios.create({
  baseURL: url,
});

api.interceptors.request.use(
  (config) => {
    config.headers.Authorization = localStorage.getItem('token')
      ? JSON.parse(localStorage.getItem('token'))
      : undefined;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      store.dispatch(
        openSnackBar({
          type: 'success',
          message: response.data.message,
        })
      );
      return response.data.data;
    }
    return response;
  },
  (error) => {
    const response = error.response;
    store.dispatch(
      openSnackBar({
        type: 'error',
        message: response ? response.data.message : error.message,
      })
    );
    return Promise.reject(error);
  }
);

export default api;
