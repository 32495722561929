import React from 'react';
import { Button } from '@material-ui/core';
import PageTitle from '../../app/main/Title';

const UsersManager = () => {
  return (
    <>
      <PageTitle>Gestão de Membros</PageTitle>
      <p>
        Crie, edite e importe usuários para a base de forma manual. Utilize os
        botões abaixo para seguir com as ações desejadas.
      </p>
      
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
	  paddingBottom: '1rem',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          href="/admin/users/create"
        >
          Adicionar um usuário
        </Button>

        <Button
          variant="contained"
          color="default"
          href="/admin/users/import"
        >
          Importar
        </Button>
      </div>
    </>
  );
};

export default UsersManager;
