import React, { useEffect, useState } from 'react';
import { useQuery } from 'urql';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import IpManagerAccordion from './IpManagerAccordion';

import {
    FormControl,
    TextField,
    Button
} from '@material-ui/core';

import PageTitle from '../../../components/app/main/Title';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const geoIpQuery = `
  query ($ip: String!){
    admin_redis_ip_check(ip: $ip){
        redis
        able_to_watch_test
        message
    }
  }
`

function Users() {
    const [ip, setIp] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [geoIpQueryResult, executeGeoIpQuery] = useQuery({
        query: geoIpQuery,
        variables: { ip: ip },
    });
    const [responseData, setResponseData] = useState(null);

    const [snackOpen, setOpen] = React.useState(false);
    const [processConfirmed, setConfirmationBar] = React.useState(false);
    const [consultedIps] = React.useState([]);

    const processSnackBarAction = () => {
        setOpen(false);
        setIp(inputValue);
        setInputValue('');
    }
    const handleSnackBarOpen = () => {
        setOpen(true);
    }
    const handleSnackBarClose = async (event, reason) => {
        if (reason === 'clickaway') {
            setOpen(false);
            return;
        }
    }

    useEffect(() => {
        if (ip) {
            executeGeoIpQuery();
        }
    }, [ip, executeGeoIpQuery]);

    useEffect(() => {
        if (!geoIpQueryResult.fetching) {
            setResponseData(geoIpQueryResult.data ? geoIpQueryResult.data['admin_redis_ip_check'] : null);
        }
    }, [geoIpQueryResult])

    useEffect(() => {
        if (ip) {
            consultedIps.push({
                ip: ip,
                results: responseData,
                timeStamp: new Date().toLocaleTimeString()
            });
            setConfirmationBar(true);
            setIp('');
        }
      // eslint-disable-next-line
    }, [consultedIps, responseData]);


    let inputField = <TextField
        id={`ipInputTextFieldId`}
        label="input ip:"
        type="text"
        value={inputValue}
        onChange={(event) => {
            setInputValue(event.target.value);
        }}
    />
    let form = <FormControl style={{ width: '250px' }}>
        {inputField}
    </FormControl>;

    const snackBarAction = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={processSnackBarAction}>
                Confirm
            </Button>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <PageTitle>Geo-ip Manager</PageTitle>
            <div style={{ display: 'flex', width: '100%', background: 'white', flexDirection: 'column' }}>
                <div style={{ display: 'flex' }}>
                    {form}
                    <Button disabled={false}
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: '15px' }}
                        onClick={(event) => {
                            if (inputValue === '') return;
                            handleSnackBarOpen();
                        }}>
                        Run ip Analysis
                    </Button>
                </div>
            </div>
            <br></br>
            <IpManagerAccordion consultedIps={consultedIps}></IpManagerAccordion>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={snackOpen} message={`Prosseguir para o IP fornecido?`} onClose={handleSnackBarClose}
                action={snackBarAction}>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={processConfirmed} autoHideDuration={3500}
                onClose={() => { setConfirmationBar(false) }}>
                <Alert onClose={() => { setConfirmationBar(false) }} severity="success" sx={{ width: '100%' }}>
                    Analise de ip executada com sucesso
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}

export default Users;
