import { roleActionTypes } from './role.types';

const INITIAL_STATE = {
  roles: [],
  isFetching: false,
};

const playlistReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case roleActionTypes.FETCH_ROLES:
      return {
        ...state,
        isFetching: true,
      };
    case roleActionTypes.RECEIVE_ROLES:
      return {
        ...state,
        roles: action.payload,
        isFetching: Object.assign({}, { isFetching: false }),
      };
    case roleActionTypes.CREATE_ROLE:
      return {
        ...state,
        roles: [].concat(state.roles, [action.payload]),
      };
    case roleActionTypes.UPDATE_ROLE:
      return {
        ...state,
        roles: state.roles.map((p) =>
          p._id === action.payload._id ? Object.assign(p, action.payload) : p
        ),
      };
    case roleActionTypes.DELETE_ROLE:
      return {
        ...state,
        roles: state.roles.filter((p) => p._id !== action.payload._id),
      };
    default:
      return state;
  }
};
export default playlistReducer;
