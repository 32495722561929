import React, { createContext, useContext, useState } from 'react';
const NavContext = createContext();

const { Provider } = NavContext;
export const NavProvider = ({ children }) => {
  const [navData, setNavData] = useState(null);
  return <Provider value={[navData, setNavData]}>{children}</Provider>;
};

export const useNavContext = () => useContext(NavContext);
