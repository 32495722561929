import React from 'react';
import BrokerProductList from '../../../components/admin/broker-products/list';

function BrokerProducts(props) {
  return (
    <React.Fragment>
      <BrokerProductList />
    </React.Fragment>
  );
}

export default BrokerProducts;
