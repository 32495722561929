import { brokerProductActionTypes } from './brokerproduct.types';

const INITIAL_STATE = {
  currentBrokerProduct: null,
  brokerProducts: [],
  toUpdate: null,
  forgotSucceed: false,
  resetSucceed: false,
};

const brokerProductReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case brokerProductActionTypes.FETCH_BROKER_PRODUCTS:
      return {
        ...state,
        isFetching: true,
      };
    case brokerProductActionTypes.EDIT_BROKER_PRODUCT:
      return {
        ...state,
        toUpdate: action.payload,
      };
    case brokerProductActionTypes.RECEIVE_BROKER_PRODUCTS:
      return {
        ...state,
        brokerProducts: action.payload.data,
        page: action.payload.page,
        totalCount: action.payload.totalCount,
        limit: action.payload.limit,
        isFetching: true,
      };
    case brokerProductActionTypes.SET_CURRENT_BROKER_PRODUCT:
      return {
        ...state,
        currentBrokerProduct: action.payload,
      };
    default:
      return state;
  }
};
export default brokerProductReducer;
