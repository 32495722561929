import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function IpManagerAccordion(props) {

  const interpretRedis = (responseData) => {
    if (responseData) {
      switch (responseData.redis) {
        case null:
          return 'No entry for that ip was found on database - Ip is not blocked.'
        case true:
          return 'Ip entry set as TRUE on database - Ip is not blocked.'
        case false:
          return 'Ip entry set as FALSE on database - Ip is blocked - Re-evaluating ip geo-provider record.'
        default:
          return 'got unexpected result for redis'
      }
    }
  }
  const interpretAbleToWatch = (responseData) => {
    if (responseData) {
      switch (responseData.able_to_watch_test) {
        case null:
          return 'Tests were not run - ip is not blocked.'
        case true:
          return 'Passed tests - attempting to delete ip entry from redis and unblock the ip.'
        case false:
          return 'Failed tests - ip should remain blocked.'
        default:
          return 'got unexpected result for test'
      }
    }
  }

  const determineIpStatus = (data) => {
    if (data.results && (data.results.able_to_watch_test === null || data.results.able_to_watch_test === true)) {
      return true;
    }  else {
      return false;
    }
  }

  return (
    <div>
      {props.consultedIps.map((data, index) => {
        if (data.ip !== '') {
          return (
            <Accordion key={`ip-manager-accordion-list-${index}`}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header">
                <Typography style={{ width: '100%', fontSize: '15px', fontWeight: 'bolder' }}>
                <span style ={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                {data.timeStamp ? `@ ${data.timeStamp} - ` : ''} {data ? `consulted ip: ${data.ip}` : ''}
                  <span style={{textAlign: 'center', marginLeft: 'auto', marginRight: '100px', borderStyle: 'solid', borderWidth: '1px', borderRadius: '5px',
                   borderColor: 'black', padding: '5px',
                   background: '#DDDDDD',
                   color: determineIpStatus(data)?'darkgreen':'darkred'}}>
                    {determineIpStatus(data) ? 'ip OK' : 'ip BLOCKED'}
                   </span>
                </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{ fontSize: '15px', fontWeight: 'normal' }}>
                  {'Results:'}<br></br>
                  {data.results ? `db: ` + data.results.redis : ''} - {data.results ? `test: ` + data.results.able_to_watch_test : ''}<br></br>
                  {data.results ? interpretRedis(data.results) : 'n/a'}<br></br>
                  {data.results ? interpretAbleToWatch(data.results) : 'n/a'}<br></br>
                  {data.results ? `message: ${data.results.message}` : 'n/a'}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        }
        else { return (null); }
      })}
  
    </div>
  );
}
