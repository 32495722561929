import cloudinary from 'cloudinary-core';
import axios from 'axios';

const cloudinaryClient = new cloudinary.Cloudinary({ cloud_name: 'hvzbb2hdx' });

async function retrieveImageBytes({ name, image, version, metadata }) {
  return await axios
    .get(
      cloudinaryClient.url(image, {
        version,
      }),
      {
        responseType: 'blob',
      }
    )
    .then((response) => {
      return { file: response.data, name, metadata };
    })
    .catch((err) => {
      console.log(
        'failed to retrieve image: ',
        {
          name,
          image,
          version,
          metadata,
        },
        err
      );
      return {};
    });
}

const getSourceUrl = ({ image, version }) => {
  return cloudinaryClient.url(image, {
    version,
  });
};

export { retrieveImageBytes, getSourceUrl };
