import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import PaymentApi from '../../../payment-api';
import PageTitle from '../../app/main/Title';

function UploadUsers() {
  const fileInputId = 'file-input-name';
  const [file, setFile] = useState(undefined);

  const uploadUsersFile = async (file) => {
    let formData = new FormData();
    formData.append('file', file);

    PaymentApi.post('/api/files/create_members', formData);
  };

  return (
    <>
      <PageTitle>Importação de novos membros</PageTitle>
      <p>
        Para iniciar uma ação de importação automática, carregue um arquivo csv
        com os dados dos novos membros seguindo o{' '}
        <a
          target="_blank"
          href="https://docs.google.com/spreadsheets/d/1arwzbczKGdC116XXFE2Yqw5LoApUYjbX-Now47wjMIU/template/preview"
          rel="noopener noreferrer"
        >
          {' '}
          modelo definido
        </a>
        .
      </p>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          justifyContent: 'space-between',
          paddingBottom: '1rem',
        }}
      >
        <input
          id={fileInputId}
          label="Arquivo CSV"
          type="file"
          accept=".csv"
          onChange={() =>
            setFile(document.getElementById(fileInputId).files[0])
          }
        ></input>

        <Button
          disabled={file === undefined}
          variant="contained"
          color="primary"
          onClick={() => uploadUsersFile(file)}
        >
          Iniciar importação
        </Button>
      </div>
    </>
  );
}

export default UploadUsers;
