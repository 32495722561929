import EnabledCommentsRules from '../../../pages/admin/enabled-comments-rules';

export default [
  {
    link: '/admin/enabled-comments-rules',
    component: EnabledCommentsRules,
    auth: true,
    roles: ['admin'],
  },
];
