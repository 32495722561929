import { loadingActionTypes } from './loading.types';

const INITIAL_STATE = {
    isLoading: false
}

const loadingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case loadingActionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
            default:
                return state
        }
    }

export default loadingReducer