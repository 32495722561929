import {
  Checkbox,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import api from '../../../integrations/api';
const useStyle = makeStyles({
  root: {
    width: '100%',
  },
});

const TeacherSelect = ({ name, label, selectedTeachers = [], inputChange }) => {
  const [allTeachers, setAllTeachers] = useState([]);
  const classes = useStyle();

  useEffect(() => {
    Promise.all([api.teachers.all()])
      .then(([teachersResponse]) => {
        const allTeachers = teachersResponse.data;
        // MaterialUI's Select component relies on the options lists ('selected' and 'all')
        // to have the same object reference; just looking the same does not suffice.
        const allTeachersRef = allTeachers.map(
          (t) => selectedTeachers.find((st) => st._id === t._id) || t
        );
        setAllTeachers(allTeachersRef);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);

  const getTeacherName = (id) => {
    return allTeachers?.find((t) => t._id === id)?.name;
  }

  console.log("dbg, selected Teachers ", selectedTeachers);
  return (
    <>
      <InputLabel id="teachers">{label || 'Professores'}</InputLabel>
      <Select
        className={classes.root}
        labelId="teachers"
        id="teachers"
        multiple
        name={name || 'teachers'}
        renderValue={(selected) => selected.map((s) => getTeacherName(s)).join(', ')}
        value={selectedTeachers ? selectedTeachers: []}
        onChange={inputChange}
      >
        {allTeachers.map((t) => (
          <MenuItem key={t._id} value={t}>
            <Checkbox
              checked={
                selectedTeachers.findIndex((st) => st === t._id) > -1
              }
            />
              <ListItemText primary={t.name} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
export default TeacherSelect;
