import React, { useContext, useEffect, useState } from 'react';

import {
  FormHelperText,
  Grid,
  InputLabel,
  NativeSelect,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { PlaylistContext } from '../playlists/PlaylistContext';

import teachersApi from '../../../integrations/api/teachers';
import levelsApi from '../../../integrations/api/levels';

var md = require('markdown-it')({
  html: true,
});

const CourseInputs = () => {
  const [state, dispatch] = useContext(PlaylistContext);

  const [teachers, setTeachers] = useState();
  const [levels, setLevels] = useState();

  useEffect(() => {
    teachersApi.all().then(({ data }) => {
      setTeachers(data);
    });

    levelsApi.search().then(({ data }) => {
      setLevels(data);
    });
  }, [state]);

  const inputChange = (key, value) => {
    dispatch({
      type: 'CHANGE_INPUT',
      payload: {
        [key]: value,
      },
    });
  };

  if (!teachers || !state.type || !levels) return null;

  const levelOptions = [
    <option key={''} value={undefined}></option>,
    ...levels.map((level) => (
      <option key={level._id} value={level._id}>
        {level.name}
      </option>
    )),
  ];

  return (
    <>
      <Grid item xs={12} lg={6}>
        <Autocomplete
          options={teachers}
          onChange={(event, value) => {
            event.preventDefault();
            inputChange('teacher', value);
          }}
          autoComplete
          getOptionLabel={(option) => option.name}
          name="teacher"
          value={state.teacher ? state.teacher : {}}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Professor *"
              name="teacher"
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <InputLabel shrink htmlFor="level-select">
          Nível
        </InputLabel>
        <NativeSelect
          style={{ width: '100%' }}
          id="level-select"
          required
          defaultValue={state.level}
          onChange={(event) => inputChange('level', event.target.value)}
        >
          {levelOptions}
        </NativeSelect>
        <FormHelperText>Nível de dificuldade do curso</FormHelperText>
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          defaultValue={state.bibliography}
          onChange={({ target }) => inputChange('bibliography', target.value)}
          label="Bibliografia"
          helperText={`Você pode utilizar marcações Markdown para estilizar o texto (https://daringfireball.net/projects/markdown/basics). 
          DICAS: Para adicionar um texto em negrito **bote ele entre dois asteriscos dessa forma**.
          Comece cada item da bibliografia com '- ' (traço + espaço).
          Para quebrar linha, são necessários 2 enters.
          Acompanhe o preview das suas alterações ao lado.`}
          multiline
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <InputLabel shrink htmlFor="ref-preview">
          Pré-visualização da bibliografia
        </InputLabel>
        <div
          id="ref-preview"
          className="flex flex-col leading-8"
          dangerouslySetInnerHTML={{
            __html: md.render(state.bibliography ? state.bibliography : ''),
          }}
        />
      </Grid>
    </>
  );
};
export default CourseInputs;
