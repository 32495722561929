import React, { useEffect, useState } from 'react';
import { Save as SaveIcon, Edit } from '@material-ui/icons';
import { Button, IconButton } from '@material-ui/core';
import moment from 'moment';

import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
} from '@mui/material';

import DataTable from '../../../components/table/DataTable';
import { useHistory } from 'react-router-dom';
import PageTitle from '../../../components/app/main/Title';

import {
  getMainBanners,
  getLastActiveMainBanners,
} from '../../../integrations/api/banners';

const translateVisibility = {
  all: 'Todos',
  bpeiros: 'BPeiros',
  nobody: 'Invisível',
  'free-only': 'Somente free',
};

const columns = [
  {
    name: '_id',
    label: ' ',
  },
  {
    name: 'title',
    label: 'Título',
  },
  {
    name: 'description',
    label: 'Descrição',
  },
  {
    name: 'start',
    label: 'Data inicial',
  },
  {
    name: 'end',
    label: 'Data final',
  },
  {
    name: 'visibility',
    label: 'Visibilidade',
  },
];

const activeBannersToRows = (activeBanners, onClick) => {
  let LinkToBanner = (banner) =>
    banner ? (
      <Button
        variant="text"
        onClick={() => onClick(banner._id)}
        style={{
          textTransform: 'unset',
          fontWeight: 'normal',
          color: 'blue',
          width: '100%',
        }}
      >
        {banner.title}
      </Button>
    ) : (
      <span style={{ color: 'red' }}>error fetching data</span>
    );

  return (
    activeBanners && [
      [
        <span style={{ whiteSpace: 'nowrap' }}>Brasil <span role='img' aria-label='br-flag'>🇧🇷</span></span>,
        LinkToBanner(activeBanners.home?.brasil),
        LinkToBanner(activeBanners.movies?.brasil),
        LinkToBanner(activeBanners.shows?.brasil),
      ],
      [
        <span style={{ whiteSpace: 'nowrap' }}>Internacional <span role='img' aria-label='globe'>🌎</span></span>,
        LinkToBanner(activeBanners.home?.international),
        LinkToBanner(activeBanners.movies?.international),
        LinkToBanner(activeBanners.shows?.international),
      ],
      [
        <span style={{ whiteSpace: 'nowrap' }}>Freemium <span role='img' aria-label='free'>🆓</span></span>,
        LinkToBanner(activeBanners.home?.freemium),
        LinkToBanner(activeBanners.movies?.freemium),
        LinkToBanner(activeBanners.shows?.freemium),
      ],
    ]
  );
};

const ActiveBanners = ({ activeBanners, onClick }) => {
  const columns = [
    {
      name: 'location',
      label: '',
    },
    {
      name: 'home',
      label: 'Home',
    },
    {
      name: 'movies',
      label: 'Filmes',
    },
    {
      name: 'shows',
      label: 'Programas',
    },
  ];

  return activeBanners ? (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell key={col.name} variant="head" align="center">
                {col.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {activeBannersToRows(activeBanners, onClick).map((row, i) => (
            <TableRow key={i}>
              {row.map((item, j) => (
                <TableCell key={j}>{item}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
};

function Banners() {
  const history = useHistory();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalDocs, setTotalDocs] = useState(0);

  const [homeBannersList, setHomeBannersList] = useState([]);
  const [activeBanners, setActiveBanners] = useState();

  useEffect(() => {
    getMainBanners({ page: page + 1, limit }).then((response) => {
      setHomeBannersList(response.data.docs);
      setTotalDocs(response.data.totalDocs);
    });
  }, [limit, page]);

  useEffect(() => {
    Promise.all(
      ['home', 'movies', 'shows'].map((page) => {
        return new Promise((resolve) => {
          getLastActiveMainBanners(page)
            .then((response) => resolve({ page, response }))
            .catch((e) => {
              console.log(e);
              resolve({ page, response: null });
            });
        });
      })
    ).then((arr) => {
      let data = {};
      arr.forEach(({ page, response }) => {
        if (response) {
          data[page] = {
            brasil: response.data.normal,
            international: response.data.international,
	    freemium: response.data.freemium,
          };
        }
      });
      setActiveBanners(data);
    });
  }, []);

  const onClickEditButton = (id) => {
    history.push(`/admin/mainBanner/edit/${id}`);
  };

  const options = {
    page: page,
    rowHover: true,
    selectableRows: 'none',
    print: false,
    download: false,
    serverSide: true,
    count: totalDocs,
    responsive: 'vertical',
    tableBodyHeight: '100%',
    tableBodyMaxHeight: '',
    sort: false,
    filter: false,
    viewColumns: false,
    search: false,
    onChangePage: (newPage) => {
      setPage(newPage);
    },
    onChangeRowsPerPage: (newLimit) => {
      setPage(0);
      setLimit(newLimit);
    },
    customRowRender: (data) => {
      const [_id, title, description, start, end, visibility] = data;

      return (
        <tr
          key={_id}
          style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
        >
          <td style={{ width: 80 }}>
            <IconButton
              aria-label="edit"
              component="span"
              onClick={() => {
                onClickEditButton(_id);
              }}
            >
              <Edit />
            </IconButton>
          </td>
          <td align="left">{title ? title : 'Utilizando logo'}</td>
          <td style={{ width: 300 }} align="left">
            {description}
          </td>
          <td align="rigth">
            {start ? moment(start).format('DD/MM/YYYY HH:mm') : ''}
          </td>
          <td align="rigth">
            {end ? moment(end).format('DD/MM/YYYY HH:mm') : ''}
          </td>
          <td align="rigth">{translateVisibility[visibility]}</td>
        </tr>
      );
    },
  };

  return (
    <>
      <PageTitle>Banners</PageTitle>
      <div>
        <h2>Ativos</h2>
        <ActiveBanners
          activeBanners={activeBanners}
          onClick={onClickEditButton}
        />
        <br></br>
      </div>

      <div style={{ textAlign: 'right', marginBottom: 20 }}>
        <h2 style={{ textAlign: 'left' }}>Listagem</h2>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<SaveIcon />}
          onClick={() => history.push(`/admin/mainBanner/create`)}
        >
          Inserir novo
        </Button>
      </div>

      <DataTable data={homeBannersList} columns={columns} options={options} />
    </>
  );
}

export default Banners;
