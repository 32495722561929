import ModulesPage from '../../../pages/admin/modules';
import ModuleCreate from '../../admin/modules/create';

export default [
  {
    link: '/admin/modules',
    component: ModulesPage,
    auth: true,
    roles: ['admin'],
  },
  {
    link: '/admin/modules/create',
    component: ModuleCreate,
    auth: true,
    roles: ['admin'],
  },
];
