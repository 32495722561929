import { useSelector } from 'react-redux';
import {
  Button,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import API from '../../api';
import Fuse from 'fuse.js';
import { componentsMap } from './componentsMap'
import { TagCreationConfirmationDialog } from './TagCreationConfirmationDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  buttonGroup: {
    '& > *': {
      margin: theme.spacing(2),
    },
  },
}));

const trimString = (s, l) => {
  if (s.length > l) {
    return s.substring(0, l);
  }
  return s;
};

const getSimilarTags = (newTags, chipOptions, storedTags) => {
  const fuse = new Fuse([...new Set([...chipOptions, ...storedTags])], {
    threshold: 0.3,
  });
  return newTags.flatMap((t) => fuse.search(t).map((e) => e.item));
};

const getNewTags = (updatedTagValues, chipOptions, storedTags) =>
  updatedTagValues.filter(
    (x) => chipOptions.indexOf(x) < 0 && storedTags.indexOf(x) < 0
  );

export default function FormBuilder({ data, structure, onSubmit }) {
  const classes = useStyles();

  const currentUser = useSelector((state) => state.user.currentUser);
  const [formState, setFormState] = useState(data);
  const [chipOptions, setChipOptions] = useState({
    mediaKind: [],
    contentDescription: [],
  });

  const [
    tagConfirmationDialogOpen,
    setTagConfirmationDialogOpen,
  ] = React.useState(false);
  const [newTags, setNewTags] = React.useState({
    mediaKind: { newTags: [], similarTags: [] },
    contentDescription: { newTags: [], similarTags: [] },
  });

  useEffect(() => {
    const getData = async () => {
      const [mediaKind, contentDescription] = await Promise.all([
        API.get('/tags?kind=mediaKind'),
        API.get('/tags?kind=contentDescription'),
      ]);
      setChipOptions({
        mediaKind: mediaKind.data,
        contentDescription: contentDescription.data,
      });
    };
    getData();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    // if there are new tags, first show confirmation dialog
    const newMediaKindTags = getNewTags(
      formState.tags || [],
      chipOptions.mediaKind,
      data.tags || []
    );
    const newContentTags = getNewTags(
      formState.contentTags || [],
      chipOptions.contentDescription,
      data.contentTags || []
    );
    if (newMediaKindTags.length || newContentTags.length) {
      // remember new tags in order to avoid getting repeated confirmation on a new tag
      setChipOptions((chipOptions) => ({
        mediaKind: [...chipOptions.mediaKind, ...newMediaKindTags],
        contentDescription: [
          ...chipOptions.contentDescription,
          ...newContentTags,
        ],
      }));
      const similarMediaTags = getSimilarTags(
        newMediaKindTags,
        chipOptions.mediaKind,
        data.tags || []
      );
      const similarContentTags = getSimilarTags(
        newContentTags,
        chipOptions.contentDescription,
        data.contentTags || []
      );
      setNewTags({
        mediaKind: { newTags: newMediaKindTags, similarTags: similarMediaTags },
        contentDescription: {
          newTags: newContentTags,
          similarTags: similarContentTags,
        },
      });
      setTagConfirmationDialogOpen(true);
    } else {
      onSubmit(formState);
    }
  };

  const handleSubmitAfterConfirmation = () => onSubmit(formState);

  const finalFields = structure.fields.map((fieldProps, index) => {
    const type = fieldProps.type;

    const isCustom = type === 'custom';

    const componentGenerator = isCustom
      ? fieldProps.component
      : componentsMap[type];

    const generalHandleChange = (e, target, index) => {
      if (type === 'textfield' && fieldProps.max) {
        setFormState((state) => ({
          ...state,
          [fieldProps.name]: trimString(target.value, fieldProps.max),
        }));
      } else if (type === 'advisoryChipInput') {
        setFormState((state) => ({
          ...state,
          [fieldProps.name]: (index || []).sort(),
        }));
      } else if (type === 'chipinput' || type === 'contentChipInput') {
        setFormState((state) => ({ ...state, [fieldProps.name]: index }));
      } else {
        const value = type === 'checkbox' ? e.target.checked : target.value;
        setFormState((state) => ({ ...state, [fieldProps.name]: value }));
      }
    };

    const component = React.cloneElement(
      componentGenerator({
        fieldProps,
        // It is necessary to send the index in the event callback
        // so the autocomplete works properly
        // reference: https://mui.com/material-ui/react-autocomplete/
        handleChange: (e, index) => generalHandleChange(e, e.target, index),
        index,
        chipOptions: chipOptions.mediaKind,
        contentDescriptionChipOptions: chipOptions.contentDescription,
        data: formState,
        currentUser,
      })
    );

    return {
      isCustom,
      gridSize: fieldProps.gridSize || 4,
      component,
    };
  });

  return (
    <Paper>
      <TagCreationConfirmationDialog
	tagConfirmationDialogOpen={tagConfirmationDialogOpen}
	setTagConfirmationDialogOpen={setTagConfirmationDialogOpen}
	newTags={newTags}
	handleSubmitAfterConfirmation={handleSubmitAfterConfirmation}
      />
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <div className={classes.root}>
          <Typography variant="h5" component="h5">
            {structure.title}
          </Typography>
          <Grid container spacing={3}>
            {finalFields.map((f, index) => (
              <Grid item xs={f.gridSize} key={index}>
                {f.component}
              </Grid>
            ))}
          </Grid>
        </div>
        <div className={classes.buttonGroup}>
          <Button type="submit" variant={'contained'} color={'primary'}>
            Salvar
          </Button>
        </div>
      </form>
    </Paper>
  );
}
