import api from '../../api';

export const getAllDistributors = () =>
  api
    .get('/distributors')
    .then((response) => response.data)
    .catch((err) => console.log(err));

export const createDistributor = (data) =>
  api
    .post('/distributors', data)
    .then((response) => {
      alert('Distribuidor criado');
      return response;
    })
    .catch(() => {
      alert('Não foi possível adicionar um distribuidor');
    });

export const getDistributor = (id) =>
  api
    .post(`/distributors/${id}`)
    .then((response) => response)
    .catch((err) => console.log(err));

export const updateDistributor = (data) =>
  api
    .put(`/distributors/${data._id}`)
    .then(() => alert('Distribuidor atualizado'))
    .catch((err) => {
      alert('Não foi possível atualizar o distribuidor');
      console.log(err);
    });
