import api from '../../api';

export default {
  all: function(params) {
    return api.get('/levels', {
      params,
    });
  },
  findById: function(id) {
    return api.get(`/levels/${id}`);
  },
  create: function(data) {
    return api.post('/levels', data);
  },
  search: function(search) {
    return this.all({ search });
  },
};
