import { PictureAsPdf, Audiotrack } from '@material-ui/icons';
import React, { useContext } from 'react';
import DndTable from '../../table/DndTable';
import { PlaylistContext } from '../playlists/PlaylistContext';
import TeacherSelect from '../teachers/TeacherSelect';

import { TextField } from '@material-ui/core';

import LabeledDropZone from '../../files/LabeledDropZone';
import { getSourceUrl } from '../../../integrations/cloudinary';

import { uploadImage } from '../../../integrations/api/files';

var md = require('markdown-it')({
  html: true,
});

const MediasTable = ({
  medias = [],
  moduleIndex,
  setModuleDragNotAllowed,
  distributorValues,
  advisoryTags,
  isCourse,
  advisoryRatings,
}) => {
  const columns = [{ title: 'Nome', field: 'name' }];
  const [, dispatch] = useContext(PlaylistContext);

  const structure = {
    fields: [
      {
        type: 'textfield',
        name: 'name',
        label: 'Nome',
        helperText: 'Nome do vídeo exibido na plataforma',
      },
      {
        type: 'textfield',
        name: 'sourceInput',
        label: 'Link/Path',
        helperText: 'Link do vimeo/wistia para cadastro ou path do vídeo no S3',
      },
      {
        type: 'datefield',
        name: 'releaseDate',
        label: 'Data de lançamento',
        helperText:
          'Data de lançamento do vídeo na plataforma. Vídeos com data futura NÃO são exibidos para membros',
      },
      {
        type: 'checkbox',
        name: 'visibility',
        label: 'Visível para membros',
        helperText:
          'Marque esse campo para que o vídeo seja visível para todos os membros',
        checked: true,
      },
      {
        type: 'checkbox',
        name: 'processMedia',
        label: 'Processar vídeo para cadastro',
        helperText:
          'Marque esse campo caso o vídeo seja novo ou tenha sofrido alguma alteração no vimeo/wistia',
        checked: false,
      },
      {
        type: 'chipinput',
        name: 'tags',
        label: 'Tags',
      },
      {
        type: 'contentChipInput',
        name: 'contentTags',
        label: 'Content Tags',
      },
      {
        type: 'datefield',
        name: 'exitDate',
        label: 'Data de saída',
        helperText:
          'Data de saída do vídeo na plataforma. Vídeos com data no passado NÃO são exibidos',
      },
      isCourse
        ? {
            type: 'custom',
            component: ({ data }) => {
              return (
                <TeacherSelect
                  selectedTeachers={data.teachers}
                  name="teachers"
                  inputChange={(event) => {
                    let clickedOption = event.target.value.find((t) => t._id)
                      ._id;
                    let mediaIndex = medias.findIndex(
                      (m) => m._id === data._id
                    );

                    if (!data.teachers) data.teachers = [];

                    if (
                      data.teachers.findIndex((t) => t === clickedOption) > -1
                    ) {
                      data.teachers = data.teachers.filter(
                        (t) => t !== clickedOption
                      );
                    } else {
                      data.teachers = [...data.teachers, clickedOption];
                    }

                    dispatch({
                      type: 'REPLACE_MEDIA',
                      payload: { moduleIndex, mediaIndex, data },
                    });
                  }}
                />
              );
            },
          }
        : null,
      {
        type: 'dropdown',
        name: 'distributor',
        label: 'Distribuidor',
        values: distributorValues.map((d) => {
          return { label: d._id, value: d._id };
        }),
        helperText:
          'Distribuidor da mídia. Cada distribuidor possui regras próprias de download, que poderão afetar a possibilidade de um usuário de baixar essa mídia.',
      },
      {
        type: 'textfield',
        modifier: 'multiline',
        max: 160,
        name: 'description',
        label: 'Descrição da mídia',
        helperText: 'Uma breve sinopse do vídeo. (Máx 160 carácteres)',
      },
      {
        type: 'advisoryDropdown',
        name: 'rating',
        label: 'Idade Mínima',
        values: advisoryRatings,
        helperText:
          'Idade Mínima para classificação indicativa. Caso não selecionado a classificação padrão será A16.',
      },
      {
        type: 'advisoryChipInput',
        name: 'advisory',
        label: 'Motivo',
        values: advisoryTags,
        helperText: 'Motivo da classificação indicativa.',
      },
      {
        type: 'custom',
        name: 'bibliography',
        component: ({ data }) => {
          return (
            <>
              <TextField
                defaultValue={data.bibliography}
                onChange={({ target }) => {
                  data.bibliography = target.value;
                  let mediaIndex = medias.findIndex((m) => m._id === data._id);

                  dispatch({
                    type: 'REPLACE_MEDIA',
                    payload: { moduleIndex, mediaIndex, data },
                  });
                }}
                label="Bibliografia"
                helperText={`Você pode utilizar marcações Markdown para estilizar o texto (https://daringfireball.net/projects/markdown/basics).
          DICAS: Para adicionar um texto em negrito **bote ele entre dois asteriscos dessa forma**.
          Comece cada item da bibliografia com '- ' (traço + espaço).
          Para quebrar linha, são necessários 2 enters.
          Acompanhe o preview das suas alterações ao lado.`}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </>
          );
        },
      },
      {
        type: 'textfield',
        name: 'youTubeId',
        label: 'ID do vídeo no YouTube',
        helperText: 'Cadastro para caso a mídia tenha um correspondente no YouTube, para exibi-lo aos usuários deslogados.'
	  + ' Exemplo de ID: Para o vídeo de URL https://www.youtube.com/watch?v=LCTOmIT9yT4, o ID do vídeo é LCTOmIT9yT4.',
      },
      {
        type: 'custom',
        component: ({ data }) => {
          return (
            <div
              id="ref-preview"
              className="flex flex-col leading-8"
              dangerouslySetInnerHTML={{
                __html: md.render(data.bibliography ? data.bibliography : ''),
              }}
            />
          );
        },
      },
      {
        type: 'custom',
        gridSize: 12,
        component: ({ data }) => {
          let filePreview;
          let file;

          if (data.thumbnail) {
            filePreview = data.thumbnail;
          }

          return (
            <LabeledDropZone
              maxWidth={480}
              width={480}
              height={270}
              name="thumbnail"
              label="Miniatura do Episódio"
              helperText="Imagem mostrada na página de detalhes e no 'Continuar Assistindo'. Dimensões: 1920x1080px"
              initialFileUrl={filePreview ? getSourceUrl(filePreview) : ''}
              initialFile={file}
              inputChange={(event) => {
                data.thumbnail = event.target.value;
              }}
            />
          );
        },
      },
    ].filter((item) => {
      return item;
    }),
  };

  const customActions = [
    {
      title: 'Adicionar materiais',
      icon: () => <PictureAsPdf />,
      onClick: (mediaIndex) => {
        dispatch({
          type: 'OPEN_MATERIALS',
          payload: {
            moduleIndex,
            mediaIndex,
          },
        });
      },
    },
  ];

  if (moduleIndex === undefined) {
    customActions.push({
      title: 'Adicionar áudio',
      icon: () => <Audiotrack />,
      onClick: (mediaIndex) => {
        dispatch({
          type: 'OPEN_AUDIO',
          payload: {
            moduleIndex,
            mediaIndex,
            formType: 'ADD_MEDIA_AUDIO',
          },
        });
      },
    });
  }

  const onSortSubmit = (data) => {
    dispatch({
      type: 'SET_MEDIAS',
      payload: {
        moduleIndex,
        data,
      },
    });
  };

  const setLoading = (loading) => {
    dispatch({
      type: 'SET_LOADING',
      payload: { loading },
    });
  };

  return (
    <DndTable
      title="Listagem de mídias"
      data={medias}
      columns={columns}
      customActions={customActions}
      submitAdd={(record, position) => {
        if (record.thumbnail && !record.thumbnail.image) {
          setLoading(true);
          uploadImage({
            image: record.thumbnail,
            path: '/playlists/medias/sixteenNine',
            name: 'thumbnail',
          })
            .then((response) => {
              const newMedia = {
                ...response.data.reference,
                name: record.name,
                visibility: record.visibility,
                releaseDate: record.releaseDate,
                exitDate: record.exitDate,
                processMedia: record.processMedia,
                description: record.description,
                tags: record.tags,
                contentTags: record.contentTags,
                distributor: record?.distributor?._id,
                bibliography: record.bibliography,
                teachers: record.teachers,
                sourceInput: record.sourceInput,
                rating: record.rating,
                advisory: record.advisory,
                youTubeId: record.youTubeId?.trim() || null,
                type: 'video',
                position,
              };
              dispatch({
                type: 'ADD_MEDIA',
                payload: { moduleIndex, newMedia },
              });
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          const newMedia = {
            name: record.name,
            visibility: record.visibility,
            releaseDate: record.releaseDate,
            exitDate: record.exitDate,
            processMedia: record.processMedia,
            description: record.description,
            tags: record.tags,
            contentTags: record.contentTags,
            distributor: record?.distributor?._id,
            bibliography: record.bibliography,
            teachers: record.teachers,
            thumbnail: record.thumbnail,
            sourceInput: record.sourceInput,
            rating: record.rating,
            advisory: record.advisory,
            youTubeId: record.youTubeId?.trim() || null,
            type: 'video',
            position,
          };

          dispatch({
            type: 'ADD_MEDIA',
            payload: { moduleIndex, newMedia },
          });
        }
      }}
      setModuleDragNotAllowed={setModuleDragNotAllowed}
      submitEdit={(record, mediaIndex) => {
        if (record.thumbnail && !record.thumbnail.image) {
          setLoading(true);

          uploadImage({
            image: record.thumbnail,
            path: '/playlists/medias/sixteenNine',
            name: 'thumbnail',
          })
            .then((response) => {
              const newMedia = {
                ...record,
                ...response.data.reference,
                youTubeId: record.youTubeId?.trim() || null,
              };

              dispatch({
                type: 'REPLACE_MEDIA',
                payload: { moduleIndex, mediaIndex, newMedia },
              });
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          const newMedia = {
            ...record,
            youTubeId: record.youTubeId?.trim() || null,
          };

          dispatch({
            type: 'REPLACE_MEDIA',
            payload: { moduleIndex, mediaIndex, newMedia },
          });
        }
      }}
      submitDelete={(record, mediaIndex) => {
        dispatch({
          type: 'REMOVE_MEDIA',
          payload: { moduleIndex, mediaIndex },
        });
      }}
      submitReordering={onSortSubmit}
      fetchRecord={() => {}}
      singleRecordFormStructure={structure}
    />
  );
};

export default MediasTable;
