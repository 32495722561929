import playlistRoutes from '../routes/playlists';
import brokerProductRoutes from '../routes/broker-products';
import offerRoutes from '../routes/offers';
import moduleRoutes from '../routes/modules';
import teacherRoutes from '../routes/teachers';
import userRoutes from '../routes/users';
import dashboardRoutes from '../routes/dashboard';
import subscriptionRoutes from '../routes/subscriptions';
import categoryRoutes from '../routes/categories';
import pageRoutes from '../routes/pages';
import bannersRoutes from '../routes/banners';
import commentFeedRoute from '../routes/comment-feed';
import enabledCommentsRules from '../routes/enabled-comments-rules';

export default [].concat(
  playlistRoutes,
  brokerProductRoutes,
  offerRoutes,
  moduleRoutes,
  teacherRoutes,
  userRoutes,
  dashboardRoutes,
  subscriptionRoutes,
  categoryRoutes,
  pageRoutes,
  bannersRoutes,
  commentFeedRoute,
  enabledCommentsRules
);
