import { FormHelperText, InputLabel, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    margin: '0 auto',
    width: '100%',
    maxWidth: ({ maxWidth }) => maxWidth,
    height: ({ height }) => height,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    color: 'white',
    zIndex: 0,
    backgroundImage: ({ backgroundImage }) => `url('${backgroundImage}')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left center',
    backgroundSize: 'cover',
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      '& > $textContainer': {
        opacity: 1,
        transition: 'opacity .3s',
      },
    },
  },
  label: {
    width: '100%',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  dragText: {
    fontSize: '1.3rem',
    fontWeight: '700',
    color: '#444',
  },
  textContainer: {
    padding: theme.spacing(2),
    backgroundColor: 'rgba(208,208,208,.9)',
    opacity: ({ isDragActive, isEmpty }) => (isDragActive || isEmpty ? 1 : 0),
    border: `2px dashed #333`,
    transition: 'opacity .3s',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    textAlign: 'center',
  },
}));

function DropZone({
  initialFile,
  initialFileUrl,
  setFieldValue,
  width = 300,
  height = 200,
  maxWidth,
  field,
  label,
}) {
  const [file, setFile] = useState();
  const [fileUrlPreview, setFileUrlPreview] = useState();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ['image/jpeg', 'image/jpg', 'image/png'],
    maxSize: 1e7,
    maxFiles: 1,
    onDropAccepted: (acceptedFiles) => {
      const firstFile = acceptedFiles[0];
      setFile(firstFile);
      setFieldValue(field.name, firstFile);
    },
    onDropRejected: () => {
      alert(
        'Formato de imagem não suportado, ou tamanho superior ao máximo permitido (3MB)'
      );
    },
  });

  useEffect(() => {
    if (!initialFile?.image) setFile(initialFile);
  }, [initialFile]);

  useEffect(() => {
    if (file) {
      setFileUrlPreview(URL.createObjectURL(file));
    }
  }, [file]);

  const classes = useStyles({
    width,
    height,
    backgroundImage: initialFileUrl ? initialFileUrl : fileUrlPreview,
    isEmpty: !file && !initialFileUrl,
    isDragActive,
    maxWidth,
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(fileUrlPreview);
    },
    [fileUrlPreview]
  );

  return (
    <>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <div {...getRootProps()} className={classes.thumbsContainer}>
        <input {...getInputProps()} />
        <div className={classes.textContainer}>
          <p className={classes.dragText}>
            Solte um arquivo ou clique para enviar
          </p>
          <CloudUploadIcon fontSize={'large'} />
        </div>
      </div>
    </>
  );
}

const FormikDropZoneArea = ({
  field,
  initialFileUrl,
  form: { setFieldValue },
  helperText,
  label,
  ...props
}) => {
  const { width, height, maxWidth } = props;

  return (
    <>
      <InputLabel style={{ textAlign: 'center' }}>{label}</InputLabel>
      {helperText && (
        <FormHelperText style={{ textAlign: 'center' }}>
          {helperText}
        </FormHelperText>
      )}
      <DropZone
        initialFileUrl={initialFileUrl ? initialFileUrl : ''}
        initialFile={field.value}
        setFieldValue={setFieldValue}
        width={width}
        field={field}
        height={height}
        maxWidth={maxWidth}
      />
    </>
  );
};

export default FormikDropZoneArea;
