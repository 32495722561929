import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  withStyles,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { Delete, Edit, Reorder } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import {
  dettachPlaylistFromCategory,
  updateCategoryFreeOnly,
} from '../../../integrations/api/categories';
import { removeCategory } from '../../../integrations/api/pages';
import AttachPlaylist from './AttachPlaylist';
import Playlists from './Playlists';

const useStyles = makeStyles((theme) => ({
  badge: {},
  catCount: {
    opacity: 0.6,
    fontSize: '.75rem',
  },
  catName: {
    marginRight: theme.spacing(1),
  },
  attachPlaylist: {
    marginBottom: theme.spacing(3),
  },
}));

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'block',
  },
}))(MuiAccordionDetails);

export default ({
  data,
  pageId,
  handlePanelChange,
  removeCategory: removeCallback,
  setDisabledCategoryDrag,
  expanded,
  types,
  updateCategory,
  index,
}) => {
  const [editing, setEditing] = useState(false);
  const [category, setCategory] = useState(data);
  const [isPlaylistDragDisabled, setIsPlaylistDragDisabled] = useState(true);
  const classes = useStyles();
  const inputRef = useRef();

  const onNameChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategory((old) => ({ ...old, category: value }));
  };

  useEffect(() => {
    if (inputRef.current && editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const isExpanded = expanded === category._id;

  const updatePlaylists = (playlists) => {
    setCategory((old) => ({ ...old, playlists: playlists }));
  };
  const addPlaylist = (newPlaylist) => {
    setCategory((old) => ({
      ...old,
      playlists: [...old.playlists, newPlaylist],
    }));
  };

  const dettachPlaylist = ({ playlistId }) =>
    dettachPlaylistFromCategory({
      categoryId: category._id,
      playlistId,
    }).then(() => {
      setCategory((old) => ({
        ...old,
        playlists: old.playlists.filter((pl) => pl._id !== playlistId),
      }));
    });

  const updateFreeOnly = (freeOnly) =>
    updateCategoryFreeOnly({ categoryId: category._id, freeOnly }).then(() =>
      setCategory((old) => ({ ...old, freeOnly: freeOnly }))
    );

  useEffect(() => {
    setDisabledCategoryDrag(!isPlaylistDragDisabled);
  }, [isPlaylistDragDisabled, setDisabledCategoryDrag]);

  useEffect(() => {
    updateCategory(index, category);
  }, [category, index, updateCategory]);

  return (
    <Accordion
      key={category._id}
      square={true}
      expanded={isExpanded}
      onChange={handlePanelChange(category._id)}
    >
      <AccordionSummary
        aria-controls={`${category._id}-content`}
        id={`${category._id}-header`}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            {editing ? (
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setEditing(false);
                }}
              >
                <TextField
                  required
                  onChange={onNameChange}
                  inputRef={inputRef}
                  value={category.category}
                />
              </form>
            ) : (
              <span className={classes.catName}>{category.category}</span>
            )}
            <span className={classes.catCount}>
              {category.playlists.length}
            </span>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={category.freeOnly || false}
                  onChange={(event) => updateFreeOnly(event.target.checked)}
                  onClick={(event) => event.stopPropagation()}
                />
              }
              label="Mostrar apenas para usuários free"
            />
            <IconButton
              aria-label="editar categoria"
              component="span"
              onClick={() => {
                setEditing((editing) => !editing);
              }}
            >
              <Edit />
            </IconButton>

            <IconButton
              aria-label="remover categoria da página"
              component="span"
              onClick={() => {
                if (window.confirm('remover categoria da página?')) {
                  removeCategory(pageId, category._id).then(() =>
                    removeCallback(category._id)
                  );
                }
              }}
            >
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {isExpanded && (
          <>
            <Grid container>
              <Grid item xs={12} md={8}>
                <AttachPlaylist
                  callback={addPlaylist}
                  types={types}
                  categoryId={category._id}
                  freeOnly={category.freeOnly}
                />
              </Grid>
              <Grid item xs={12} md={4} style={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Reorder />}
                  onClick={() =>
                    setIsPlaylistDragDisabled(
                      (isPlaylistDragDisabled) => !isPlaylistDragDisabled
                    )
                  }
                >
                  {isPlaylistDragDisabled
                    ? 'Reordenar playlists'
                    : 'Confirmar ordenação'}
                </Button>
              </Grid>
            </Grid>
            <Playlists
              playlists={category.playlists}
              dettachPlaylist={dettachPlaylist}
              updatePlaylists={updatePlaylists}
              isDragDisabled={isPlaylistDragDisabled}
            />
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
