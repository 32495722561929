import {
  Button,
  Chip,
} from '@material-ui/core';
import Stack from '@mui/material/Stack';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import React from 'react';

export const TagCreationConfirmationDialog = ({
  tagConfirmationDialogOpen,
  setTagConfirmationDialogOpen,  
  newTags,
  handleSubmitAfterConfirmation,  
}) => (
  <Dialog
    open={tagConfirmationDialogOpen}
    onClose={() => setTagConfirmationDialogOpen(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {'Tem certeza de que deseja criar esta(s) tag(s)?'}
    </DialogTitle>
    <DialogContent>
      {newTags.mediaKind.newTags.length > 0 && (
        <>
          <DialogContentText id="alert-dialog-description">
            Você está prestes a criar novas tags de{' '}
            <strong>tipo de mídia</strong>:
          </DialogContentText>
          <Stack direction="row" spacing={1}>
            {newTags.mediaKind.newTags.map((t, i) => (
              <Chip
                key={`chip-media-new-${i}`}
                label={t}
                variant="outlined"
              />
            ))}
          </Stack>
        </>
      )}
      {newTags.mediaKind.similarTags.length > 0 && (
        <>
          <DialogContentText>Tags similares existentes:</DialogContentText>
          <Stack direction="row" spacing={1}>
            {newTags.mediaKind.similarTags.map((t, i) => (
              <Chip
                key={`chip-media-existing-${i}`}
                label={t}
                variant="outlined"
              />
            ))}
          </Stack>
        </>
      )}
      {newTags.contentDescription.newTags.length > 0 && (
        <>
          <DialogContentText id="alert-dialog-description">
            Você está prestes a criar novas tags de{' '}
            <strong>descrição de conteúdo</strong>:
          </DialogContentText>
          <Stack direction="row" spacing={1}>
            {newTags.contentDescription.newTags.map((t, i) => (
              <Chip
                key={`chip-media-new-${i}`}
                label={t}
                variant="outlined"
              />
            ))}
          </Stack>
        </>
      )}
      {newTags.contentDescription.similarTags.length > 0 && (
        <>
          <DialogContentText>Tags similares existentes:</DialogContentText>
          <Stack direction="row" spacing={1}>
            {newTags.contentDescription.similarTags.map((t, i) => (
              <Chip
                key={`chip-content-existing-${i}`}
                label={t}
                variant="outlined"
              />
            ))}
          </Stack>
        </>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setTagConfirmationDialogOpen(false)}>
        Não
      </Button>
      <Button onClick={handleSubmitAfterConfirmation} autoFocus>
        Sim, quero criar novas tags
      </Button>
    </DialogActions>
  </Dialog>)
