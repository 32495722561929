/**
 * Checks if given email is from brasilparalelo.com.br domain
 * @param {*} user
 * @returns {boolean} true if user domain is brasilparalelo.com.br
 */
export const isBrasilParaleloDomain = (user) => {
  return user?.email?.split('@')[1] === 'brasilparalelo.com.br';
};

/**
 * Checks if user.roles has an admin role
 * @param {*} user
 * @returns {boolean} true if user has an admin role, false if not
 */
export const isUserAdmin = (user) => {
  return user?.roles?.some((role) => role.role === 'admin');
};

export const userHasRole = (user, roleName) => {
  return user?.roles?.some((role) => role.role === roleName) || false;
};
