import SubscriptionUpdate from '../../../components/admin/subscriptions/Update';
import SubscriptionCreate from '../../../components/admin/subscriptions/Create';

export default [
  {
    link: '/admin/:userId/subscriptions/:id',
    component: SubscriptionUpdate,
    auth: true,
    roles: ['admin'],
  },
  {
    link: '/admin/users/:userId/subscriptions/create',
    component: SubscriptionCreate,
    auth: true,
    roles: ['admin'],
  },
];
