import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TabContext, TabPanel } from '@material-ui/lab';
import { useHistory, useParams } from 'react-router-dom';

import {
  AppBar,
  Button,
  Divider,
  Paper,
  Tab,
  Tabs,
  CircularProgress,
} from '@material-ui/core';

import api from '../../../integrations/api';
import { uploadImage } from '../../../integrations/api/files';
import Materials from '../materials/Materials';
import Audio from '../audio/Audio';
import BasicData from './BasicData';
import { PlaylistContext } from './PlaylistContext';
import Videos from './Videos';
import store from '../../../redux/store';
import { openSnackBar } from '../../../redux/snackbar/snackbar.actions';
import castleThumbnailFiles from './CastleThumbnails';
import { isCourse } from './playlistsMeta';
import ModuleThumbnail from './thumbnailModal';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  form: {
    marginTop: '20px',
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  player: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  footer: {
    width: '100%',
    padding: '10px',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  durationLabel: {
    textAlign: 'center',
    marginBottom: 10,
    paddingBottom: 10,
    borderBottom: '1px solid #ddd',
  },
}));

export default ({ initialValues, children, action }) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const [tab, setTab] = useState('0');
  const [state, dispatch] = useContext(PlaylistContext);

  let { typeName: type } = useParams();
  if (state.type) {
    type = state.type;
  }

  const castleThumbnail = castleThumbnailFiles(type);

  useEffect(() => {
    dispatch({
      type: 'INITIAL_STATE',
      payload: initialValues,
    });
  }, [dispatch, initialValues]);

  const isModular = useRef(
    initialValues.modules && initialValues.modules.length > 0
  );

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { rating, ...dataWithouRating } = state;

    let data = {
      ...dataWithouRating,
      type,
      roles: state.roles.map((r) => r._id),
    };

    if (isModular.current) {
      delete data.medias;
    } else {
      delete data.modules;
    }

    if (!state.coverFile || !state.thumbnailFile) {
      store.dispatch(
        openSnackBar({
          type: 'error',
          message: 'Imagens são obrigatórias!',
        })
      );
      return;
    }

    if (!state.teacher && isCourse(data.type)) {
      store.dispatch(
        openSnackBar({
          type: 'error',
          message: 'É obrigatório selecionar o professor!',
        })
      );
      return;
    } else if (typeof state.teacher === 'object') {
      data.teacher = state.teacher._id;
    }

    setLoading(true);

    let imagesToUpload = [];
    if (initialValues.coverFile !== state.coverFile) {
      imagesToUpload.push({
        image: state.coverFile,
        name: 'cover',
        path: `/playlists/${type}/cover`,
      });
    }

    if (initialValues.thumbnailFile !== state.thumbnailFile) {
      imagesToUpload.push({
        image: state.thumbnailFile,
        name: 'thumbnail',
        path: `/playlists/${type}/thumbnail`,
      });
    }

    if (
      state.thumbnailsFiles &&
      state.thumbnailsFiles[castleThumbnail.stateField] &&
      castleThumbnail.stateField !== 'dvd' &&
      (!initialValues.thumbnailsFiles ||
        initialValues.thumbnailsFiles[castleThumbnail.stateField] !==
        state.thumbnailsFiles[castleThumbnail.stateField])
    ) {
      imagesToUpload.push({
        metadata: 'thumbnails',
        image: state.thumbnailsFiles[castleThumbnail.stateField],
        name: castleThumbnail.stateField,
        path: `/playlists/${type}/${castleThumbnail.stateField}`,
      });
    }

    if (state.dvdFile && initialValues.dvdFile !== state.dvdFile) {
      imagesToUpload.push({
        metadata: 'thumbnails',
        image: state.dvdFile,
        name: 'dvd',
        path: `/playlists/${type}/dvd`,
      });
    }

    if (initialValues.heroFile !== state.heroFile) {
      imagesToUpload.push({
        image: state.heroFile,
        name: 'hero',
        path: `/playlists/${type}/hero`,
      });
    }

    if (state.heroWeb && !state.heroWeb.image) {
      imagesToUpload.push({
        image: state.heroWeb,
        name: 'heroWeb',
        path: `/playlists/${type}/heroWeb`,
      });
    }

    if (state.playlistLogo && !state.playlistLogo.image) {
      imagesToUpload.push({
        image: state.playlistLogo,
        name: 'playlistLogo',
        path: `/playlists/${type}/logo`,
      });
    }

    if (state.freemium_banner_img && !state.freemium_banner_img.image) {
      imagesToUpload.push({
        image: state.freemium_banner_img,
        name: 'freemium_banner_img',
        path: `/playlists/${type}/freemium_banner_img`,
      });
    }

    if (!state.exitDate) {
      data.exitDate = undefined;
    }

    Promise.all(imagesToUpload.map((arg) => uploadImage(arg)))
      .then((responses) => {
        let thumbnails = data.thumbnails;
        let otherMedias = {};

        responses.forEach((response) => {
          if (response.data.meta === 'thumbnails') {
            thumbnails = { ...thumbnails, ...response.data.reference };
          } else {
            otherMedias = { ...otherMedias, ...response.data.reference };
          }
        });

        let finalData = { ...data, ...otherMedias };
        if (thumbnails) finalData = { ...finalData, thumbnails };

        if (
          state.teaser?.embed &&
          state.teaser.embed !== initialValues.teaser?.embed
        ) {
          api.playlists
            .getMediaRawSources({
              embed: state.teaser?.embed,
            })
            .then((response) => {
              finalData = {
                ...finalData,
                teaser: response.data,
              };

              submitForm(finalData);
            })
            .catch(() => {
              setLoading(false);
              showError();
            });
        } else {
          submitForm(finalData);
        }
      })
      .catch(() => {
        showError();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitForm = (data) => {
    let handler = api.playlists.createPlaylist;
    if (action === 'update') {
      handler = api.playlists.updatePlaylist;
    }
    if (data.playlist?._id) {
      data.playlist = data.playlist._id;
    }

    handler(data, data.id)
      .then(() => {
        history.push(`/admin/playlists/${type}`);
      })
      .catch(() => {
        showError();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showError = () => {
    openSnackBar({
      type: 'error',
      message: `Falha ao ${action === 'update' ? 'atualizar' : 'cadastrar'
        } a playlist`,
    });
  };

  if (loading || state.loading) {
    return (
      <div
        style={{
          display: 'block',
          padding: '200px',
          width: '50%',
          margin: 'auto',
          textAlign: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Paper className={classes.paper}>
      <Typography gutterBottom variant="h5">
        Cadastrar conteúdo
      </Typography>

      <Divider />
      <form className={classes.form} onSubmit={handleSubmit}>
        <TabContext value={tab}>
          <AppBar color="default" position="static">
            <Tabs value={tab} onChange={handleChange} aria-label="user-tabs">
              <Tab label={'Dados gerais'} value={'0'} key={'0'} />
              <Tab label={'Videos'} value={'1'} key={'1'} />
            </Tabs>
          </AppBar>
          <TabPanel value={'0'} index={'0'} key={'0'}>
            <BasicData>{children}</BasicData>
          </TabPanel>
          <TabPanel value={'1'} index={'1'} key={'1'}>
            <Videos
              isCourse={isCourse(type)}
              isModular={isModular}
              modules={state.modules}
              medias={state.medias}
            />
          </TabPanel>
        </TabContext>
        <div className={classes.footer}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </div>
      </form>
      {state.materialsForm.open && <Materials />}
      {state.audioForm.open && <Audio />}
      {state.moduleThumbnail.open && <ModuleThumbnail />}
    </Paper>
  );
};
