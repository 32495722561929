import BrokerProductsPage from '../../../pages/admin/broker-products';
import BrokerProductDetailsPage from '../../admin/broker-products/details';
import CreateBrokerProductPage from '../../admin/broker-products/create';

export default [
  {
    link: '/admin/broker-products',
    component: BrokerProductsPage,
    auth: true,
    roles: ['admin'],
  },
  {
    link: '/admin/broker-products/create',
    component: CreateBrokerProductPage,
    auth: true,
    roles: ['admin'],
  },
  {
    link: '/admin/broker-products/details/:id',
    component: BrokerProductDetailsPage,
    auth: true,
    roles: ['admin'],
  },
];
