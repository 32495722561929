import React from 'react';
import PageList from '../../../components/admin/pages/List';

function Pages(props) {
  return (
    <React.Fragment>
      <PageList />
    </React.Fragment>
  );
}

export default Pages;
