import React from 'react';
import UsersManager from '../../../components/admin/users/UsersManager';
import UsersList from '../../../components/admin/users/UsersList';

function Users(props) {
  return (
    <>
      <UsersManager />
      <UsersList />
    </>
  );
}

export default Users;
