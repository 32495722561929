import React, { useState } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Input, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { asyncPlayListAddModule } from '../../../redux/playlists/playlist.actions';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));
export default () => {
  const playlist = useSelector((state) => state.playlist.toUpdate);
  const classes = useStyles();
  const [name, setName] = useState('');
  const [position, setPosition] = useState();
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('a');
    const data = {
      playlist: playlist._id,
      position,
      name,
    };
    dispatch(asyncPlayListAddModule(data));
  };
  return (
    <form onSubmit={handleSubmit}>
      <FormControl>
        <InputLabel htmlFor="name">Nome do módulo</InputLabel>
        <Input
          id="name"
          value={name}
          required
          onChange={(e) => setName(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <InputLabel htmlFor="name">Posição na playlist</InputLabel>
        <Input
          id="position"
          label="Posição na playlist"
          type="number"
          required
          value={position}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setPosition(e.target.value)}
        />
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        className={classes.button}
        startIcon={<SaveIcon />}
      >
        Adicionar
      </Button>
    </form>
  );
};
