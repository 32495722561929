import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';

export default ({ link, component, auth, path }) => {
  const location = useLocation();
  const currentUser = useSelector((state) => state.user.currentUser);

  if (location.pathname.includes('/signin') && currentUser) {
    return <Redirect to={{ path: '/signin' }} />;
  }
  if (auth && !localStorage.getItem('token')) {
    return <Redirect to={{ path: '/signin' }} />;
  }

  return <Route exact path={path} component={component}></Route>;
};
