import { pageActionTypes } from './pages.types';

const INITIAL_STATE = {
  pages: [],
  isFetching: false,
};

const pageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case pageActionTypes.FETCH_PAGES:
      return {
        ...state,
        isFetching: true,
      };
    case pageActionTypes.RECEIVE_PAGES:
      return {
        ...state,
        pages: action.payload,
        isFetching: Object.assign({}, { isFetching: false }),
      };
    case pageActionTypes.CREATE_PAGE:
      return {
        ...state,
        pages: [].concat(state.pages, [action.payload]),
      };
    case pageActionTypes.UPDATE_PAGE:
      return {
        ...state,
        pages: state.pages.map((p) =>
          p._id === action.payload._id ? Object.assign(p, action.payload) : p
        ),
      };
    case pageActionTypes.DELETE_PAGE:
      return {
        ...state,
        pages: state.pages.filter((p) => p._id !== action.payload._id),
      };
    default:
      return state;
  }
};
export default pageReducer;
