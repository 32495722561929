import React, { useContext } from 'react';

import { Chip, Grid, TextField } from '@material-ui/core';

import { PlaylistContext } from '../playlists/PlaylistContext';
import { Autocomplete } from '@material-ui/lab';

const SerieInputs = () => {
  const [state, dispatch] = useContext(PlaylistContext);

  const inputChange = (key, value) => {
    dispatch({
      type: 'CHANGE_INPUT',
      payload: {
        [key]: value,
      },
    });
  };

  if (!state.type) return null;

  return (
    <>
      <Grid item xs={12} lg={6}>
        <Autocomplete
          onChange={(event, value) => {
            event.preventDefault();
            inputChange('directors', value);
          }}
          options={[]}
          multiple
          id="tags-filled"
          defaultValue={state.directors}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Diretores"
              helperText="Você pode cadastrar diversos diretores. Digite o nome do diretor e tecle 'enter'."
            />
          )}
        />
      </Grid>
    </>
  );
};

export default SerieInputs;
