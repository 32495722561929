import React from 'react';
import UploadUsers from '../../../components/admin/users/UploadUsers';
import UploadedFiles from '../../../components/admin/users/UploadedFiles';

function Users() {
  return (
    <>
      <UploadUsers />
      <UploadedFiles />
    </>
  );
}

export default Users;
