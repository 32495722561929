import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useContext, useState } from 'react';
import { PlaylistContext } from '../playlists/PlaylistContext';

import { CircularProgress } from '@material-ui/core';

import LabeledDropZone from '../../../components/files/LabeledDropZone';
import { uploadImage } from '../../../integrations/api/files';
import { getSourceUrl } from '../../../integrations/cloudinary';

export default function ModuleThumbnail() {
  const [state, dispatch] = useContext(PlaylistContext);
  const [loading, setLoading] = useState(false);

  const mod = state.modules[state.moduleThumbnail.moduleIndex];

  let preview;
  if (mod.thumbnail) {
    preview = getSourceUrl(mod.thumbnail);
  }

  const handleClose = () =>
    dispatch({
      type: 'CLOSE_MODULE_THUMBNAIL',
    });

  const uploadThumb = (event) => {
    setLoading(true);
    uploadImage({
      image: event.target.value,
      path: '/playlists/modules',
      name: 'thumbnail',
    }).then((response) => {
      const payload = {
        index: state.moduleThumbnail.moduleIndex,
        ...response.data.reference,
      };
      setLoading(false);

      dispatch({
        type: 'REPLACE_MODULE_THUMB',
        payload,
      });

      dispatch({
        type: 'CLOSE_MODULE_THUMBNAIL',
      });
    });
  };

  const progress = (
    <div
      style={{
        display: 'block',
        padding: '200px',
        width: '50%',
        margin: 'auto',
        textAlign: 'center',
      }}
    >
      <CircularProgress />
    </div>
  );

  return (
    <div>
      <Dialog
        open={state.moduleThumbnail.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Miniatura do Módulo</DialogTitle>
        <DialogContent>
          {!loading && (
            <LabeledDropZone
              name="heroFile"
              width={480}
              height={270}
              initialFileUrl={preview}
              inputChange={uploadThumb}
              maxWidth={480}
              label="Miniatura do Módulo"
              helperText="Imagem mostrada na página de detalhes e em 'Continuar assistindo'. Dimensões: 1920x1080px"
            />
          )}
          {loading && progress}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
