import { teacherActionTypes } from './teacher.types';
import API from '../../api';

export const createTeacher = (payload) => {
  return {
    type: teacherActionTypes.CREATE_TEACHER,
    payload,
  };
};
export const updateTeacher = (payload) => {
  return {
    type: teacherActionTypes.UPDATE_TEACHER,
    payload,
  };
};
export const deleteTeacher = (payload) => {
  return {
    type: teacherActionTypes.DELETE_TEACHER,
    payload,
  };
};
export const fetchTeachers = () => {
  return {
    type: teacherActionTypes.FETCH_TEACHERS,
  };
};

export const receiveTeachers = (payload) => {
  return {
    type: teacherActionTypes.RECEIVE_TEACHERS,
    payload,
  };
};

export const asyncFetchTeachers = (data) => (dispatch) => {
  dispatch(fetchTeachers);
  API.get('/teachers', {
    data,
  })
    .then(
      (response) => response,
      (error) => console.log('erro ao trazer professores', error.response.data)
    )
    .then((json) => dispatch(receiveTeachers(json.data)));
};
export const asyncCreateTeacher = (data) => (dispatch) => {
  API.post('/teachers', data)
    .then(
      (response) => response.data,
      (error) => console.log('erro ao cadastrar professor', error.response.data)
    )
    .then((res) => dispatch(createTeacher(res)));
};

export const asyncDeleteTeacher = (data) => (dispatch) => {
  API.delete('/teachers', {
    params: data,
  })
    .then(
      (response) => response.data,
      (error) => console.log('erro ao deletar professor', error.response.data)
    )
    .then((res) => dispatch(deleteTeacher(res)));
};
export const asyncUpdateTeacher = (data) => (dispatch) => {
  API.put('/teachers', data)
    .then(
      (response) => response.data,
      (error) => console.log('erro ao atualizar professor', error.response.data)
    )
    .then((res) => dispatch(updateTeacher(res)));
};
