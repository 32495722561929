const products = [
  { name: 'Escola da Família', id: 'escola-da-familia' },
  { name: 'Sociedade do Livro', id: 'sociedade-do-livro' },
  { name: 'Núcleo de Formação', id: 'curso' },
  { name: 'Clube da Música', id: 'clube-da-musica' },
  { name: 'Travessia', id: 'travessia' },
  { name: 'Produtos', id: 'produto' },
];

const isCourse = (type) => {
  return products.map((p) => p.id).includes(type);
};

module.exports = { isCourse, products };
